import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService } from '@core/translation/translation.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BcmFinancialRecord, FinancialRecordStatus } from '@shared/models/bcm-financial-record';
import { InvoiceTypes, ReceiptTypes } from '@shared/invoice-types';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { PayByCashDialogComponent } from '@sharedComponents/dialogs/pay-by-cash-dialog/pay-by-cash-dialog.component';
import { CapturePaymentDialogComponent } from '@modules/bcm/accounting/invoices/dialogs/capture-payment-dialog/capture-payment-dialog.component';
import { Invoice } from '@shared/models/invoice';
import { PaymentsDialogComponent } from '@modules/bcm/accounting/invoices/dialogs/payments-dialog/payments-dialog.component';
import { U2bInformationDialogComponent } from '@sharedComponents/dialogs/information-dialog/information-dialog.component';
import { stopEvent } from '@shared/functions/stop-event';
import { SendEmailDialogComponent } from '@modules/bcm/accounting/invoices/dialogs/send-email-dialog/send-email-dialog.component';
import { dataURItoBlob } from '@shared/functions/datauri-to-blob';
import { downloadFile } from '@shared/functions/download-file';
import print from 'print-js';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';

@Component({
    selector: 'form-widget-financial-records',
    templateUrl: './form-widget-financial-records.component.html',
    styleUrls: ['./form-widget-financial-records.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormWidgetFinancialRecordsComponent {

    readonly InvoiceTypes = InvoiceTypes;
    readonly bcmTenantPermissions = BcmTenantPermission;
    readonly FinancialRecordStatus = FinancialRecordStatus;
    readonly ReceiptTypes = ReceiptTypes;

    @Input()
    headline = TranslationService.translate('financialRecords');

    @Input()
    financialRecords: BcmFinancialRecord[] = [];

    @Output()
    financialRecordsChanged: EventEmitter<void> = new EventEmitter<void>();

    colspan = 11;

    constructor(
        private _matDialog: MatDialog,
        private _confirmDialogService: ConfirmDialogService,
        private _appNotificationService: AppNotificationService,
        private _invoicesService: InvoicesService,
    ) {
    }

    onChangeStatus(event: MouseEvent, record: BcmFinancialRecord, newInvoiceStatus: FinancialRecordStatus): void {
        event.stopPropagation();

        const invoice = record.invoice;
        const receipt = record.receipt;

        if (receipt && newInvoiceStatus === FinancialRecordStatus.Canceled) {

            this._confirmDialogService
                .setTheme('warn')
                .setBody(`Beleg ${receipt.receiptNumber} wirklich stornieren?`)
                .appendInputToBody({
                    name: 'addPositionsBackToCoaster',
                    type: 'checkbox',
                    label: TranslationService.translate('addPositionsBackToCoaster'),
                    defaultValue: false
                })
                .openAndReturnResult<{ addPositionsBackToCoaster: boolean }>()
                .subscribe(async result => {
                    if (!result) {
                        return;
                    }

                    this._matDialog
                        .open(PayByCashDialogComponent, {
                            data: {
                                positions: receipt.positions,
                                person: record.person,
                                company: record.company,
                                receiptToCancel: receipt,
                                addPositionsBackToCoaster: result.addPositionsBackToCoaster
                            },
                            disableClose: true
                        })
                        .afterClosed()
                        .subscribe(() => {
                            this.financialRecordsChanged.emit();
                        });
                });

        } else if (invoice) {
            switch (newInvoiceStatus) {
                case FinancialRecordStatus.Payed:
                    const dialogRef = this._matDialog.open(CapturePaymentDialogComponent, {data: {invoice}});

                    dialogRef.afterClosed().subscribe(() => {
                        this.financialRecordsChanged.emit();
                    });
                    break;
                case FinancialRecordStatus.Canceled:

                    this._confirmDialogService
                        .setTheme('warn')
                        .setBody(`${invoice.invoiceType === InvoiceTypes.Crediting ? 'Gutschrift' : TranslationService.translate('invoice')} ${invoice.invoiceNumber} wirklich stornieren?`)
                        .appendInputToBody({
                            name: 'addPositionsBackToCoaster',
                            type: 'checkbox',
                            label: TranslationService.translate('addPositionsBackToCoaster'),
                            defaultValue: false
                        })
                        .appendInputToBody({
                            name: 'cancellationReason',
                            type: 'text',
                            label: 'Grund',
                        })
                        .openAndReturnResult<{ addPositionsBackToCoaster: boolean, cancellationReason: string }>()
                        .subscribe(result => {
                            if (!result) {
                                return;
                            }

                            this._invoicesService
                                .cancelInvoice(invoice, result.addPositionsBackToCoaster, result.cancellationReason)
                                .subscribe((cancellationInvoice) => {
                                    const invoiceTypeName = invoice.invoiceType === InvoiceTypes.Crediting ? 'Gutschrift' : TranslationService.translate('invoice');
                                    const cancellationInvoiceTypeName = invoice.invoiceType === InvoiceTypes.Crediting ? 'Stornogutschrift' : TranslationService.translate('cancellationInvoice');
                                    this._appNotificationService.showSuccess(
                                        `${invoiceTypeName} storniert. ${cancellationInvoiceTypeName} ${cancellationInvoice.invoiceNumber} erzeugt.`
                                    );

                                    this.financialRecordsChanged.emit();
                                });
                        });
                    break;
            }
        }
    }

    openPaymentsDialog(event: MouseEvent, invoice: Invoice): void {
        event.stopPropagation();

        const dialogRef = this._matDialog.open(PaymentsDialogComponent, {
            data: {invoice}
        });

        dialogRef.afterClosed().subscribe(() => {
            this.financialRecordsChanged.emit();
        });
    }

    async onClickSendMail(event, record: BcmFinancialRecord): Promise<void> {
        stopEvent(event);

        const invoice = record.invoice;

        const person = record.person?.id && record.person;
        const company = record.company?.id && record.company;

        const invoicesByMail = (person || company)?.invoicesByMail;
        const mails = (person || company)?.mails;
        const invoiceId = invoice.id;

        if (invoicesByMail || (person || company) == null) {
            const sendEmailDialogRef = this._matDialog.open(SendEmailDialogComponent, {
                data: {invoice, mails, invoiceId}
            });

            await sendEmailDialogRef.afterClosed().toPromise();
        } else {
            this._appNotificationService.showError('In den Stammdaten ist kein Versand per E-Mail hinterlegt.');
        }
    }

    async onClickDownload(event, record: BcmFinancialRecord): Promise<void> {

        event.stopPropagation();
        event.preventDefault();

        let pdfBlob;

        if (record.invoice) {
            pdfBlob = await this._invoicesService.getPdfFile(record.invoice).toPromise();
        } else if (record.receipt) {
            pdfBlob = dataURItoBlob(record.receipt.pdfDataUrl);
        } else {
            return;
        }

        if (!pdfBlob || !pdfBlob.size) {
            const confirmDialogRef = this._matDialog.open(U2bInformationDialogComponent);

            confirmDialogRef.componentInstance.dialogTitle = `Download nicht möglich`;
            confirmDialogRef.componentInstance.dialogBody = `Für diesen Beleg ist kein PDF hinterlegt.`;

            return;
        }

        const documentTitle = `${TranslationService.translate(record.type)} ${record.number} - ${(record.person?.fullNameBackward || record.company?.name)}.pdf`;

        downloadFile(pdfBlob, documentTitle);
    }

    async onClickPrint(event, record: BcmFinancialRecord): Promise<void> {

        event.stopPropagation();
        event.preventDefault();

        let pdfBlob;

        if (record.invoice) {
            pdfBlob = await this._invoicesService.getPdfFile(record.invoice).toPromise();
        } else if (record.receipt) {
            pdfBlob = dataURItoBlob(record.receipt.pdfDataUrl);
        } else {
            return;
        }

        if (!pdfBlob || !pdfBlob.size) {
            const confirmDialogRef = this._matDialog.open(U2bInformationDialogComponent);

            confirmDialogRef.componentInstance.dialogTitle = `Drucken nicht möglich`;
            confirmDialogRef.componentInstance.dialogBody = `Für diese${TranslationService.translate(record.type)} ist kein PDF hinterlegt.`;

            return;
        }

        const documentTitle = `${TranslationService.translate(record.type)} ${record.number} - ${(record.person?.fullNameBackward || record.company?.name)}.pdf`;

        print({printable: URL.createObjectURL(pdfBlob), documentTitle});
    }

}
