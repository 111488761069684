<div class="table-search p-8">
    <mat-form-field fxFlex="100" appearance="outline" floatLabel="never" style="{{showSearch ? '' : 'display: none'}}">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput #filter placeholder="Dokumente durchsuchen" data-test="FI-Suche">
    </mat-form-field>
</div>

<mat-table class="no-pointer"
           [dataSource]="dataSource"
           fusePerfectScrollbar
           matSort>

    <ng-container matColumnDef="icon">
        <mat-header-cell fxFlex="64px" fxHide fxShow.gt-sm *matHeaderCellDef></mat-header-cell>
        <mat-cell fxFlex="64px" fxHide fxShow.gt-sm *matCellDef="let document" (click)="showFile(document.file)">
            <ng-container *ngIf="document.file?.id; else noFile">
                <fa-icon [matTooltip]="document.file?.mimeType | mimeDescription"
                         matTooltipPosition="left"
                         [icon]="mimeToFaIcon(document?.file?.mimeType)"></fa-icon>
            </ng-container>
            <ng-template #noFile>
                <fa-icon [matTooltip]="'Nachweis / Prüfzertifikat / Datei fehlt'"
                         matTooltipPosition="left"
                         [icon]="faExclamationTriangle"></fa-icon>
            </ng-template>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Bezeichnung</mat-header-cell>
        <mat-cell *matCellDef="let document" (click)="showFile(document.file)">
            <p>{{document.title}}</p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="categoryName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-md>Kategorie</mat-header-cell>
        <mat-cell *matCellDef="let document" (click)="showFile(document.file)" fxHide.lt-md>
            <p>
                {{document.categoryName}}
            </p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="documentIdent">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-md>Identifikations-<br>/ Police-Nr
        </mat-header-cell>
        <mat-cell *matCellDef="let document" (click)="showFile(document.file)" fxHide.lt-md>
            <p>{{document.documentIdent}}</p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateOfExpiry">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-lg>Gültig bis</mat-header-cell>
        <mat-cell *matCellDef="let document" (click)="showFile(document.file)" fxHide.lt-lg>
            <p>
                {{document.dateOfExpiry | date}}
            </p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="note">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-lg>Notiz</mat-header-cell>
        <mat-cell *matCellDef="let document" (click)="showFile(document.file)" fxHide.lt-lg>
            <p>{{document.note}}</p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="insertedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-md>Erstellt am</mat-header-cell>
        <mat-cell *matCellDef="let document" (click)="showFile(document.file)" fxHide.lt-md>
            <p>
                {{document.insertedOn | dateTimeFormat}}
            </p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef fxFlex="64px"></mat-header-cell>
        <mat-cell *matCellDef="let document" fxFlex="64px">
            <div fxFlex="row" fxLayoutAlign="end center">

                <button data-test="B-Menu" mat-icon-button
                        [matMenuTriggerFor]="moreMenu"
                        aria-label="more">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #moreMenu="matMenu" style="display: none;">
                    <button fxLayout="row"
                            fxLayoutAlign="start center"
                            mat-menu-item
                            [disabled]="document.file?.mimeType === 'DUMMY_BCM_DOCUMENTS'"
                            (click)="downloadDocument.emit(document)">
                        <mat-icon color="primary">download</mat-icon>
                        <span>Herunterladen</span>
                    </button>
                    <ng-container *hasPermission="permissionNames.BOATS_WRITE">
                        <button fxLayout="row"
                                fxLayoutAlign="start center"
                                mat-menu-item
                                (click)="editDocument.emit(document)">
                            <mat-icon color="accent">edit</mat-icon>
                            <span>Bearbeiten</span>
                        </button>
                    </ng-container>
                    <ng-container *hasPermission="permissionNames.BOATS_DELETE">
                        <button fxLayout="row"
                                fxLayoutAlign="start center"
                                mat-menu-item
                                (click)="deleteDocument.emit(document)">
                            <mat-icon color="warn">delete</mat-icon>
                            <span>Löschen</span>
                        </button>
                    </ng-container>
                </mat-menu>

            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let document; columns: displayedColumns;"></mat-row>
</mat-table>

<mat-paginator matPaginatorPersisted [length]="dataSource?.filteredData?.length"></mat-paginator>
