import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { AbstractControlOptions, FormArray, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

@UntilDestroy()
export abstract class BaseArrayTabComponent<RAW, CLASS> {

    protected constructor(
        protected bookingDialogService: BookingDialogService,
        protected formBuilder: FormBuilder,
        private _attribute: BookingAttribute,
        private _model: new (arg: RAW) => CLASS,
    ) {
        this._createForm();

        this.bookingDialogService[this._attribute].update$
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this._fillForm(this.bookingDialogService[this._attribute.valueOf()].value);
            });

    }

    formArray: FormArray;
    formGroups$: BehaviorSubject<UntypedFormGroup[]> = new BehaviorSubject<UntypedFormGroup[]>([]);

    abstract formTemplate(value?: CLASS): {
        controls: { [key: string]: any },
        options?: AbstractControlOptions | null
    };

    addFormGroup(value?: CLASS): void {

        const formTemplate = this.formTemplate(value);

        const newFormGroup = this.formBuilder.group(
            formTemplate.controls,
            formTemplate.options
        ) as UntypedFormGroup;

        this.formArray.push(newFormGroup);
    }

    removeFormGroup(index: number): void {
        this.formArray.controls[index].get('deleted').setValue(true);
    }

    private generateFormGroups(values: CLASS[]): UntypedFormGroup[] {
        return (values || [])
            .map(value => {

                const formTemplate = this.formTemplate(value);

                return this.formBuilder.group(
                    formTemplate.controls,
                    formTemplate.options
                ) as UntypedFormGroup;

            });
    }

    private _createForm(): void {

        this.formArray = this.formBuilder.array([]);

        this.bookingDialogService[this._attribute].formArray = this.formArray;

        this.formArray.valueChanges
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
                map(formGroups => formGroups.map((raw: RAW) => this._mapFormGroupToClass(raw))),
                tap(() => this.formGroups$.next(this._filteredFormGroups())),
            )
            .subscribe((value: CLASS[]) => {
                if (!this.bookingDialogService.initialized) {
                    return;
                }
                this.bookingDialogService[this._attribute.valueOf()].value = value;
            });

    }

    private _fillForm(value: CLASS[]): void {
        this.formArray.clear();

        if (value) {
            const formGroups = this.generateFormGroups(value);
            formGroups.forEach(formGroup => this.formArray.push(formGroup));
        }
    }

    private _filteredFormGroups(): UntypedFormGroup[] {
        return this.formArray.controls
            .filter(group => !group.get('deleted')?.value) as UntypedFormGroup[];
    }

    private _mapFormGroupToClass(rawValue: RAW): CLASS {
        return new this._model(rawValue);
    }

}
