import { environment } from '@env/environment';
import { RawEditorSettings } from 'tinymce';

export const defaultEditorOptions: RawEditorSettings = {
    base_url: `${(environment.baseHref || '/')}assets/tinymce`,
    suffix: '.min',
    min_height: 500,
    menubar: false,
    branding: false,
    plugins: [
        'autolink lists advlist link image charmap print preview anchor charmap',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount hr nonbreaking pagebreak'
    ],
    // toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
    toolbar: [
        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
        'link image | removeformat | table | hr | nonbreaking pagebreak | charmap'
    ],
    language: 'de',
    pagebreak_separator: '<div class="pdf-pagebreak-before"></div>',
    pagebreak_split_block: true
};

export const PDFFooterEditorOptions = {
    base_url: `${(environment.baseHref || '/')}assets/tinymce`,
    suffix: '.min',
    min_height: 300,
    menubar: false,
    branding: false,
    plugins: [
        'autolink lists link charmap',
        'searchreplace',
        'insertdatetime table paste help wordcount'
    ],
    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | removeformat | table'
};


