<div class="container">

    <ng-container *ngIf="headline">
        <div>
            <h2 [innerHTML]="headline"></h2>
        </div>
    </ng-container>

    <div>

        <table class="simple sm">
            <thead>
            <tr>
                <th>
                    <ng-container *ngIf="!noPositionsAvailable">
                        <mat-checkbox
                                [checked]="allPositionsSelected"
                                (change)="updateSelectAllPositions()"></mat-checkbox>
                    </ng-container>
                </th>
                <th></th>
                <th>Beschreibung</th>
                <th style="width: 80px;" class="text-center">Anzahl</th>
                <th class="text-right">Einheit</th>
                <th class="text-right">Einzelpreis</th>
                <th class="text-right">Rabatt</th>
                <th class="text-right">Summe</th>
                <th class="pr-0"></th>
            </tr>
            </thead>
            <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
            <ng-container *ngIf="!positions?.length">
                <tr>
                    <td [attr.colspan]="colspan" class="text-center">Noch keine Position erfasst.</td>
                </tr>
            </ng-container>
            <ng-container *ngFor="let position of positions; let i = index">
                <tr cdkDrag [class.deleted]="position.deleted">
                    <div *cdkDragPreview>
                        {{ position.title }}<br>
                        <small>An gewünschter Position loslassen.</small>
                    </div>
                    <td>
                        <mat-checkbox
                                matTooltip="Positionen müssen erst gespeichert werden, bevor sie auf einen Beleg übernommen werden können."
                                matTooltipPosition="right"
                                [matTooltipDisabled]="position.isPaid || !position.isNew"
                                [disabled]="!position.id || position.isPaid || position.deleted"
                                [checked]="position.selected"
                                (change)="position.selected = $event.checked;updateSelected()"></mat-checkbox>
                    </td>
                    <td>
                        <div class="position-icon">
                            <ng-container
                                    *ngIf="position.berthAssignmentUuid && position.electricMeterAssignmentUuid">
                                <mat-icon
                                        [matTooltip]="'Stromverbrauch aus Belegung #' + (position.berthAssignmentIndex + 1)"
                                        matTooltipPosition="right">
                                    exposure
                                </mat-icon>
                            </ng-container>
                            <ng-container
                                    *ngIf="!position.fromTenantRelation && position.berthAssignmentUuid && !position.electricMeterAssignmentUuid">
                                <mat-icon
                                        [matTooltip]="'Belegung #' + (position.berthAssignmentIndex + 1)"
                                        matTooltipPosition="right">
                                    directions_boat
                                </mat-icon>
                            </ng-container>
                            <ng-container *ngIf="position.fromTenantRelation || position.tenantRelationAssignment">
                                <mat-icon
                                        [matTooltip]="'Beziehung - ' + position.tenantRelation?.name || position.tenantRelationAssignment?.tenantRelation?.name"
                                        matTooltipPosition="right">
                                    timeline
                                </mat-icon>
                            </ng-container>
                        </div>
                    </td>
                    <td>
                        {{ position.title }}
                        <ng-container *ngIf="position.isNew">
                            <span class="badge new">NEU</span>
                        </ng-container>
                        <ng-container *ngIf="position.isPaid">
                            <span class="badge payed"
                                  [matTooltip]="position.financialRecordNumberText"
                                  matTooltipPosition="right"
                            >BEZAHLT</span>
                        </ng-container>
                        <ng-container *ngIf="position.voucher">
                            <br>
                            <small>
                                {{ position.voucher.title }}<br>
                                (Verbleibend: {{ position.voucherRemainingAmount || 0 }})
                            </small>
                        </ng-container>
                    </td>
                    <td style="width: 80px;" class="text-center">
                        <app-input-number matInput
                                          [disabled]="(!!position.tenantRelationAssignment && !!position.subscription) || readonly || position.isPaid || position.deleted"
                                          [(ngModel)]="position.quantity"
                                          [ngModelOptions]="{standalone: true}"
                                          (ngModelChange)="updateSum()"></app-input-number>
                    </td>
                    <td class="text-right">
                        {{ position.unit?.name }}
                    </td>
                    <td class="text-right">
                        {{ position.price | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                    <td class="text-right">
                        {{ position.discountPercentage | number : '1.0-2' }}
                    </td>
                    <td class="text-right">
                        {{ position.totalPrice | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                    <ng-container *ngIf="!readonly">
                        <td class="text-right pr-0" width="80px">
                            <div fxLayout="row" fxLayoutAlign="flex-end center">
                                <ng-container *ngIf="!position.isPaid && !position.deleted">
                                    <button type="button" mat-icon-button color="accent"
                                            [disabled]="!!position.voucher || (!!position.tenantRelationAssignment && !!position.subscription)"
                                            [matTooltip]="('voucher' | translate) + ' Positionen können aus technischen Gründen nur gelöscht werden.'"
                                            [matTooltipDisabled]="!position.voucher"
                                            (click)="editInvoicePosition(position, i)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button type="button" mat-icon-button color="warn"
                                            [disabled]="(!!position.tenantRelationAssignment && !!position.subscription)"
                                            (click)="removeInvoicePosition(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>

            <div *cdkDragPlaceholder></div>
            </tbody>
            <tfoot>
            <ng-container *ngIf="positionsSum">
                <tr>
                    <td [attr.colspan]="colspan - 2" class="no-border"></td>
                    <td class="text-right padding sum no-border">
                        {{ positionsSum | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                    <td></td>
                </tr>
            </ng-container>

            </tfoot>
        </table>

        <ng-container *ngIf="!readonly">

            <div fxLayout="column" fxLayoutGap="8px" class="mt-8">

                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">

                    <ng-container *ngIf="showAddBerthPositionButton">
                        <div [fxFlex]="showAddBerthPositionButton ? '50' : '100'" class="text-center">
                            <button mat-stroked-button
                                    type="button"
                                    color="accent"
                                    data-test="B-Liegegebühr hinzufügen"
                                    style="width: 100%"
                                    (click)="addBerthInvoicePosition()">
                                <mat-icon>add</mat-icon>
                                Liegegebühr hinzufügen
                            </button>
                        </div>
                    </ng-container>

                    <div [fxFlex]="showAddBerthPositionButton ? '50' : '100'" class="text-center"
                         matTooltip="Keine Person / Organisation angegeben"
                         [matTooltipDisabled]="!!person || !!company">
                        <button mat-stroked-button
                                type="button"
                                color="accent"
                                data-test="B-Position hinzufügen"
                                style="width: 100%"
                                [disabled]="!person && !company"
                                (click)="addInvoicePosition()">
                            <mat-icon>add</mat-icon>
                            Position hinzufügen
                        </button>
                    </div>
                </div>

                <div fxFlex="100" class="text-center"
                     [matTooltip]="payPositionTooltip">
                    <button mat-stroked-button
                            type="button"
                            color="accent"
                            data-test="B-Ausgewählte Positionen auf neuen Beleg übernehmen"
                            style="width: 100%"
                            [disabled]="!anyPositionSelected"
                            (click)="openInvoiceForm()">
                        <mat-icon> insert_drive_file</mat-icon>
                        Ausgewählte Positionen auf neuen Beleg übernehmen
                    </button>
                </div>

            </div>


        </ng-container>

    </div>

</div>
