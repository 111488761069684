<u2b-modal [closeFunction]="closeDialog">

    <div u2bModalHeadline>{{'invoice' | translate}} {{ invoice?.invoiceNumber }} - Zahlungen</div>

    <u2b-message>
        Bereits gezahlt: {{ invoice.paidAmount | bcmDynamicCurrency }}<br>
        Ausstehender Betrag: {{ invoice.missingAmount | bcmDynamicCurrency }}
    </u2b-message>

    <table class="simple">
        <thead>
        <tr>
            <th>Betrag</th>
            <th>Datum</th>
            <th>Zahlweise</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="!invoice?.payments?.length">
            <tr>
                <td colspan="5">Noch keine Zahlungen erfasst.</td>
            </tr>
        </ng-container>
        <tr *ngFor="let payment of invoice?.payments">
            <td>{{ payment.amount | bcmDynamicCurrency }}</td>
            <td>{{ payment.date | dateFormat }}</td>
            <td>{{ paymentTypeTranslationDe[payment.paymentType] }}</td>
            <td class="cell-align-right">
                <div class="button-group">
                    <button mat-icon-button
                            color="accent"
                            [matTooltip]="'Keine Notiz hinterlegt.'"
                            [matTooltipDisabled]="!!payment.note"
                            [disabled]="!payment.note"
                            (click)="showNote(payment)">
                        <mat-icon>description</mat-icon>
                    </button>
                    <button mat-icon-button
                            color="accent"
                            [disabled]="tsePaymentTypes.includes(payment.paymentType)"
                            (click)="updatePayment(payment)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button
                            color="warn"
                            [disabled]="tsePaymentTypes.includes(payment.paymentType)"
                            (click)="deletePayment(payment)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </td>
        </tr>
        </tbody>
    </table>

    <div class="button-group mt-24">
        <button mat-flat-button (click)="addPayment()">
            <mat-icon>euro</mat-icon>
            Zahlung erfassen
        </button>
    </div>

    <button mat-flat-button
            modalButtonRight
            color="accent"
            (click)="closeDialog()">
        <mat-icon>close</mat-icon>
        Schließen
    </button>

</u2b-modal>
