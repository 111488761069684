<mat-form-field fxFlex="100" style="width: 100%" [appearance]="appearance" floatLabel="always">
    <mat-label>Kategorie</mat-label>

    <ng-container *ngIf="multiSelect; else singleSelect">
        <mat-select data-test="" [formControl]="documentCategoryFC" multiple
                    [compareWith]="compareObjectsById">
            <mat-option *ngFor="let category of (filteredDocumentCategories$ | async) | orderBy : 'name' : false : true"
                        [value]="category">
                {{category.name}}
            </mat-option>
        </mat-select>
    </ng-container>

    <ng-template #singleSelect>
        <input type="text" matInput data-test="FI-Kategorie" [formControl]="documentCategoryFC"
               [matAutocomplete]="categorySelector">

        <mat-autocomplete #categorySelector="matAutocomplete" [displayWith]="displayWithDocumentCategory">
            <ng-container
                    *ngFor="let category of (filteredDocumentCategories$ | async) | orderBy : 'name' : false : true">
                <mat-option [value]="category">
                    <span>{{ category.name }}</span><br>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </ng-template>

    <mat-error>{{documentCategoryFC.errors | firstErrorMessage}}</mat-error>
</mat-form-field>
