<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{header || 'Bitte warten...'}}</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0">
        <u2b-message type="warning">
            <header>Wichtig</header>
            <p>
                Bitte schließe nicht Deinen Browser oder Browser-Tab und fahre nicht Deinen PC herunter so lange dieses
                Fenster angezeigt wird!
            </p>
        </u2b-message>

        <ng-template #defaultSpinner>
            <div class="pt-24">
                <mat-spinner style="margin:0 auto;" [diameter]="40" [color]="'primary'"></mat-spinner>
            </div>
        </ng-template>

        <ng-container *ngIf="download$ | async as download; else tryProgress">
            <ng-container *ngIf="download.state !== 'DONE'">
                <div class="pt-24">
                    <p>
                        <mat-progress-bar *ngIf="download$ | async as download"
                                          [mode]="download.state == 'PENDING' ? 'buffer' : 'determinate'"
                                          [value]="download.progress">
                        </mat-progress-bar>
                    </p>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #tryProgress>
            <ng-container *ngIf="uploadProgress != null && uploadProgress < 100; else defaultSpinner">
                <div>
                    ({{uploadProgress}} %)
                </div>
                <div>
                    <mat-progress-bar [value]="uploadProgress"></mat-progress-bar>
                </div>
            </ng-container>
        </ng-template>

        <ng-container *ngIf="statusList?.length">
            <div class="text-center">
                <ng-container *ngFor="let entry of statusList; let first = first">
                    <div class="status-item mt-8" [class.first]="first" fxLayout="row" fxLayoutAlign="center center">
                        <ng-container *ngIf="first">
                            <mat-icon style="font-size: 21px">{{entry.iconName || 'hourglass_empty'}}</mat-icon>
                            {{entry.message}}
                        </ng-container>
                        <ng-container *ngIf="!first">
                            <mat-icon style="font-size: 21px">{{entry.iconName || 'check'}}</mat-icon>
                            {{entry.doneMessage}}
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>

</div>
