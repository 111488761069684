import { IPerson } from '@shared/models/person';
import { ICompany } from '@shared/models/company';
import {
    ProductSubscription,
    ProductSubscriptionDto,
    ProductSubscriptionType
} from '@shared/models/product-subscription';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { IProduct, Product } from '@shared/models/product';
import { BcmCostCenterDto } from '@shared/models/bcm-cost-center';
import { Observable, of, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

export class BookingDialogSubscriptions extends BookingDialogBaseEntity<ProductSubscription, ProductSubscription[]> {

    constructor(
        bookingDialogService: BookingDialogService,
        private _productService: ProductService
    ) {
        super(bookingDialogService, BookingAttribute.SUBSCRIPTIONS);
    }

    protected isDuplicate(value: ProductSubscription): boolean {
        return this.bookingDialogService.subscriptions.value.findIndex(sub => {
            return sub.product.id === value.product.id &&
                sub.quantity === value.quantity &&
                sub.type === ProductSubscriptionType.TenantRelation &&
                !sub.id;
        }) !== -1;
    }

    addFromTenantRelation(assignment: TenantRelationAssignment): void {

        const subscriptions = assignment.tenantRelation.products.map(product => {

            const subscriptionDto: ProductSubscriptionDto = {
                product: product as unknown as IProduct,
                payableOption: assignment.payableOption,
                quantity: product.quantity,
                type: ProductSubscriptionType.TenantRelation,
                vestingPeriodFrom: assignment.fromDate,
                vestingPeriodUntil: assignment.toDate,
                fromDate: assignment.fromDate,
                toDate: assignment.toDate,
                lastDayOfMonth: assignment.lastDayOfMonth,
                tenantRelationName: assignment.tenantRelation.name,
                costCenter: assignment.costCenter as unknown as BcmCostCenterDto,
                person: this.bookingDialogService.person.value as unknown as IPerson,
                company: this.bookingDialogService.company.value as unknown as ICompany,
            };

            return new ProductSubscription(subscriptionDto);

        });

        subscriptions.forEach(subscription => {
            this.add(subscription);
        });

        // at the moment we do not have dynamic prices for subscriptions in tenant relations
        // from(subscriptions).pipe(
        //     concatMap(subscription => this.getSubscriptionWithDynamicPrice(subscription))
        // ).subscribe({
        //     next: dynamicPriceSubscription => {
        //         this.add(dynamicPriceSubscription);
        //     }
        // });

    }

    removeUnsavedFromTenantRelation(): void {
        this.value = this.value
            .filter(subscription => subscription.id || subscription.type !== ProductSubscriptionType.TenantRelation);
    }

    getSubscriptionWithDynamicPrice(subscription: ProductSubscription): Observable<ProductSubscription> {

        if (subscription.product.hasDynamicPrice) {
            const boat = this.bookingDialogService.boat.value;
            const berth = this.bookingDialogService.berthAssignments.value.find(assignment => assignment.uuid === subscription.berthAssignmentUuid)?.berth;
            const tenantRelation = this.bookingDialogService.tenantRelationAssignment.value?.tenantRelation;

            return this._productService.evaluatePriceRule(subscription.product as Product, {
                boat,
                berth,
                tenantRelation
            }, subscription.quantity)
                .pipe(
                    take(1),
                    map(dynamicPrice => {
                        dynamicPrice = {
                            ...dynamicPrice,
                            boat,
                            berth,
                            tenantRelation
                        };

                        subscription.dynamicPrice = dynamicPrice;
                        subscription.customPrice = dynamicPrice.rulePrice;
                        subscription.customTitle = dynamicPrice.ruleName;

                        return subscription;
                    })
                );

        } else {
            return of(subscription);
        }
    }

}
