import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { differenceInCalendarDays, isBefore, isSameDay } from 'date-fns';
import { DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';
import { DEFAULT_BERTH_RESERVATION_UNIT } from '@modules/bcm/settings/default-units/default-units.component';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Berth } from '@shared/models/berth';
import { Boat } from '@shared/models/boat';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'booking-berth-assignment',
    templateUrl: './berth-assignment.component.html',
    styleUrls: ['./berth-assignment.component.scss'],
})
export class BerthAssignmentComponent implements OnInit {

    bcmTenantPermissions = BcmTenantPermission;
    permissionNames = BcmUserPermission;

    constructor(private _bookingDialogService: BookingDialogService) {
    }

    @Input()
    index: number;

    @Input()
    expanded: boolean;

    @Input()
    berthAssignmentFormGroup: UntypedFormGroup;

    @Input()
    defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit;

    @Output()
    removeAssignment: EventEmitter<void> = new EventEmitter<void>();

    berth: Berth;
    boat: Boat;

    durationOfStay: number;
    totalDays: number;
    sameDay: boolean;
    isAfterPeriodStarts: boolean;
    isAfterPeriodEnds: boolean;

    fromHours: number;
    fromMinutes: number;
    toHours: number;
    toMinutes: number;

    boatToLong: number;
    boatToWide: number;
    boatToHeavy: number;
    boatWeight: number;
    berthMaxWeight: number;

    DEFAULT_BERTH_RESERVATION_UNIT = DEFAULT_BERTH_RESERVATION_UNIT;

    ngOnInit(): void {

        this.berth = this.berthAssignmentFormGroup.get('berthForm')?.get('berth')?.value;

        this.berthAssignmentFormGroup
            .valueChanges
            .pipe(
                map(value => [value.from, value.to]),
                distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]),
            )
            .subscribe(value => {
                this._calculateDurationOfStay(value[0], value[1]);
                this.berthAssignmentFormGroup.patchValue({durationOfStay: this.durationOfStay});
            });

        // TODO check if this is needed -> move to service
        this.berthAssignmentFormGroup
            .valueChanges
            .pipe(
                map(value => value.berthForm?.berth),
                filter(berth => !!berth),
                distinctUntilChanged((a, b) => a?.id === b?.id)
            )
            .subscribe((berth: Berth) => {
                this.berth = berth;
                this.berthAssignmentFormGroup.patchValue({berth: berth}, {emitEvent: false});
                this._checkBoatDimensions();
            });


        this._bookingDialogService.boat.value$
            .pipe(untilDestroyed(this))
            .subscribe((boat: Boat) => {
                    this.boat = boat;
                    this._checkBoatDimensions();
                }
            );

        this._calculateDurationOfStay(this.berthAssignmentFormGroup.value.from, this.berthAssignmentFormGroup.value.to);
        this.berthAssignmentFormGroup.patchValue({durationOfStay: this.durationOfStay});

    }

    private _checkBoatDimensions(): void {
        const boat = this.boat;
        const berth = this.berth;

        const boatLength = boat?.length;
        const boatWidth = boat?.width;
        const boatWeight = boat?.weight;

        this.boatToLong = undefined;
        this.boatToWide = undefined;
        this.boatToHeavy = undefined;
        this.berthMaxWeight = undefined;
        this.boatWeight = undefined;

        if (berth && (boatLength || boatWidth)) {
            if (berth.length < boatLength) {
                this.boatToLong = boatLength - berth.length;
            }
            if (berth.width < boatWidth) {
                this.boatToWide = boatWidth - berth.width;
            }
            if (berth.isBuoy && berth.maxCarryingCapacity) {
                this.berthMaxWeight = berth.maxCarryingCapacity * 1000; // t > kg

                if (this.berthMaxWeight < boatWeight) {
                    this.boatToHeavy = boatWeight - this.berthMaxWeight;
                    this.boatWeight = boatWeight;
                }
            }
        }

    }

    private _calculateDurationOfStay(from: Date, to: Date): void {

        if (!from || !to || isBefore(to, from)) {
            this.durationOfStay = 0;
            return;
        }

        if (this.defaultBerthReservationTimeUnit?.type === DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY) {

            if (isSameDay(from, to)) {
                this.sameDay = true;
                this.fromHours = this.defaultBerthReservationTimeUnit.dailyGuestFromHours;
                this.fromMinutes = this.defaultBerthReservationTimeUnit.dailyGuestFromMinutes;
                this.toHours = this.defaultBerthReservationTimeUnit.dailyGuestToHours;
                this.toMinutes = this.defaultBerthReservationTimeUnit.dailyGuestToMinutes;
            } else {
                this.fromHours = this.defaultBerthReservationTimeUnit.overnightGuestFromHours;
                this.fromMinutes = this.defaultBerthReservationTimeUnit.overnightGuestFromMinutes;
                this.toHours = this.defaultBerthReservationTimeUnit.overnightGuestToHours;
                this.toMinutes = this.defaultBerthReservationTimeUnit.overnightGuestToMinutes;
            }

            this.isAfterPeriodStarts = this.fromHours < from.getHours() ||
                (this.fromHours === from.getHours() && this.fromMinutes <= from.getMinutes());

            this.isAfterPeriodEnds = this.toHours < to.getHours() ||
                (this.toHours === to.getHours() && this.toMinutes < to.getMinutes());

            this.totalDays = Math.max(differenceInCalendarDays(to, from), 1);
            this.durationOfStay = this.totalDays;

            if (!this.isAfterPeriodStarts) {
                this.durationOfStay++;
            }

            if (this.isAfterPeriodEnds) {
                this.durationOfStay++;
            }

        } else {
            this.durationOfStay = differenceInCalendarDays(to, from) + 1;
        }

    }

}
