import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { PageRequest } from '@shared/models/pagination';

@Directive({
    selector: '[matPaginatorPersisted]'
})
export class MatPaginatorPersistedDirective implements OnInit {

    public static readonly pageSizeOptionsDefault = [5, 10, 25, 100];
    public static readonly pageSizeDefault = 25;
    public static readonly pageIndexDefault = 0;

    @Output() pageSizeChanged = new EventEmitter<PageEvent>();

    constructor(private element: MatPaginator) {
        this.element.pageSize = PageRequest.pageSize;
        this.element.pageSizeOptions = MatPaginatorPersistedDirective.pageSizeOptionsDefault;
        this.element.pageIndex = MatPaginatorPersistedDirective.pageIndexDefault;
    }

    ngOnInit(): void {
        this.element.page.subscribe((page: PageEvent) => {
            PageRequest.pageSize = page.pageSize;
            this.pageSizeChanged.emit(page);
        });
    }
}
