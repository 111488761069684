import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { BcmDocument } from '@shared/models/bcm-document';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { BcmBookingsApiService } from '@bcmApiServices/bcm-bookings.api-service';

export class BookingDialogDocuments extends BookingDialogBaseEntity<BcmDocument, BcmDocument[]> {

    constructor(
        bookingDialogService: BookingDialogService,
        private _bookingsApiService: BcmBookingsApiService,
    ) {
        super(bookingDialogService, BookingAttribute.DOCUMENTS);
    }

    reload(): void {
        this._bookingsApiService.getDocuments(this.bookingDialogService.booking.id)
            .subscribe(documents => {
                this.bookingDialogService.booking.documents = documents;
                this.value = documents;
            });
    }


}
