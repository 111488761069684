import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BcmApiService } from '@bcmApiServices/bcm-api.service';
import { BcmDocumentCategory, BcmDocumentCategoryDto } from '@shared/models/bcm-document-category';
import { parseHttpResponseItem, parseHttpResponsePage } from '@shared/functions/parse-http-response';
import { Page, PageRequest } from '@shared/models/pagination';

@Injectable({
    providedIn: 'root'
})
export class BcmDocumentCategoriesApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'documents/categories';

    protected getAll(pageRequest?: PageRequest<BcmDocumentCategory>): Observable<Page<BcmDocumentCategory>> {
        return super.get<Page<BcmDocumentCategoryDto>>(this.RESOURCE_ENDPOINT, pageRequest?.getHttpParams())
            .pipe(parseHttpResponsePage<BcmDocumentCategoryDto, BcmDocumentCategory>(BcmDocumentCategory));
    }

    protected getOne(id: number, params: HttpParams = new HttpParams()): Observable<BcmDocumentCategory> {
        return super.get<BcmDocumentCategoryDto>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<BcmDocumentCategoryDto, BcmDocumentCategory>(BcmDocumentCategory));
    }

    protected add(documentCategory: BcmDocumentCategory, params: HttpParams = new HttpParams()): Observable<BcmDocumentCategory> {
        return super.post<BcmDocumentCategoryDto>(`${this.RESOURCE_ENDPOINT}`, documentCategory, params)
            .pipe(parseHttpResponseItem<BcmDocumentCategoryDto, BcmDocumentCategory>(BcmDocumentCategory));
    }

    protected update(documentCategory: BcmDocumentCategory, documentCategoryData: Partial<BcmDocumentCategory>): Observable<BcmDocumentCategory> {
        return super.put<BcmDocumentCategoryDto>(`${this.RESOURCE_ENDPOINT}/${documentCategory.id}`, documentCategoryData)
            .pipe(parseHttpResponseItem<BcmDocumentCategoryDto, BcmDocumentCategory>(BcmDocumentCategory));
    }

    protected remove(documentCategory: BcmDocumentCategory): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${documentCategory.id}`);
    }

}
