import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { AbstractControlOptions, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

@UntilDestroy()
export abstract class BaseTabComponent<RAW, CLASS> {

    protected constructor(
        protected bookingDialogService: BookingDialogService,
        protected formBuilder: FormBuilder,
        private _attribute: BookingAttribute,
        private _model: new (arg: RAW) => CLASS,
    ) {
        this._createForm();

        this.bookingDialogService[this._attribute].update$
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (!this.bookingDialogService.initialized) {
                    return;
                }

                this._fillForm(this.bookingDialogService[this._attribute.valueOf()].value);
            });

    }

    formGroup: UntypedFormGroup;

    // Override this method to implement custom distinctUntilChanged logic (e.g. compare only specific fields needed to trigger a change)
    protected distinctUntilChangedComparator(a: CLASS, b: CLASS): boolean {
        return false;
    }

    abstract formTemplate(value?: CLASS): {
        controls: { [key: string]: any },
        options?: AbstractControlOptions
    };

    private _createForm(): void {

        const formTemplate = this.formTemplate();

        this.formGroup = this.formBuilder.group(
            formTemplate.controls,
            formTemplate.options
        ) as UntypedFormGroup;

        this.bookingDialogService[this._attribute].formGroup = this.formGroup;

        this.formGroup.valueChanges
            .pipe(
                untilDestroyed(this),
                map((raw: RAW) => this._mapFormGroupToClass(raw)),
                distinctUntilChanged((a, b) => this.distinctUntilChangedComparator(a, b))
            )
            .subscribe((value: CLASS) => {
                this.bookingDialogService[this._attribute].value = value;
            });

    }

    private _fillForm(value: CLASS): void {
        this.formGroup.reset();

        if (value) {
            this.formGroup.patchValue(value);
        }
    }

    private _mapFormGroupToClass(rawValue: RAW): CLASS {
        return new this._model(rawValue);
    }

}
