import { PayableOption } from '@shared/models/payable-option';

export function calculateProportionalTime(_from: Date, _to: Date, payableOption: PayableOption): number | null {

    if (!_from || !_to || (_from?.getTime() > _to?.getTime())) {
        return 1;
    }

    const seconds = (_to.getTime() - _from.getTime()) / 1000;
    const hours = seconds / 3600;
    const days = seconds / 86400;

    switch (payableOption.id) {
        case 1: // 1 = Jährlich
            return Math.ceil(days / 360);

        case 2: // 2 = Monatlich
            return Math.ceil(days / 30);

        case 3: // 3 = Wöchentlich
            return Math.ceil(days / 7);

        case 4: // 4 = Täglich
            return Math.ceil(hours / 24);

        case 5: // 5 = Stündlich
            return Math.ceil(seconds / 3600);

        case 6: // 6 = Halbjährlich
            return Math.ceil(days / 180);

        case 7: // 7 = Vierteljährlich
            return Math.ceil(days / 90);

        default:
            return 1;
    }



}
