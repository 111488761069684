import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { hours, minutes } from '@shared/constants/date';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Season } from '@shared/models/seasons';
import { SeasonApiService } from '@bcmApiServices/seasons.api-service';
import { PayableOptionId } from '@bcmApiServices/product-payable-option.api-service';
import { endOfMonth, } from 'date-fns';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { getVestingPeriodUntil } from '@shared/functions/vesting-period-helper';

@UntilDestroy()
@Component({
    selector: 'booking-tenant-relation-assignment',
    templateUrl: './tenant-relation-assignment.component.html',
})
export class TenantRelationAssignmentComponent implements OnInit {

    seasons: Season[];

    seasonFormControl = new FormControl<Season>(null);

    loading: boolean;

    hours = hours;

    minutes = minutes;

    PayableOptionId = PayableOptionId;

    constructor(
        public bookingDialogService: BookingDialogService,
        public formGroupDirective: FormGroupDirective,
        private _seasonApiService: SeasonApiService,
    ) {

        const form = formGroupDirective.form;
        const vestingPeriodFromField = form.get('vestingPeriodFrom');
        const vestingPeriodUntilField = form.get('vestingPeriodUntil');
        const lastDayOfMonthField = form.get('lastDayOfMonth');

        form.valueChanges
            .pipe(
                untilDestroyed(this),
                map(value => value?.tenantRelationForm?.tenantRelation),
                distinctUntilChanged((previous, current) => previous?.id === current?.id)
            )
            .subscribe(tenantRelation => {

                if (!tenantRelation) {
                    return;
                }

                form.get('tenantRelation').setValue(tenantRelation);

                const controlNames = [
                    'periodFromDate',
                    'periodToDate',
                    'vestingPeriodFrom',
                    'vestingPeriodUntil',
                ];

                if (tenantRelation?.products?.length) {
                    enableControlsByName(formGroupDirective.form, controlNames, false, {emitEvent: false});
                } else {
                    disableControlsByName(formGroupDirective.form, controlNames, {emitEvent: false});
                }

                if (tenantRelation?.payableOption && !form.get('vestingPeriodUntil')?.value) {
                    const vestingPeriodFrom = form.get('vestingPeriodFrom').value;
                    const vestingPeriodUntil = getVestingPeriodUntil(vestingPeriodFrom, tenantRelation?.payableOption);
                    form.get('vestingPeriodUntil').patchValue(vestingPeriodUntil, {emitEvent: false});
                }
            });

        vestingPeriodFromField
            .valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((vestingPeriodFrom: Date) => {
                const tenantRelation = form.get('tenantRelationForm')?.get('tenantRelation').value;
                const vestingPeriodUntil = getVestingPeriodUntil(vestingPeriodFrom, tenantRelation?.payableOption);

                vestingPeriodUntilField.patchValue(vestingPeriodUntil, {emitEvent: false});
            });

        lastDayOfMonthField
            .valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value: any) => {
                if (value) {
                    form.get('vestingPeriodUntil').disable();
                    form.get('vestingPeriodUntil').patchValue(endOfMonth(form.get('vestingPeriodUntil').value || form.get('vestingPeriodFrom').value), {emitEvent: false});
                } else {
                    form.get('vestingPeriodUntil').enable();
                }
            });
    }

    ngOnInit(): void {

        this.loading = true;

        this._seasonApiService
            .getAllSeasons()
            .pipe(untilDestroyed(this))
            .subscribe((seasons) => {
                this.seasons = seasons;
            });

        this.seasonFormControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(season => {
                this.formGroupDirective.form.patchValue({
                    vestingPeriodFrom: season?.startDate || null,
                    vestingPeriodUntil: season?.endDate || null
                });
            });
    }

    compareSeasons(season1: Season, season2: Season): boolean {
        return season1?.id === season2?.id;
    }

}
