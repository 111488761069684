import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { Download } from 'ngx-operators';

@Component({
    selector: 'u2b-loading-dialog',
    templateUrl: './loading-dialog.component.html',
    styleUrls: ['./loading-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class U2bLoadingDialogComponent implements OnDestroy {

    public header: string;

    public statusList: { message: string, doneMessage: string, iconName?: string }[] = [];

    public download$: Observable<Download>;

    public uploadProgress: number;

    constructor(
        public dialogRef: MatDialogRef<U2bLoadingDialogComponent>
    ) {
        dialogRef.addPanelClass('loading-dialog');
        dialogRef.disableClose = true;
    }

    ngOnDestroy(): void {
        this.statusList = [];
    }

    addStatus(message: string, doneMessage: string, iconName?: string): void {
        this.statusList.unshift({message, doneMessage, iconName});
    }

    removeLastStatus(): void {
        this.statusList.shift();
    }
}
