import { Component, Input, ViewChild } from '@angular/core';
import { AbstractControlOptions, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Boat } from '@shared/models/boat';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { BcmSettingsSectionName, DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';
import { MatAccordion } from '@angular/material/expansion';
import { v4 as uuidv4 } from 'uuid';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BaseArrayTabComponent } from '@sharedComponents/dialogs/booking-dialog/tabs/abstract/base-array-tab.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { U2bValidators } from '@shared/validators/validators';

@UntilDestroy()
@Component({
    selector: 'booking-berth-assignments-tab',
    templateUrl: './berth-assignments-tab.component.html',
    styleUrls: ['./berth-assignments-tab.component.scss'],
})
export class BerthAssignmentsTabComponent extends BaseArrayTabComponent<IBerthBoatAssignment, BerthBoatAssignment> {

    @ViewChild('accordion') accordion: MatAccordion;

    @Input() scrollOnCreate = false;

    boatFormGroup: UntypedFormGroup = this.formBuilder.group({});
    defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit;
    givenBoats: Boat[] = [];

    constructor(
        private _bcmSettingsFacade: BcmSettingsFacade,
        bookingDialogService: BookingDialogService,
        formBuilder: FormBuilder,
    ) {
        super(
            bookingDialogService,
            formBuilder,
            BookingAttribute.BERTH_ASSIGNMENTS,
            BerthBoatAssignment
        );

        this.defaultBerthReservationTimeUnit = this._bcmSettingsFacade.settings()[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];
    }

    formTemplate(value: BerthBoatAssignment): {
        controls: { [key: string]: any },
        options?: AbstractControlOptions | null
    } {
        return {
            controls: {
                id: [value?.id || null],
                from: [value?.from || new Date(), U2bValidators.required('Das Anreisedatum ist erforderlich')],
                to: [value?.to || null],
                berth: [value?.berth || null, U2bValidators.required('Der Liegeplatz ist erforderlich')],
                note: [value?.note || null],
                durationOfStay: [[]],
                electricMeterAssignments: [value?.electricMeterAssignments || []],
                uuid: [value?.uuid || uuidv4()],
                deleted: [value?.deleted || false]
            }
        };
    }

    addBerthAssignment(): void {

        const newAssignment = new BerthBoatAssignment({
            id: null,
            from: this.bookingDialogService.booking.to || new Date(),
            to: null,
            berth: null,
            boat: null,
            note: null,
            electricMeterAssignments: [],
            uuid: uuidv4(),
            deleted: false
        });

        this.addFormGroup(newAssignment);
    }

}
