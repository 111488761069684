<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button data-test="B-Dialog schließen"  mat-icon-button (click)="dialogRef.close(false)" aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <div class="mb-24" fxLayout="column" fxLayoutAlign="start start">
                <label class="discount-type">Adresse einer:</label>
                <mat-radio-group class="radio-group" formControlName="type">
                    <mat-radio-button data-test="RB-Person" class="radio-button" [value]="invoiceAddressType.Person">Person</mat-radio-button>
                    <mat-radio-button data-test="RB-Organisation" class="radio-button" [value]="invoiceAddressType.Company">Organisation</mat-radio-button>
                </mat-radio-group>
            </div>

            <ng-container *ngIf="formGroup.get('type').value === invoiceAddressType.Person">
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex>
                        <input type="text" matInput data-test="FI-Personen durchsuchen..." formControlName="person" placeholder="Personen durchsuchen..."
                               [matAutocomplete]="personSelector">
                        <mat-error>{{formGroup.get('person').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </div>

                <mat-autocomplete #personSelector="matAutocomplete" [displayWith]="displayPersonWith">
                    <mat-option *ngFor="let person of (filteredPersons$ | async)" [value]="person">
                        <span>{{ person.toString() }}</span>
                    </mat-option>
                </mat-autocomplete>
            </ng-container>

            <ng-container *ngIf="formGroup.get('type').value === invoiceAddressType.Company">
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex>
                        <input type="text" matInput data-test="FI-Organisationen durchsuchen..." formControlName="company" placeholder="Organisationen durchsuchen..."
                               [matAutocomplete]="companySelector">
                        <mat-error>{{formGroup.get('company').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </div>

                <mat-autocomplete #companySelector="matAutocomplete" [displayWith]="displayCompanyWith">
                    <mat-option *ngFor="let company of (filteredCompanies$ | async)" [value]="company">
                        <span>{{ company.name }}</span>
                    </mat-option>
                </mat-autocomplete>
            </ng-container>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Übernehmen" mat-flat-button
                (click)="save()"
                class="save-button mat-accent"
                [disabled]="isSaving || !formGroup.dirty"
                aria-label="Speichern">
            Übernehmen
        </button>

    </div>
</div>
