import {
    U2bColumnPipeName,
    U2bFilterSelectOptionsRelations,
    U2bTableData
} from '@core/components/layout/table/table.types';
import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import { Product } from '@shared/models/product';
import { getIsActive } from '@core/functions/get-is-active';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';
import { DataFilterOperator } from '@core/datafilter/filter-operator';
import { FILTER_OPERATORS_DEFAULT_SELECT } from '@core/datafilter/constants';

export const productsTableData: U2bTableData = {
    tableId: BcmUserTableSettingKey.Products,
    columnDefinitions: [
        {
            property: 'name',
            name: 'Name',
            isActive: getIsActive(),
            filter: {}
        },
        {
            property: 'account',
            name: 'Konto',
            isActive: getIsActive(),
            filter: {}
        },
        {
            property: 'dockSiteTag',
            name: 'Docksite',
            isActive: getIsActive(),
            parseItem: (product: Product) => product.dockSiteTag ? '✔' : '',
            fixedWidth: '100',
            filter: {
                property: 'dockSiteTag',
                fieldType: FilterFieldType.Boolean
            },
        },
        {
            property: 'category',
            name: 'Warengruppe',
            isActive: getIsActive(),
            parseItem: (product: Product) => product.category.name,
            filter: {
                property: 'category',
                fieldType: FilterFieldType.AutoComplete,
                compareAttribute: 'name',
                operators: FILTER_OPERATORS_DEFAULT_SELECT,
                selectOptionsRelation: U2bFilterSelectOptionsRelations.ProductCategories,
                selectOptionsDisplayWith: 'name',
            }
        },
        {
            property: 'unit',
            name: 'Einheit',
            isActive: getIsActive(),
            parseItem: (product: Product) => product.unit.name,
            filter: {
                property: 'unit',
                fieldType: FilterFieldType.AutoComplete,
                compareAttribute: 'name',
                operators: FILTER_OPERATORS_DEFAULT_SELECT,
                selectOptionsRelation: U2bFilterSelectOptionsRelations.Units,
                selectOptionsDisplayWith: 'name',
            }
        },
        {
            property: 'price',
            name: 'Einzelpreis ',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Currency,
            useNetGrossPriceHeaderLabel: true,
            filter: {
                fieldType: FilterFieldType.Numeric
            }
        },
        {
            property: 'Steuersatz',
            name: 'Steuer&shy;satz',
            isActive: getIsActive(),
            parseItem: (product: Product) => product.taxRate?.value || 0,
            pipe: U2bColumnPipeName.Percent,
            fixedWidth: '100',
            filter: {
                property: 'taxRate',
                fieldType: FilterFieldType.Select,
                compareAttribute: 'name',
                operators: [
                    DataFilterOperator.Equal,
                    DataFilterOperator.NotEqual,
                    DataFilterOperator.IsEmpty,
                ],
                selectOptionsRelation: U2bFilterSelectOptionsRelations.TaxRates,
                selectOptionsDisplayWith: 'value',
            }
        },
        {
            property: 'description',
            name: 'Beschreibung',
            isActive: getIsActive(false, false, false),
            parseItem: (product: Product) => product.description,
            filter: {}
        },
        {
            property: 'itemNumber',
            name: 'Artikelnummer',
            isActive: getIsActive(false, false, false),
            parseItem: (product: Product) => product.itemNumber,
            filter: {}
        },
    ],
};
