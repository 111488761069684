import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppNotificationService } from '@core/services/app-notification.service';
import { U2bValidators } from '@shared/validators/validators';

@Component({
    selector: 'add-assignment-dialog',
    templateUrl: './get-date-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class GetDateDialogComponent {

    formGroup: UntypedFormGroup;

    dateToday = new Date();

    public dialogTitle: string;

    public dateDefault = this.dateToday;

    public dateMax: Date;

    public dateMin: Date;

    constructor(
        public dialogRef: MatDialogRef<GetDateDialogComponent>,
        private _appNotificationService: AppNotificationService,
        private _formBuilder: UntypedFormBuilder,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            dialogTitle?: string,
            dateDefault?: Date,
            dateMax?: Date,
            dateMin?: Date,
        },
    ) {
        this.dialogTitle = this.data.dialogTitle || 'Datum auswählen';
        this.dateDefault = this.data.dateDefault || this.dateDefault;
        this.dateMax = this.data.dateMax;
        this.dateMin = this.data.dateMin;
        this._createForm();
    }

    public save(): void {

        if (this.formGroup.valid) {
            this.dialogRef.close(this.formGroup.get('date').value);
            return;
        }

        this.formGroup.markAllAsTouched();
        this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            date: [this.dateDefault, [U2bValidators.required('Bitte Datum angeben')]],
        });
    }
}
