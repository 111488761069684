import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
    selector: 'booking-dialog-info',
    template: `
        <u2b-modal>
            <div u2bModalHeadline>{{ data?.title }}</div>
            <div [innerHTML]="data?.message"></div>

            <div modalButtonLeft></div>

            <button modalButtonRight
                    mat-flat-button
                    color="accent"
                    (click)="dialogRef.close()">
                Verstanden
            </button>
        </u2b-modal>
    `
})
export class InfoDialogComponent {

    constructor(public dialogRef: MatDialogRef<InfoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string }) {
    }

}
