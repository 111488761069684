<form-widget-positions
        (invoiceCreated)="bookingDialogService.financialRecords.reload();bookingDialogService.positions.reload()"
        (positionsChanged)="bookingDialogService.positions.value = $event"
        [person]="bookingDialogService.person.value"
        [company]="bookingDialogService.company.value"
        [tenantRelation]="bookingDialogService.tenantRelationAssignment.value?.tenantRelation"
        [berthAssignments]="bookingDialogService.berthAssignments.value"
        [boat]="bookingDialogService.boat.value"
        [positions]="bookingDialogService.positions.value"
        [showAddBerthPositionButton]="true">
</form-widget-positions>

<form-widget-subscription
        (subscriptionsChanged)="bookingDialogService.subscriptions.value = $event"
        [berthAssignments]="bookingDialogService.berthAssignments.value"
        [subscriptions]="bookingDialogService.subscriptions.value">
</form-widget-subscription>

<form-widget-financial-records
        (financialRecordsChanged)="bookingDialogService.financialRecords.reload();bookingDialogService.positions.reload()"
        [financialRecords]="bookingDialogService.financialRecords.value">
</form-widget-financial-records>
