import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BcmApiService } from '@bcmApiServices/bcm-api.service';
import { BcmDocument, BcmDocumentDto } from '@shared/models/bcm-document';
import { parseHttpResponseItem, parseHttpResponsePage } from '@shared/functions/parse-http-response';
import { Page, PageRequest } from '@shared/models/pagination';

@Injectable({
    providedIn: 'root'
})
export class BcmDocumentsApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'documents';

    getAll(pageRequest?: PageRequest<BcmDocument>): Observable<Page<BcmDocument>> {
        return super.get<Page<BcmDocumentDto>>(this.RESOURCE_ENDPOINT, pageRequest?.getHttpParams())
            .pipe(parseHttpResponsePage<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    getExpiry(pageRequest?: PageRequest<BcmDocument>): Observable<Page<BcmDocument>> {
        return super.get<Page<BcmDocumentDto>>(`${this.RESOURCE_ENDPOINT}/expiry`, pageRequest?.getHttpParams())
            .pipe(parseHttpResponsePage<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<BcmDocument> {
        return super.get<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    add(document: BcmDocument, params: HttpParams = new HttpParams()): Observable<BcmDocument> {
        return super.post<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}`, document, params)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    update(document: BcmDocument, documentData: Partial<BcmDocument>): Observable<BcmDocument> {
        return super.put<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${document.id}`, documentData)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    remove(document: BcmDocument): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${document.id}`);
    }

}
