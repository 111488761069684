import { BcmBookingTraveler } from '@shared/models/bcm-booking';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

@UntilDestroy()
export class BookingDialogTravelers extends BookingDialogBaseEntity<BcmBookingTraveler, BcmBookingTraveler[]> {

    constructor(bookingDialogService: BookingDialogService) {
        super(bookingDialogService, BookingAttribute.TRAVELERS);
    }

}
