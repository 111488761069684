import { Component, OnInit } from '@angular/core';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export enum BookingDialogAppearance {
    TABS = 'tabs',
    ACCORDION = 'accordion',
}

export enum BookingDialogFirstChoice {
    PERSON_OR_COMPANY = 'personOrCompany',
    BOAT = 'boat',
}

@Component({
    selector: 'ui-settings',
    templateUrl: './ui-settings.component.html',
    styleUrls: ['./ui-settings.component.scss'],
})
export class UiSettingsComponent implements OnInit {

    readonly BookingDialogAppearance = BookingDialogAppearance;
    readonly BookingDialogFirstChoice = BookingDialogFirstChoice;

    formGroup: UntypedFormGroup;

    constructor(
        private formGroupBuilder: UntypedFormBuilder,
        public bcmSettingsFacade: BcmSettingsFacade,
    ) {
    }

    ngOnInit(): void {
        this.bcmSettingsFacade.loadSettings().subscribe((settings) => {
            this.createForm(settings[BcmSettingsSectionName.UISettings]);
        });
    }

    private createForm(uiSettings: any): void {
        this.formGroup = this.formGroupBuilder.group({
            bookingDialogAppearance: [uiSettings.bookingDialogAppearance || BookingDialogAppearance.ACCORDION],
            bookingDialogFirstChoice: [uiSettings.bookingDialogFirstChoice || BookingDialogFirstChoice.BOAT],
        });
    }

    save(event: MouseEvent): void {
        event.stopPropagation();

        const uiSettings = this.formGroup.getRawValue();

        this.bcmSettingsFacade
            .updateSection(BcmSettingsSectionName.UISettings, uiSettings);

    }

}
