import {
    U2bColumnDefinitionBrakePoint,
    U2bColumnDefinitionExternalComponent,
    U2bColumnPipeName,
    U2bTableData,
    U2bTableTextAlignments
} from '@core/components/layout/table/table.types';
import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import {
    BcmFinancialRecord,
    FinancialRecordStatus,
    financialRecordStatusTranslationsGer
} from '@shared/models/bcm-financial-record';
import { getIsActive } from '@core/functions/get-is-active';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';
import {
    FILTER_OPERATORS_DEFAULT_DATE,
    FILTER_OPERATORS_DEFAULT_SELECT,
    FILTER_OPERATORS_DEFAULT_STRING
} from '@core/datafilter/constants';
import { financialRecordTypeTranslationsDe, InvoiceTypes, ReceiptTypes } from '@shared/invoice-types';
import { BcmPaymentType, paymentTypeTranslationDe } from '@shared/models/person';
import { DataFilterOperationType } from '@core/datafilter/filter-operation-type';

export const financialRecordsTableData: U2bTableData = {
    tableId: BcmUserTableSettingKey.FinancialRecords,
    hasServerSideFiltering: true,
    uniqueIdentAttribute: 'number',
    columnDefinitions: [
        {
            property: 'typeText',
            sortProperty: 'type',
            name: 'Belegart',
            isActive: getIsActive(),
            filter: {
                property: 'type',
                fieldType: FilterFieldType.Select,
                operators: FILTER_OPERATORS_DEFAULT_SELECT,
                selectOptions: [
                    ...Object.values(InvoiceTypes),
                    ...Object.values(ReceiptTypes)
                ],
                selectedOperationType: DataFilterOperationType.Or,
                selectOptionsTranslations: financialRecordTypeTranslationsDe,
            }
        },
        {
            property: 'number',
            name: 'Nummer',
            isActive: getIsActive(),
            filter: {
                property: 'number',
                fieldType: FilterFieldType.Text,
                operators: FILTER_OPERATORS_DEFAULT_STRING
            }
        },
        {
            property: 'customerName',
            name: 'Empfänger',
            isActive: getIsActive(),
            externalComponent: U2bColumnDefinitionExternalComponent.FinancialRecordCustomer,
            filter: {
                property: 'customerName',
                fieldType: FilterFieldType.Text,
                operators: FILTER_OPERATORS_DEFAULT_STRING
            }
        },
        {
            property: 'creatorName',
            name: 'Mitarbeiter',
            isActive: getIsActive(),
            parseItem: (item: BcmFinancialRecord) => item.creatorName,
            filter: {
                property: 'creatorName',
                fieldType: FilterFieldType.Text,
                operators: FILTER_OPERATORS_DEFAULT_STRING
            }
        },
        {
            property: 'date',
            name: 'Datum',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Date,
            filter: {
                property: 'date',
                fieldType: FilterFieldType.Date,
                operators: FILTER_OPERATORS_DEFAULT_DATE
            }
        },
        {
            property: 'dueDate',
            name: 'Fällig zum',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Date,
            filter: {
                property: 'dueDate',
                fieldType: FilterFieldType.Date,
                operators: FILTER_OPERATORS_DEFAULT_DATE
            }
        },
        {
            property: 'status',
            name: 'Status',
            isActive: getIsActive(),
            externalComponent: U2bColumnDefinitionExternalComponent.InvoiceStatus,
            filter: {
                property: 'status',
                fieldType: FilterFieldType.Select,
                operators: FILTER_OPERATORS_DEFAULT_SELECT,
                selectOptions: Object.values(FinancialRecordStatus),
                selectOptionsTranslations: financialRecordStatusTranslationsGer
            }
        },
        {
            property: 'cancellationNumber',
            name: 'Storno-Nr.',
            isActive: getIsActive(),
            externalComponent: U2bColumnDefinitionExternalComponent.FinancialRecordCancellation,
            filter: {}
        },
        {
            property: 'cancellationReason',
            name: 'Storno-Grund',
            isActive: getIsActive(false, false, false),
            filter: {

            }
        },
        {
            property: 'total',
            name: 'Betrag (Brutto)',
            isActive: getIsActive(),
            alignment: {
                [U2bColumnDefinitionBrakePoint.Desktop]: U2bTableTextAlignments.Right,
                [U2bColumnDefinitionBrakePoint.Tablet]: U2bTableTextAlignments.Right,
                [U2bColumnDefinitionBrakePoint.Mobile]: U2bTableTextAlignments.Right,
            },
            pipe: U2bColumnPipeName.Currency,
            filter: {
                fieldType: FilterFieldType.Numeric,
            }
        },
        {
            property: 'paymentTypeDe',
            name: 'Zahlart',
            isActive: getIsActive(),
            filter: {
                property: 'paymentType',
                fieldType: FilterFieldType.Select,
                operators: FILTER_OPERATORS_DEFAULT_SELECT,
                selectOptions: Object.values(BcmPaymentType),
                selectOptionsTranslations: paymentTypeTranslationDe
            }
        },
        {
            property: 'isSEPA',
            name: 'SEPA',
            isActive: getIsActive(false, false, false),
            filter: {
                fieldType: FilterFieldType.Boolean,
            }
        },
    ]
};
