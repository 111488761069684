import {
    faFile,
    faFileAlt,
    faFileArchive,
    faFileAudio,
    faFileCode,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';

const iconClasses: { [key: string]: IconDefinition } = {
    // Media
    'image': faFileImage,
    'audio': faFileAudio,
    'video': faFileVideo,
    // Documents
    'application/pdf': faFilePdf,
    'application/msword': faFileWord,
    'application/vnd.ms-word': faFileWord,
    'application/vnd.oasis.opendocument.text': faFileWord,
    'application/vnd.openxmlformats-officedocument.wordprocessingml': faFileWord,
    'application/vnd.ms-excel': faFileExcel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml': faFileExcel,
    'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
    'application/vnd.ms-powerpoint': faFilePowerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml': faFilePowerpoint,
    'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
    'text/plain': faFileAlt,
    'text/html': faFileCode,
    'application/json': faFileCode,
    // Archives
    'application/gzip': faFileArchive,
    'application/zip': faFileArchive
};

export function mimeToFaIcon(mimeType?: string): IconDefinition {
    // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml

    const candidate = Object.entries(iconClasses).find(([k]) => mimeType?.startsWith(k));

    if (candidate === undefined) {
        return faFile;
    } else {
        return candidate[1];
    }

}
