import { Component } from '@angular/core';
import { AddBcmDocumentDialogComponent } from '@sharedComponents/dialogs/add-bcm-document-dialog/add-bcm-document-dialog.component';
import { BcmDocument } from '@shared/models/bcm-document';
import { downloadFile } from '@shared/functions/download-file';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FilesApiService } from '@bcmServices/index';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmDocumentsApiService } from '@bcmApiServices/bcm-documents.api-service';
import { BcmDocumentCategorySystemKey } from '@shared/models/bcm-document-category';
import { BookingDialogService } from '../../services/booking-dialog.service';
import { ContractCreatorComponent } from '@bcm-work-flows/contract-creator/contract-creator.component';
import { take } from 'rxjs';

@Component({
    selector: 'booking-documents-tab',
    templateUrl: './documents-tab.component.html',
    styleUrls: ['./documents-tab.component.scss']
})
export class DocumentsTabComponent {

    constructor(
        public bookingDialogService: BookingDialogService,
        private _matDialog: MatDialog,
        private _filesApiService: FilesApiService,
        private _confirmDialogService: ConfirmDialogService,
        private _appNotificationService: AppNotificationService,
        private _documentService: BcmDocumentsApiService,
        private _dialog: MatDialog
    ) {
    }

    addDocument(): void {
        this._matDialog
            .open(AddBcmDocumentDialogComponent, {
                data: {
                    documentCategorySystemKeys: [
                        BcmDocumentCategorySystemKey.BOC,
                        BcmDocumentCategorySystemKey.CON,
                        BcmDocumentCategorySystemKey.CRW,
                        BcmDocumentCategorySystemKey.INV
                    ]
                }
            })
            .afterClosed()
            .subscribe(async (document: BcmDocument | undefined) => {
                if (document) {

                    document.booking = this.bookingDialogService.booking;
                    document.person = this.bookingDialogService.booking.person;
                    document.company = this.bookingDialogService.booking.company;
                    document.boat = this.bookingDialogService.booking.boat;

                    this._documentService.add(document)
                        .subscribe((documentResult: BcmDocument) => {
                            if (documentResult && documentResult.id) {
                                this.bookingDialogService.documents.reload();
                            }
                        });
                }
            });
    }

    async editDocument(document: BcmDocument): Promise<void> {
        this._matDialog
            .open(AddBcmDocumentDialogComponent, {
                data: {
                    document: document,
                    documentCategorySystemKeys: [
                        BcmDocumentCategorySystemKey.BOC,
                        BcmDocumentCategorySystemKey.CON,
                        BcmDocumentCategorySystemKey.CRW,
                        BcmDocumentCategorySystemKey.INV
                    ]
                }
            })
            .afterClosed()
            .subscribe(async (documentData: BcmDocument | undefined) => {
                if (documentData) {

                    documentData.booking = this.bookingDialogService.booking;
                    documentData.person = this.bookingDialogService.booking.person;
                    documentData.company = this.bookingDialogService.booking.company;
                    documentData.boat = this.bookingDialogService.booking.boat;

                    this._documentService.update(document, documentData)
                        .subscribe((documentResult: BcmDocument) => {
                            if (documentResult && documentResult.id) {
                                this.bookingDialogService.documents.reload();
                                this._appNotificationService.showSuccess(`Dokument wurde aktualisiert`);
                            }
                        });
                }
            });
    }

    async onClickFileDownload(document: BcmDocument): Promise<void> {
        this._filesApiService
            .getOne(document.file)
            .subscribe((file) => {
                downloadFile(file, document.file?.name, document.file.fileExtension, file.type);
            });
    }

    async onClickDocumentDelete(document: BcmDocument): Promise<void> {
        this._confirmDialogService
            .useWarnTheme()
            .setTitle('Dokument wirklich entfernen?')
            .setBody(`Möchtest Du das Dokument "${document?.title}" wirklich unwiderruflich entfernen?`)
            .openWithCallback(() => {
                this._documentService
                    .remove(document)
                    .subscribe((result) => {
                        if (result) {
                            this.bookingDialogService.documents.reload();
                            this._appNotificationService.showSuccess(`Dokument wurde entfernt`);
                        }
                    });
            });
    }

    openContractCreator(): void {

        const dialog = this._dialog.open(ContractCreatorComponent, {
            data: {
                booking: this.bookingDialogService.booking,
            },
            disableClose: true,
            panelClass: ['xxl-dialog'],
        });

        dialog.afterClosed()
            .pipe(take(1))
            .subscribe((response) => {
                if (response) {
                    this.bookingDialogService.documents.reload();
                }
            });

    }

}
