import { Component, Inject, Optional } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Invoice } from '@shared/models/invoice';
import { InvoicePayment } from '@shared/models/invoice-payment';
import { BcmPaymentType, paymentTypeTranslationDe } from '@shared/models/person';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';
import { EditPaymentDialogComponent } from '@modules/bcm/accounting/invoices/dialogs/edit-payment-dialog/edit-payment-dialog.component';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { CapturePaymentDialogComponent } from '@modules/bcm/accounting/invoices/dialogs/capture-payment-dialog/capture-payment-dialog.component';

@UntilDestroy()
@Component({
    selector: 'invoice-payments-dialog',
    templateUrl: './payments-dialog.component.html',
})
export class PaymentsDialogComponent {

    protected invoice: Invoice;

    protected paymentTypeTranslationDe = paymentTypeTranslationDe;

    tsePaymentTypes: BcmPaymentType[] = [
        BcmPaymentType.Cash,
        BcmPaymentType.EcCard,
        BcmPaymentType.CreditCard,
        BcmPaymentType.PaymentProvider
    ];

    constructor(
        public dialogRef: MatDialogRef<PaymentsDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: { invoice: Invoice },
        private appNotificationService: AppNotificationService,
        private informationDialogService: InformationDialogService,
        private confirmDialogService: ConfirmDialogService,
        private invoicesService: InvoicesService,
        private matDialog: MatLegacyDialog
    ) {
        this.invoice = data.invoice;
    }

    showNote(payment: InvoicePayment) {
        this.informationDialogService
            .setBody(payment.note)
            .open();
    }

    addPayment() {
        this.matDialog
            .open(CapturePaymentDialogComponent, {data: {invoice: this.invoice}})
            .afterClosed()
            .subscribe(updatedInvoice => {
                if (updatedInvoice) {
                    this.invoice = updatedInvoice;
                }
            });
    }

    updatePayment(payment?: InvoicePayment) {
        this.matDialog
            .open(EditPaymentDialogComponent, {data: {invoice: this.invoice, payment}})
            .afterClosed()
            .subscribe(updatedInvoice => {
                if (updatedInvoice) {
                    this.invoice = updatedInvoice;
                }
            });
    }

    deletePayment(payment: InvoicePayment) {
        this.confirmDialogService
            .useWarnTheme()
            .setBody(`<p>Bist Du sicher, dass Du die Zahlung unwiderruflich löschen möchtest?</p>`)
            .openWithCallback(
                () => {
                    this.invoicesService
                        .removePayment(payment)
                        .subscribe((updatedInvoice) => {
                            this.invoice = updatedInvoice;
                            this.appNotificationService.showSuccess(`Zahlung gelöscht.`);
                        });
                }
            );
    }

    closeDialog() {
        this.dialogRef.close(this.invoice);
    }
}
