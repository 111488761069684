import { Component, Inject, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Person } from '@shared/models/person';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { U2bAddressValidators } from '@shared/validators/address/address-validators';
import { U2bValidators } from '@shared/validators/validators';
import { BoatApiService, CompanyApiService, PersonApiService } from '@modules/bcm/@shared/services';
import { U2bCompanyValidators } from '@shared/validators/company/company-validators';
import { Company } from '@shared/models/company';
import { AppNotificationService } from '@core/services/app-notification.service';
import { isString } from '@shared/functions/is-string';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Boat } from '@shared/models/boat';
import { U2bBoatValidators } from '@shared/validators/boat/boat-validators';

enum EntityType {
    Person,
    Company,
    Boat
}

const entityTypeNames: { [key in EntityType]: string } = {
    [EntityType.Person]: 'Person',
    [EntityType.Company]: 'company', // translation key
    [EntityType.Boat]: 'boat', // translation key
};

@Component({
    selector: 'get-person-or-organisation-dialog',
    templateUrl: './get-entity-dialog.component.html',
    styles: ['.dialog-title { font-size: 14px }']
})
export class GetEntityDialogComponent {

    formGroup: UntypedFormGroup;

    persons$: Observable<Person[]>;
    filteredPersons$: Observable<Person[]>;

    companies$: Observable<Company[]>;
    filteredCompanies$: Observable<Company[]>;

    boats$: Observable<Boat[]>;
    filteredBoats$: Observable<Boat[]>;

    EntityType = EntityType;

    entityTypeNames = entityTypeNames;

    isSaving: boolean;

    selectableEntities: EntityType[];

    constructor(
        public dialogRef: MatDialogRef<GetEntityDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            selectableEntities?: EntityType[],
        },
        private _formBuilder: UntypedFormBuilder,
        private _personApiService: PersonApiService,
        private _companyApiService: CompanyApiService,
        private _boatApiService: BoatApiService,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogRef.addPanelClass('default-dialog');

        if (data?.selectableEntities?.length) {
            this.selectableEntities = data.selectableEntities;
        } else {
            this.selectableEntities = Object.values(EntityType)
                .filter((value) => typeof value !== 'string') as EntityType[];
        }

        this._createForm();
        this._prepareEntities();
    }

    save(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;

        const formValue = this.formGroup.value;

        switch (formValue.type) {
            case EntityType.Person:
                this.dialogRef.close({person: formValue.person});
                break;
            case EntityType.Company:
                this.dialogRef.close({company: formValue.company});
                break;
            case EntityType.Boat:
                this.dialogRef.close({boat: formValue.boat});
                break;
        }
    }

    public displayEntityWith(entityType: EntityType): (entity: Person | Company | Boat) => string {
        switch (entityType) {
            case EntityType.Boat:
                return (boat) => boat ? boat.toString() : '';
            case EntityType.Person:
                return (person) => person ? person.toString() : '';
            case EntityType.Company:
                return (company) => company ? company.toString() : '';
        }
    }

    private _prepareEntities() {
        if (this.selectableEntities.includes(EntityType.Person)) {
            this._loadPersons();
        }

        if (this.selectableEntities.includes(EntityType.Company)) {
            this._loadCompanies();
        }

        if (this.selectableEntities.includes(EntityType.Boat)) {
            this._loadBoats();
        }
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            type: [null],
            person: [
                {value: null, disabled: true},
                [
                    U2bValidators.required('Bitte Auswahl treffen'),
                    U2bAddressValidators.addressExists()
                ]
            ],
            company: [
                {value: null, disabled: true},
                [
                    U2bValidators.required('Bitte Auswahl treffen'),
                    U2bCompanyValidators.companyExists()
                ]
            ],
            boat: [
                {value: null, disabled: true},
                [
                    U2bValidators.required('Bitte Auswahl treffen'),
                    U2bBoatValidators.boatExists()
                ]
            ]
        });

        this.formGroup.get('type').valueChanges.subscribe((value) => {
            switch (value) {
                case EntityType.Person:
                    this.formGroup.get('boat').disable();
                    this.formGroup.get('person').enable();
                    this.formGroup.get('company').disable();
                    break;
                case EntityType.Company:
                    this.formGroup.get('boat').disable();
                    this.formGroup.get('person').disable();
                    this.formGroup.get('company').enable();
                    break;
                case EntityType.Boat:
                    this.formGroup.get('boat').enable();
                    this.formGroup.get('person').disable();
                    this.formGroup.get('company').disable();
                    break;
            }
        });

        this.formGroup.get('type').setValue(this.selectableEntities[0]);
    }

    private _loadPersons(): void {
        this.persons$ = this._personApiService.getAll();

        this.filteredPersons$ = this.formGroup.get('person').valueChanges
            .pipe(
                startWith(this.persons$),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((value) => isString(value) ? this._filterPersons(value || '') : this.persons$)
            );
    }

    private _filterPersons(filter = ''): Observable<Person[]> {
        return this.persons$
            .pipe(
                map((persons: Person[]) => persons.filter(person => {
                    return (person.firstName + person.lastName).toLowerCase().includes(filter.toLowerCase());
                }))
            );
    }

    private _loadCompanies(): void {
        this.companies$ = this._companyApiService.getAll();

        this.filteredCompanies$ = this.formGroup.get('company').valueChanges
            .pipe(
                startWith(this.companies$),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((value) => isString(value) ? this._filterCompanies(value) : this.companies$)
            );
    }

    private _filterCompanies(filter = ''): Observable<Company[]> {
        return this.companies$
            .pipe(
                map((companies: Company[]) => companies.filter(company => {
                    return company.name.toLowerCase().includes(filter.toLowerCase());
                }))
            );
    }

    private _loadBoats(): void {
        this.boats$ = this._boatApiService.getAll();

        this.filteredBoats$ = this.formGroup.get('boat').valueChanges
            .pipe(
                startWith(this.boats$),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((value) => isString(value) ? this._filterBoats(value) : this.boats$)
            );
    }

    private _filterBoats(filter = ''): Observable<Boat[]> {
        return this.boats$
            .pipe(
                map((boats: Boat[]) => boats.filter(boat => {
                    return (boat.name || '').toLowerCase().includes(filter)
                        || (boat.licensePlate || '').toLowerCase().includes(filter);
                }))
            );
    }
}
