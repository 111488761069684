import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ProductsApiService, TenantRelationsApiService } from '@bcmServices/index';
import { TenantRelation } from '@shared/models/tenant-relation';
import { BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { Product } from '@shared/models/product';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BcmDefaultAsset, BcmDefaultAssetDto } from '@shared/models/bcm-default-asset';
import { DefaultAssetsApiService } from '@bcmApiServices/default-assets.api-service';
import { cloneDeep } from '@shared/functions/clone-deep';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { hours, minutes } from '@shared/constants/date';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export enum DEFAULT_BERTH_RESERVATION_UNIT {
    NONE = null,
    DAY = 0,
    OVERNIGHT_STAY = 1
}

@UntilDestroy()
@Component({
    selector: 'default-units',
    templateUrl: './default-units.component.html',
    styleUrls: ['./default-units.component.scss'],
})
export class DefaultUnitsComponent implements OnInit {

    readonly DEFAULT_BERTH_RESERVATION_UNIT = DEFAULT_BERTH_RESERVATION_UNIT;

    readonly BookingType = BookingType;

    readonly hours = hours;
    readonly minutes = minutes;

    tenantRelations: TenantRelation[];

    products: Product[];

    formsDirty; // created this as checking 

    formGroup = this.fb.group({
        dayGuestBoater: [],
    });

    electricMeterFormGroup = this.fb.group({
        productID: [],
    });

    bookingTypeFormControl: FormControl = new FormControl(BookingType.Reservation);

    timeFormGroup = this.fb.group({
        type: [DEFAULT_BERTH_RESERVATION_UNIT.NONE],
        dailyGuestFromHours: [{value: null}],
        dailyGuestFromMinutes: [{value: null}],
        dailyGuestToHours: [{value: null}],
        dailyGuestToMinutes: [{value: null}],
        overnightGuestFromHours: [{value: null}],
        overnightGuestFromMinutes: [{value: null}],
        overnightGuestToHours: [{value: null}],
        overnightGuestToMinutes: [{value: null}],
    });

    defaultAssetsDataSource: MatTableDataSource<BcmDefaultAsset> = new MatTableDataSource<BcmDefaultAsset>();

    defaultAssetsDisplayedColumns = ['name', 'length', 'width', 'buttons'];

    isSaving = false;

    isDeleting = false;

    assetInEditMode: BcmDefaultAssetDto;

    assetInEditModeOriginal: BcmDefaultAssetDto;

    assetToDelete: BcmDefaultAssetDto;

    newAsset: BcmDefaultAssetDto = {
        name: '',
        width: 0,
        length: 0
    };

    protected readonly BcmUserPermission = BcmUserPermission;

    constructor(
        private fb: UntypedFormBuilder,
        private appNotificationService: AppNotificationService,
        private confirmDialogService: ConfirmDialogService,
        private tenantRelationsApiService: TenantRelationsApiService,
        private defaultAssetsApiService: DefaultAssetsApiService,
        private productsApiService: ProductsApiService,
        public bcmSettingsFacade: BcmSettingsFacade,
    ) {
    }

    ngOnInit(): void {
        this.tenantRelationsApiService.getAll()
            .pipe(untilDestroyed(this))
            .subscribe((tr) => this.tenantRelations = tr);

        this.productsApiService.getAll()
            .pipe(untilDestroyed(this))
            .subscribe((p) => this.products = p);

        this.bcmSettingsFacade.loadSettings()
            .pipe(untilDestroyed(this))
            .subscribe((settings) => {

                this.formGroup.patchValue(settings[BcmSettingsSectionName.DefaultUnits]);
                this.electricMeterFormGroup.patchValue(settings[BcmSettingsSectionName.ElectricMeter]);
                this.bookingTypeFormControl.setValue(settings[BcmSettingsSectionName.DefaultBookingType].type || BookingType.Reservation);

                const {
                    type,
                    // fromHours,
                    // fromMinutes,
                    // toHours,
                    // toMinutes,
                    dailyGuestToHours,
                    dailyGuestToMinutes,
                    dailyGuestFromHours,
                    dailyGuestFromMinutes,
                    overnightGuestToHours,
                    overnightGuestToMinutes,
                    overnightGuestFromHours,
                    overnightGuestFromMinutes,
                } = settings[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];

                if (type !== '') {
                    this.timeFormGroup.patchValue({
                        type,
                        dailyGuestToHours: dailyGuestToHours ? String(dailyGuestToHours).padStart(2, '0') : null,
                        dailyGuestToMinutes: dailyGuestToMinutes ? String(dailyGuestToMinutes).padStart(2, '0') : null,
                        dailyGuestFromHours: dailyGuestFromHours ? String(dailyGuestFromHours).padStart(2, '0') : null,
                        dailyGuestFromMinutes: dailyGuestFromMinutes ? String(dailyGuestFromMinutes).padStart(2, '0') : null,
                        overnightGuestToHours: overnightGuestToHours ? String(overnightGuestToHours).padStart(2, '0') : null,
                        overnightGuestToMinutes: overnightGuestToMinutes ? String(overnightGuestToMinutes).padStart(2, '0') : null,
                        overnightGuestFromHours: overnightGuestFromHours ? String(overnightGuestFromHours).padStart(2, '0') : null,
                        overnightGuestFromMinutes: overnightGuestFromMinutes ? String(overnightGuestFromMinutes).padStart(2, '0') : null,
                    }, {emitEvent: false});
                }
            });

        this.formGroup.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(data => {
                this.formsDirty = this.formGroup.dirty;
            });

        this.bookingTypeFormControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(data => {
                this.formsDirty = this.bookingTypeFormControl.dirty;
            });

        this.electricMeterFormGroup.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.formsDirty = this.electricMeterFormGroup.dirty;
            });

        this.timeFormGroup.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(data => {
                this.formsDirty = this.timeFormGroup.dirty;
            });

        this.defaultAssetsApiService.getAll()
            .pipe(untilDestroyed(this))
            .subscribe(defaultAssets => this.defaultAssetsDataSource.data = defaultAssets);

    }

    save(event): void {
        event.stopPropagation();

        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        const defaultSettings = this.formGroup.value;
        const bookingType = this.bookingTypeFormControl.value;
        const electricMeterSettings = this.electricMeterFormGroup.value;
        const timeSettings = this.timeFormGroup.getRawValue();

        this.bcmSettingsFacade
            .updateSection(BcmSettingsSectionName.DefaultUnits, defaultSettings)
            .add(() => {
                this.formGroup.markAsPristine();
            });

        this.bcmSettingsFacade
            .updateSection(BcmSettingsSectionName.DefaultBookingType, {type: bookingType})
            .add(() => {
                this.bookingTypeFormControl.markAsPristine();
            });

        this.bcmSettingsFacade
            .updateSection(BcmSettingsSectionName.ElectricMeter, electricMeterSettings)
            .add(() => {
                this.formGroup.markAsPristine();
            });

        this.bcmSettingsFacade
            .updateSection(BcmSettingsSectionName.DefaultBerthReservationTimeUnit, timeSettings)
            .add(() => {
                this.formGroup.markAsPristine();
            });
    }

    onAssetAdd(newAsset: BcmDefaultAssetDto): void {
        this.isSaving = true;

        this.defaultAssetsApiService
            .add(newAsset)
            .pipe(untilDestroyed(this))
            .subscribe((addedAsset: BcmDefaultAsset) => {

                const data = this.defaultAssetsDataSource.data;
                data.push(addedAsset);
                this.defaultAssetsDataSource.data = data;

                newAsset.name = '';
                newAsset.width = undefined;
                newAsset.length = undefined;

                this.appNotificationService.showSuccess(`Asset Vorlage "${addedAsset.name}" erfolgreich gespeichert`);
            })
            .add(() => this.isSaving = false);
    }

    onAssetEdit(asset: BcmDefaultAsset): void {
        asset.inEditMode = true;
        this.assetInEditMode = asset;
        this.assetInEditModeOriginal = cloneDeep(asset);
    }

    onAssetEditCancel(asset: BcmDefaultAsset): void {
        const {name, length, width} = this.assetInEditModeOriginal;
        asset.inEditMode = false;
        asset.name = name;
        asset.length = length;
        asset.width = width;
        this.assetInEditModeOriginal = null;
        this.assetInEditMode = null;
    }

    onAssetSave(asset: BcmDefaultAsset): void {
        this.isSaving = true;
        this.defaultAssetsApiService.update(asset)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                asset.inEditMode = false;
                this.assetInEditModeOriginal = null;
                this.assetInEditMode = null;
                this.appNotificationService.showSuccess(`Änderungen an Asset Vorlage "${asset.name}" wurde gespeichert.`);
            })
            .add(() => this.isSaving = false);
    }

    onAssetDelete(asset: BcmDefaultAsset): void {
        this.isDeleting = true;
        this.assetToDelete = asset;
        this.confirmDialogService
            .setTitle('Asset Vorlage wirklich entfernen?')
            .setTheme('warn')
            .openWithCallback(() => {
                this.defaultAssetsApiService.remove(asset)
                    .pipe(untilDestroyed(this))
                    .subscribe(() => {

                        const data = this.defaultAssetsDataSource.data;
                        const index: number = data.findIndex(d => d === asset);
                        data.splice(index, 1);
                        this.defaultAssetsDataSource.data = data;

                        this.appNotificationService.showSuccess(`Asset Vorlage "${asset.name}" wurde entfernt.`);
                    })
                    .add(() => {
                        this.isDeleting = false;
                        this.assetToDelete = null;
                    });
            }, () => {
                this.isDeleting = false;
                this.assetToDelete = null;
            }, () => {
                this.isDeleting = false;
                this.assetToDelete = null;
            });
    }

}
