import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { downloadFile } from '@shared/functions/download-file';
import { parseHttpResponseList } from '@shared/functions/parse-http-response';
import { BcmInvoiceWdTemplate } from '@shared/models/bcm-invoice-wd-template';

@Injectable({providedIn: 'root'})
export class BcmInvoiceWdTemplatesApiService extends BcmApi2Service {

    getAll(params: HttpParams = new HttpParams()): Observable<BcmInvoiceWdTemplate[]> {
        return super.get<BcmInvoiceWdTemplate[]>('invoice-wd-templates', params)
            .pipe(parseHttpResponseList<any, BcmInvoiceWdTemplate>(BcmInvoiceWdTemplate));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<BcmInvoiceWdTemplate> {
        return super.get<BcmInvoiceWdTemplate>(`invoice-wd-templates/${id}`, params);
    }

    create(invoiceTemplate: BcmInvoiceWdTemplate): Observable<BcmInvoiceWdTemplate> {
        return super.post('invoice-wd-templates', invoiceTemplate);
    }

    update(invoiceTemplate: BcmInvoiceWdTemplate): Observable<BcmInvoiceWdTemplate> {
        return super.put(`invoice-wd-templates/${invoiceTemplate.id}`, invoiceTemplate);
    }

    remove(invoiceTemplate: BcmInvoiceWdTemplate): Observable<any> {
        return super.delete(`invoice-wd-templates/${invoiceTemplate.id}`);
    }

    generateTestInvoiceWdTemplate(invoiceTemplate: BcmInvoiceWdTemplate): void {
        super.getBlob(`invoice-wd-templates/${invoiceTemplate.id}/generate-test`)
            .subscribe((file) => downloadFile(file, invoiceTemplate.title, 'pdf'), console.error);
    }

    getWordTemplate(): void {
        super.getBlob(`invoice-wd-templates/template`)
            .subscribe((file) => downloadFile(file, 'Rechnung_Vorlage.docx', 'docx'), console.error);
    }

}
