import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { SepaRun, SepaRunStatus } from '@shared/models/sepa-run';

@Injectable({
    providedIn: 'root'
})
export class SepaRunsApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'sepa-runs';

    getAll(params: HttpParams = new HttpParams()): Observable<SepaRun[]> {
        return super.get<SepaRun[]>(this.RESOURCE_ENDPOINT, params);
    }

    getOne(sepaRun: SepaRun, params: HttpParams = new HttpParams()): Observable<any> {
        return super.get(`${this.RESOURCE_ENDPOINT}/${sepaRun.id}`, params);
    }

    getFile(sepaRun: SepaRun, params: HttpParams = new HttpParams().set('type', 'blob')): Observable<any> {
        return super.getBlob(`${this.RESOURCE_ENDPOINT}/${sepaRun.id}`, params);
    }

    add(body?: any, params: HttpParams = new HttpParams()): Observable<number> {
        super.suppressErrorForNextRequest();
        return super.post(`${this.RESOURCE_ENDPOINT}`, body, params);
    }

    updateStatus(sepaRun: SepaRun, status: SepaRunStatus): Observable<any> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${sepaRun.id}`, new HttpParams().set('status', status));
    }

    remove(sepaRun: SepaRun): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${sepaRun.id}`);
    }

}
