import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseValidator } from '@shared/validators/base-validator';
import { Company } from '@shared/models/company';

export class CompanyExistsValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(message?: string) {
        super();

        this.validationError = new BaseValidationError('companyExists', message || 'Falsche Eingabe. Bitte Organisation aus der Liste wählen.');
    }

    public validate({value}: AbstractControl): ValidationErrors {
        if (!value || (value instanceof Company && value.id != null)) {
            return this.IS_VALID;
        }

        this.validationError.message = `Keine Organisation namens ${value} gefunden. Bitte Suchbegriff überprüfen.`;
        return this.IS_INVALID;
    }
}
