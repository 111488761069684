<div fxFlex="100" fxLayout="column" class="travelers-tab">

    <div [class.mb-16]="(formGroups$ | async)?.length > 0" class="mt-16">
        <mat-accordion [multi]="false">
            <ng-container
                    *ngFor="let formGroup of (formGroups$ | async); let index = index">
                <booking-traveler
                        [travelerFormGroup]="formGroup"
                        (removeTraveler)="removeFormGroup(index)"></booking-traveler>
            </ng-container>
        </mat-accordion>
    </div>

    <button class="add-btn" mat-stroked-button color="accent"
            (click)="addFormGroup()">Reisenden hinzufügen
    </button>

</div>
