import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductSubscription } from '@shared/models/product-subscription';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';

@Component({
    selector: 'form-widget-subscription',
    templateUrl: './form-widget-subscription.component.html',
    styleUrls: ['./form-widget-subscription.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormWidgetSubscriptionComponent {

    @Input()
    headline = 'Abos';

    @Input()
    readonly = false;

    @Input()
    berthAssignments?: BerthBoatAssignment[];

    @Input()
    set subscriptions(value: ProductSubscription[]) {
        this._subscriptions = value.map(subscription => {
            return {
                ...subscription,
                berthAssignmentIndex: this._getBerthAssignmentIndex(subscription.berthAssignment?.uuid),
                totalPrice: (subscription.customPrice || subscription.product.price) * subscription.quantity || 0
            };
        });
    }

    get subscriptions(): ProductSubscription[] {
        return this._subscriptions;
    }

    @Output()
    subscriptionsChanged: EventEmitter<ProductSubscription[]> = new EventEmitter<ProductSubscription[]>();

    colspan = 8;

    private _subscriptions: ProductSubscription[] = [];

    constructor() {
    }

    removeSubscription(index: number): void {
        this.subscriptions[index].deleted = true;
        this.subscriptionsChanged.emit(this.subscriptions);
    }

    private _getBerthAssignmentIndex(berthAssignmentUuid: string): number {
        return this.berthAssignments?.findIndex(assignment => assignment.uuid === berthAssignmentUuid);
    }


}
