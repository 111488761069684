<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ dialogTitle }}</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <ng-container *ngIf="showPaymentType">
            <u2b-message type="info">
                <ng-container [ngSwitch]="electricMeterAssignment.paymentType">
                    <ng-container *ngSwitchCase="ElectricMeterPaymentType.None">
                        Strom wird nicht berechnet.
                    </ng-container>
                    <ng-container *ngSwitchCase="ElectricMeterPaymentType.BasicCharge">
                        Der Strompreis wird auf Basis des Verbrauchs und dem gewählten Produkts berechnet.
                    </ng-container>
                    <ng-container *ngSwitchCase="ElectricMeterPaymentType.Flatrate">
                        Strom wurde pauschal berechnet.
                    </ng-container>
                </ng-container>
            </u2b-message>
        </ng-container>

        <form [formGroup]="formGroup">

            <ng-container *ngIf="showNoProductHint; else formTemplate">
                <u2b-message type="warning">
                    <!-- todo: add check for bcm_settings electric meter product like in the electric meter module/component -->
                    Es wurden noch keine Produkte angelegt, die der Einheit kWh (Kilowattstunde) zugeordnet
                    wurden.<br><br>
                    Bitte lege ein Produkt an welches den aktuellen Strompreis angibt.
                    Wähle beim Anlegen des Produkts im Feld "Einheit" den Wert <strong>kWh</strong> und im Feld
                    "Zahlungsweise" den Wert <strong>{{ 'on_the_next_invoice' | translate }}</strong> aus.<br><br>

                    <button data-test="B-Produkt anlegen" mat-flat-button (click)="addNewProduct()">
                        Produkt anlegen
                    </button>
                </u2b-message>
            </ng-container>

            <ng-template #formTemplate>
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex appearance="outline">
                        <mat-label>{{ 'untilWhenBoatAtElectricMeter' | translate }}</mat-label>
                        <input matInput data-test="FI-Bis wann ist/war das Boot am Stromzähler?"
                               formControlName="to"
                               appMaskDate placeholder="TT.MM.JJJJ"
                               [matDatepicker]="untilDatePicker"
                               [min]="electricMeterAssignment.from"
                               [max]="meterReadingDateMax">
                        <mat-datepicker-toggle data-test="FIT-Bis wann ist/war das Boot am Stromzähler?" matSuffix
                                               [for]="untilDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #untilDatePicker></mat-datepicker>
                        <mat-error>{{ formGroup.get('to').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex appearance="outline">
                        <mat-label>{{ 'electricMeterValueAtEndOfBoat' | translate }}</mat-label>
                        <app-input-number matInput
                                          data-test="FI-Zählerstand bei Abschluss des Bootes"
                                          [formControl]="formGroup.get('meterReadingEnd') | formControl"></app-input-number>
                        <mat-hint>
                            <ng-container *ngIf="electricMeterAssignment.reading?.startMeterReading > -1">
                                Startzählerstand: {{ electricMeterAssignment.reading?.startMeterReading | number : '1.0-4' }}
                                |
                                Verbrauch: {{ formGroup.get('meterReadingEnd').value - electricMeterAssignment.reading?.startMeterReading | number : '1.0-4' }}
                                kWh
                            </ng-container>
                        </mat-hint>
                        <mat-error>{{ formGroup.get('meterReadingEnd').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="electricMeterAssignment.paymentType === ElectricMeterPaymentType.BasicCharge">
                    <div fxLayout="row" fxLayoutAlign="start start" class="mt-8">
                        <mat-form-field fxFlex appearance="outline">
                            <mat-label>Strompreis - Produkt wählen</mat-label>
                            <input type="text" matInput data-test="FI-Strompreis - Produkt wählen"
                                   formControlName="product"
                                   placeholder="Produkte durchsuchen..."
                                   [matAutocomplete]="productSelector">
                            <mat-hint>Produkte der Warengruppe "Stromgebühr"</mat-hint>
                        </mat-form-field>

                        <mat-autocomplete #productSelector="matAutocomplete" [displayWith]="displayProductWith">
                            <ng-container *ngIf="loadingProducts">
                                <mat-option disabled class="loading">
                                    <mat-spinner diameter="35"></mat-spinner>
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="!loadingProducts">
                                <mat-option *ngFor="let product of (filteredProducts$ | async | orderBy : 'name')"
                                            [value]="product">
                                    <span>{{ product.name }}</span>
                                    <small> | Preis: {{ product.price }}/{{ product.unit?.name }}</small>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </div>
                </ng-container>
            </ng-template>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16 p-sm-24" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Speichern" mat-flat-button
                (click)="save()"
                class="save-button mat-accent"
                [disabled]="isSaving || !formGroup.dirty"
                aria-label="Speichern">
            <mat-icon>save</mat-icon>
            Speichern
        </button>

    </div>
</div>
