<div fxLayout="column">
    <ng-container *ngIf="formGroup">
        <form [formGroup]="formGroup" class="settings w-100-p"
              fxFlex fxLayout="column" data-test="C-UI-Einstellungen">

            <div class="fuse-card auto-width">

                <div class="p-16"
                     fxLayout="column" fxLayoutAlign="center stretch"
                     fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">

                    <div fxLayout="row" fxLayoutAlign="start center">

                        <div fxLayout="column" fxLayoutAlign="start start">
                            <div class="h2">
                                UI Einstellungen
                            </div>
                        </div>

                    </div>

                    <ng-template #saving>
                        <mat-icon>save</mat-icon>
                        <span>Speichert...</span>
                        <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                    </ng-template>

                    <ng-template #notSaving>
                        <mat-icon>save</mat-icon>
                        <span>Speichern</span>
                    </ng-template>

                    <button data-test="B-Speichern" mat-flat-button
                            color="accent"
                            [disabled]="(bcmSettingsFacade.isUpdating$ | async) || !formGroup.dirty"
                            (click)="$event.stopPropagation(); save($event)">
                        <ng-container
                                *ngIf="(bcmSettingsFacade.isUpdating$ | async); then saving else notSaving"></ng-container>
                    </button>

                </div>

                <mat-divider class="my-24"></mat-divider>

                <div class="p-16">

                    <div fxLayout="row wrap"
                         fxLayout.lt-sm="column"
                         fxLayoutGap="16px grid"
                         fxLayoutGap.lt-sm="0px">

                        <div fxFlex="100">
                            <h2>
                                Buchungs-Dialog
                            </h2>
                        </div>

                        <div fxFlex="60" fxFlexAlign="center">
                            <h4>
                                Welches Layout soll standardmäßig verwendet werden?
                            </h4>
                        </div>

                        <mat-form-field appearance="outline" fxFlex="40">
                            <mat-select formControlName="bookingDialogAppearance">
                                <mat-option [value]="BookingDialogAppearance.ACCORDION">Scrollbare Liste</mat-option>
                                <mat-option [value]="BookingDialogAppearance.TABS">Tabs</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div fxFlex="60" fxFlexAlign="center">
                            <h4>
                                Was soll im Dialog an erster Stelle ausgewählt werden?
                            </h4>
                        </div>

                        <mat-form-field appearance="outline" fxFlex="40">
                            <mat-select formControlName="bookingDialogFirstChoice">
                                <mat-option [value]="BookingDialogFirstChoice.BOAT">Boot</mat-option>
                                <mat-option [value]="BookingDialogFirstChoice.PERSON_OR_COMPANY">Person / Organisation
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                </div>
            </div>
        </form>
    </ng-container>
</div>
