<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Zahlung erfassen für {{invoice.invoiceType | translate}} {{invoice.invoiceNumber}}</span>
            <button mat-icon-button
                    data-test="B-Dialog schließen"
                    aria-label="Dialog schließen"
                    (click)="dialogRef.close()"
                    [disabled]="isSaving">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <u2b-message>
                Bereits gezahlt: {{ invoice.paidAmount | bcmDynamicCurrency }}<br>
                Ausstehender Betrag: {{ invoice.missingAmount | bcmDynamicCurrency }}
            </u2b-message>

            <div fxLayout="column">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Zahlart</mat-label>
                    <mat-select data-test="FS-Prefix" formControlName="paymentType">
                        <ng-container *ngFor="let paymentType of allowedPaymentTypes">
                            <mat-option [value]="paymentType">{{ paymentTypeTranslationDe[paymentType] }}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error>{{ formGroup.get('paymentType').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <ng-container
                        *ngIf="formGroup.get('paymentType').value === BcmPaymentType.Cash ||
                        formGroup.get('paymentType').value === BcmPaymentType.EcCard ||
                        formGroup.get('paymentType').value === BcmPaymentType.CreditCard">
                    <app-check-pay-by-cash class="check-pay-by-cash"
                                           [parentFormGroup]="formGroup"
                                           (cashRegisterEmitter)="cashRegister = $event"></app-check-pay-by-cash>
                </ng-container>

                <mat-form-field fxFlex="100%" appearance="outline" floatLabel="always">
                    <mat-label>Zahlung eingegangen am</mat-label>
                    <input matInput
                           formControlName="date"
                           appMaskDate placeholder="TT.MM.JJJJ"
                           [matDatepicker]="fromDatePicker"
                           [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>

                    <mat-error>
                        <ng-container *ngIf="formGroup.get('date').hasError('matDatepickerMax');">
                            Das Datum darf nicht in der Zukunft liegen
                        </ng-container>
                        <ng-container *ngIf="formGroup.get('date').hasError('matDatepickerParse');">
                            Bitte gib ein korrektes Datumsformat ein. TT.MM.JJJJ
                        </ng-container>
                        {{ formGroup.get('date')?.errors | firstErrorMessage }}
                    </mat-error>

                </mat-form-field>

                <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                    <mat-label>Betrag</mat-label>
                    <app-input-number matInput formControlName="amount"></app-input-number>
                    <mat-error>{{ formGroup.get('amount')?.errors | firstErrorMessage }}</mat-error>
                    <mat-hint *ngIf="formGroup.get('amount')?.value === 0">
                        Sicher, dass eine {{ 0 | bcmDynamicCurrency }} Zahlung erfasst werden soll?
                    </mat-hint>
                </mat-form-field>

                <mat-form-field fxFlex="100%" appearance="outline" floatLabel="always">
                    <mat-label>Bemerkung</mat-label>
                    <textarea matInput formControlName="note" rows="3" autocomplete="new-password"></textarea>
                    <mat-error>{{ formGroup?.get('note')?.errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

            </div>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button mat-button
                data-test="B-Abbrechen"
                class="save-button mat-primary"
                aria-label="Abbrechen"
                (click)="dialogRef.close()"
                [disabled]="isSaving">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>

        <button mat-flat-button
                data-test="B-Speichern"
                color="accent"
                class="save-button"
                aria-label="Speichern"
                [disabled]="isSaving || ((!cashRegister || formGroup.get('checkPayByCash').errors?.length > 0) &&
                                        (formGroup.get('paymentType').value === BcmPaymentType.Cash ||
                                        formGroup.get('paymentType').value === BcmPaymentType.EcCard ||
                                        formGroup.get('paymentType').value === BcmPaymentType.CreditCard))"
                (click)="save()">
            <mat-icon>save</mat-icon>
            Speichern
        </button>

    </div>
</div>
