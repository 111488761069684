import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { BcmDocumentCategory, BcmDocumentCategorySystemKey } from '@shared/models/bcm-document-category';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { BcmDocumentCategoriesFacade } from '@bcmServices/documents/bcm-document-categories-facade';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { compareObjectsById } from '@shared/functions/compare-objects-by-id';

@Component({
    selector: 'get-document-category',
    templateUrl: './get-document-category.component.html',
    styleUrls: ['./get-document-category.component.scss']
})
export class GetDocumentCategoryComponent implements OnInit, OnDestroy {

    @Input() appearance: MatFormFieldAppearance = 'outline';

    @Input() multiSelect = false;

    @Input() documentCategorySystemKeys: BcmDocumentCategorySystemKey[] = [];

    @Input() set documentCategoryFormControl(value: AbstractControl) {
        this._formControl = value as UntypedFormControl;
        this._addFormControlListeners(this._formControl);
    }

    get documentCategoryFC(): UntypedFormControl {
        return this._formControl;
    }

    private _formControl: UntypedFormControl;

    private _unsubscribeAll = new Subject();

    private _documentCategories: BcmDocumentCategory[] = [];

    filteredDocumentCategories$: Observable<BcmDocumentCategory[]>;

    compareObjectsById = compareObjectsById;

    constructor(private _bcmDocumentCategoriesFacade: BcmDocumentCategoriesFacade) {
    }

    ngOnInit(): void {
        this._loadDocumentCategories();

        if (this.multiSelect) {
            this._formControl.setValue(this._formControl.value);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _addFormControlListeners(formControl: UntypedFormControl): void {
        if (!formControl) {
            return;
        }
        this.filteredDocumentCategories$ = combineLatest([
            formControl.valueChanges
                .pipe(
                    startWith((this.multiSelect) ? [] : ''),
                    debounceTime(DEFAULT_DEBOUNCE_TIME)
                ),
            this._bcmDocumentCategoriesFacade
                .page$
                .pipe(
                    filter(val => !!val),
                    map(page => {
                        if (this.documentCategorySystemKeys?.length) {
                            this._documentCategories = page.results
                                .filter(_ => (this.documentCategorySystemKeys || []).includes(_.systemKey));
                        } else {
                            this._documentCategories = page.results;
                        }

                        if (this._documentCategories?.length === 1) {
                            this._formControl.setValue(this._documentCategories[0]);
                            this._formControl.disable();
                        }
                    })
                )
        ])
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((value) => isString(value[0]) && !this.multiSelect ? this._filterDocumentCategories(value[0]) : this._documentCategories)
            );
    }

    private _loadDocumentCategories(): void {
        this._documentCategories = [];
        this._bcmDocumentCategoriesFacade.loadAll();
    }

    private _filterDocumentCategories(search = ''): BcmDocumentCategory[] {
        return this._documentCategories.filter(documentCategory => documentCategory.name.toLowerCase().includes(search.toLowerCase()));
    }

    displayWithDocumentCategory(documentCategory: BcmDocumentCategory): string {
        return documentCategory?.name || '';
    }

}
