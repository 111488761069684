import { PayableOption } from '@shared/models/payable-option';
import { isValidDate } from '@core/functions/is-valid-date';
import { addDays, addHours, addMonths, addWeeks, addYears, subMinutes } from 'date-fns';
import { PayableOptionId } from '@bcmApiServices/product-payable-option.api-service';

export function getVestingPeriodUntil(vestingPeriodFrom: Date, payableOption: PayableOption): Date {

    if (!isValidDate(vestingPeriodFrom) || !payableOption) {
        return null;
    }

    let newDate: Date;

    switch (payableOption.id) {
        case PayableOptionId.Yearly:
            newDate = addYears(vestingPeriodFrom, 1);
            break;
        case PayableOptionId.HalfYearly:
            newDate = addMonths(vestingPeriodFrom, 6);
            break;
        case PayableOptionId.QuarterYearly:
            newDate = addMonths(vestingPeriodFrom, 3);
            break;
        case PayableOptionId.Monthly:
            newDate = addMonths(vestingPeriodFrom, 1);
            break;
        case PayableOptionId.Weekly:
            newDate = addWeeks(vestingPeriodFrom, 1);
            break;
        case PayableOptionId.Daily:
            newDate = addDays(vestingPeriodFrom, 1);
            break;
        case PayableOptionId.Hourly:
            newDate = addHours(vestingPeriodFrom, 1);
            break;
    }

    return subMinutes(newDate, 1);
}
