import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { BcmDocument } from '@shared/models/bcm-document';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmFile } from '@shared/models/bcm-file';
import { FilesApiService } from '@modules/bcm/@shared/services';
import { downloadFile } from '@shared/functions/download-file';
import { U2bValidators } from '@shared/validators/validators';
import { now } from 'lodash';
import { BcmDocumentCategorySystemKey } from '@shared/models/bcm-document-category';
import { HttpErrorResponse } from '@angular/common/http';
import { AppNotificationService } from '@core/services/app-notification.service';
import { GetEntityDialogComponent } from '@sharedComponents/dialogs/get-entity-dialog/get-entity-dialog.component';
import { Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { Boat } from '@shared/models/boat';

@Component({
    selector: 'app-add-bcm-document-dialog',
    templateUrl: './add-bcm-document-dialog.component.html',
    styleUrls: ['./add-bcm-document-dialog.component.scss']
})
export class AddBcmDocumentDialogComponent implements OnInit, OnDestroy {

    formGroup: UntypedFormGroup;

    isSaving = false;

    document: BcmDocument;

    filesToDelete: BcmFile[] = [];
    uploadedFiles: BcmFile[] = [];

    uploadError: string;

    editableOwner: boolean;

    private _unsubscribeAll = new Subject();

    constructor(public dialogRef: MatDialogRef<AddBcmDocumentDialogComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: {
                    document?: BcmDocument,
                    dateOfExpiryRequired?: boolean,
                    documentCategorySystemKeys: BcmDocumentCategorySystemKey[],
                    editableOwner?: boolean,
                },
                public bcmService: BcmService,
                private _matDialog: MatDialog,
                private _formBuilder: UntypedFormBuilder,
                private _appNotificationService: AppNotificationService,
                private _filesApiService: FilesApiService) {
        if (data?.document) {
            this.document = {...data.document} as BcmDocument;
            this.document.file = (this.document.file?.mimeType === 'DUMMY_BCM_DOCUMENTS') ? null : this.document.file;
        } else {
            this.document = {} as BcmDocument;
        }

        if (data?.editableOwner != null) {
            this.editableOwner = data.editableOwner;
        }
    }

    ngOnInit(): void {
        this._createForm();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    changeOwner(): void {
        this._matDialog.open(GetEntityDialogComponent)
            .afterClosed()
            .subscribe((data?: {
                person?: Person,
                company?: Company,
                boat?: Boat
            }) => {
                if (data?.person) {
                    this.document.person = data.person;
                    this.formGroup.get('owner').setValue(data.person.toString());
                } else if (data?.company) {
                    this.document.company = data.company;
                    this.formGroup.get('owner').setValue(data.company.toString());
                } else if (data?.boat) {
                    this.document.boat = data.boat;
                    this.formGroup.get('owner').setValue(data.boat.toString());
                }
            });
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            owner: [{value: null, disabled: true}],
            title: [this.data?.document?.title || null, [U2bValidators.required('Bitte gib eine Bezeichnung für dieses Dokument an.')]],
            documentCategory: [this.data?.document?.documentCategory || null, [U2bValidators.required('Bitte gib eine Kategorie für dieses Dokument an.')]],
            documentIdent: this.data?.document?.documentIdent || null,
            dateOfExpiry: [this.data?.document?.dateOfExpiry || null,
                this.data?.dateOfExpiryRequired ? [U2bValidators.required('Das Dokument einer Regelprüfung benötigt eine Gültigkeit.')] : []],
            note: this.data?.document?.note || null
        });

        if (this.data?.document?.person) {
            this.formGroup.get('owner').setValue(this.data.document.person.toString());
        } else if (this.data?.document?.company) {
            this.formGroup.get('owner').setValue(this.data.document.company.toString());
        } else if (this.data?.document?.boat) {
            this.formGroup.get('owner').setValue(this.data.document.boat.toString());
        }
    }

    downloadFile(file: BcmFile): void {
        this._filesApiService.getById(file.id).pipe(take(1)).subscribe(blob => {
            downloadFile(blob, file.fileName, file.fileExtension);
        });
    }

    deleteFile(file: BcmFile): void {
        this.filesToDelete.push(file);
        this.document.file = null;
    }

    uploadCompleted(bcmFile: BcmFile): void {

        if (!bcmFile) {
            return;
        }

        this.uploadError = undefined;
        this.document.file = bcmFile;
        this.uploadedFiles.push(bcmFile);

        this.formGroup.markAsDirty();

        if (!(this.formGroup.get('title').value?.length > 0)) {
            this.formGroup.get('title').setValue(bcmFile.fileName);
        }
    }

    uploadFailed(error: HttpErrorResponse): void {
        this.uploadError = error.error?.message;
    }

    close(): void {
        this.uploadError = undefined;

        if (this.uploadedFiles.length > 0) {
            forkJoin([...this.uploadedFiles.map(file => this._filesApiService.deleteById(file.id))])
                .pipe(take(1))
                .subscribe(() => this.dialogRef.close());
        } else {
            this.dialogRef.close();
        }
    }

    save(): void {

        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder.');
            return;
        }

        const {title, documentCategory, documentIdent, dateOfExpiry, note} = this.formGroup.getRawValue();

        this.uploadError = undefined;
        this.document = {
            ...this.document,
            title,
            documentCategory,
            documentIdent,
            dateOfExpiry,
            note,
            expired: dateOfExpiry?.getTime() < now()
        };

        delete this.document.file?.fileBlob;

        if (this.filesToDelete.length > 0) {
            forkJoin([...this.filesToDelete.map(file => this._filesApiService.deleteById(file.id))])
                .pipe(take(1))
                .subscribe(() => this.dialogRef.close(this.document));
        } else {
            this.dialogRef.close(this.document);
        }

    }

}
