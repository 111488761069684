import { HttpParams } from '@angular/common/http';

const PAGE_SIZE_KEY = 'pagination_page_size';
const PAGE_SIZE_DEFAULT = 25;

export class PageRequest<T extends {id?: any} = unknown> {

    index = 0;

    filter?: string;

    props = new Map<string, string>();

    static get pageSize(): number {
        return parseInt(localStorage.getItem(PAGE_SIZE_KEY), 10) || PAGE_SIZE_DEFAULT;
    }

    static set pageSize(size: number) {
        localStorage.setItem(PAGE_SIZE_KEY, String(size || PAGE_SIZE_DEFAULT));
    }

    constructor(public sort: keyof T = 'id',
                public sortDirection: 'asc' | 'desc' | '' = 'asc') {
        this.props = new Map<string, string>();
        this.index = 0;
    }

    public getHttpParams(): HttpParams {
        let params = new HttpParams()
            .set('page', this?.index || 0)
            .set('index', this?.index || 0)
            .set('size', PageRequest.pageSize)
            .set('filter', this?.filter || '')
            .set('sort', this?.sort ? String(this?.sort) : 'id')
            .set('sortDirection', this?.sortDirection || 'asc');

        if (this?.props) {
            for (const [key, value] of this.props.entries()) {
                params = params.set(key, value);
            }
        }

        return params;
    }

}

export class Page<T> {
    page: number;
    size: number;
    total: number;
    results: T[];
}
