<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">

    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Dokument {{(data?.document?.id) ? 'bearbeiten' : 'erstellen'}}</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="close()"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>


    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <div class="input-row"
                 fxLayout="column wrap"
                 fxLayout.gt-xs="row wrap"
                 fxLayoutGap="12px grid">

                <ng-container *ngIf="editableOwner">
                    <div fxFlex="100" fxLayout="row wrap">
                        <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                            <mat-label>Halter / Besitzer</mat-label>
                            <input matInput formControlName="owner">
                            <mat-error>{{formGroup.get('owner').errors | firstErrorMessage}}</mat-error>
                            <button matSuffix mat-icon-button (click)="changeOwner()">
                                <mat-icon color="accent">edit</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </ng-container>

                <div fxFlex="60" fxLayout="row wrap">

                    <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                        <mat-label>Bezeichnung</mat-label>
                        <input matInput data-test="FI-Title" formControlName="title">
                        <mat-error>{{formGroup.get('title').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                        <mat-label>Identifikationsnummer / Police-nummer</mat-label>
                        <input matInput data-test="FI-documentIdent" formControlName="documentIdent">
                    </mat-form-field>

                </div>

                <div fxFlex="40">

                    <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                        <mat-label>Bemerkung / Notiz</mat-label>
                        <textarea matInput data-test="FT-Bemerkung / Notiz" formControlName="note"
                                  rows="5"></textarea>
                    </mat-form-field>

                </div>

                <div fxFlex="60">
                    <get-document-category
                            [documentCategoryFormControl]="this.formGroup.get('documentCategory')"
                            [documentCategorySystemKeys]="data?.documentCategorySystemKeys"></get-document-category>
                </div>

                <mat-form-field fxFlex="40" appearance="outline" floatLabel="always">
                    <mat-label>
                        Gültig bis
                    </mat-label>
                    <input matInput
                           appMaskDate
                           placeholder="TT.MM.JJJJ"
                           formControlName="dateOfExpiry"
                           [matDatepicker]="datePicker">
                    <button type="button" matSuffix mat-icon-button matTooltip="Felder Leeren"
                            [disabled]="!formGroup.get('dateOfExpiry').value"
                            (click)="formGroup.get('dateOfExpiry').reset()">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <mat-datepicker-toggle data-test="FIT-Gültig bis" matSuffix
                                           [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                    <mat-error>{{formGroup.get('dateOfExpiry').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>

                <ng-container *ngIf="document?.file?.id else uploadForm">
                    <div class="box">
                        <div class="file" fxLayout="row">
                            <div class="file-name">{{document.file.fileName}}</div>
                            <button style="margin-left: auto" mat-button aria-label="remove" color="accent"
                                    data-test="B-Datei herunterladen"
                                    matTooltip="Datei herunterladen"
                                    (click)="downloadFile(document.file)">
                                <mat-icon>download</mat-icon>
                            </button>
                            <button mat-button aria-label="remove" color="warn"
                                    data-test="B-Datei entfernen"
                                    matTooltip="Datei entfernen"
                                    (click)="deleteFile(document.file)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>

                <ng-template #uploadForm>
                    <file-upload-v2
                            [buttonText]="'Datei auswählen'"
                            [showDropDownArea]="false"
                            [fullscreenDropDownArea]="true"
                            [uploadEndpoint]="bcmService.apiBaseUrl + 'files'"
                            [disableWhileUploading]="true"
                            [maxFileSize]="'4 MB'"
                            (uploadCompleted)="uploadCompleted($event)"
                            (uploadFailed)="uploadFailed($event)">
                        <ng-container *ngIf="uploadError">
                            <u2b-message type="error">
                                {{uploadError}}
                            </u2b-message>
                        </ng-container>
                    </file-upload-v2>
                </ng-template>

            </div>

        </form>

    </div>

    <div mat-dialog-actions class="p-16 p-sm-24 m-0" fxLayout="row" fxLayoutAlign="space-between center">
        <button data-test="B-Abbrechen" mat-button
                (click)="close()"
                aria-label="Dialog schließen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>
        <button data-test="B-Speichern" mat-flat-button
                color="accent"
                (click)="save()"
                class="save-button mat-accent"
                [disabled]="isSaving"
                aria-label="Speichern">
            <mat-icon>save</mat-icon>
            Speichern
        </button>
    </div>

</div>
