<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title"> Vertrag für {{ person?.fullName || company?.fullName }}.</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="onClickClose()"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>


    <div [formGroup]="formGroup" mat-dialog-content fusePerfectScrollbar>

        <ng-container *ngIf="!availableContracts?.length; else showContracts">
            <u2b-message type="warning">
                Das System konnte keine Vertragsvorlagen für die Beziehung
                <strong>{{ person?.firstActiveTenantRelationAssignment?.tenantRelation.name || company?.firstActiveTenantRelationAssignment?.tenantRelation.name }}</strong>
                finden.
                Wurden im
                <ng-container *ngIf="!data?.booking; else noLink">
                    <button mat-button [bcmRouterLink]="'contract-manager/contracts'" (click)="onClickClose()">
                        Vertragsmanager
                    </button>
                </ng-container>
                <ng-template #noLink>
                    <span>Vertragsmanager</span>
                </ng-template>
                Vertragsvorlagen angelegt und der entsprechenden Beziehung zugewiesen?
            </u2b-message>
        </ng-container>

        <ng-template #showContracts>

            <div>
                <h2>Vertragsvorlagen</h2>
                <p>
                    Für die
                    Beziehung {{ person?.firstActiveTenantRelationAssignment?.tenantRelation.name || company?.firstActiveTenantRelationAssignment?.tenantRelation.name }}
                    wurden
                    folgende Vertragsvorlagen hinterlegt:
                </p>
            </div>

            <div class="mb-24" fxLayout="column">
                <div fxFlex="100">
                    <mat-form-field appearance="fill" floatLabel="always" fxFlex="100">
                        <mat-label>Vertragsvorlage</mat-label>
                        <input type="text" matInput data-test="FI-Vertragsvorlage" formControlName="contract"
                               autocomplete="new-password"
                               [matAutocomplete]="contractAttachmentSelector">
                        <mat-error>{{ formGroup.get('contract').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>

                    <mat-autocomplete fxFlex="0" #contractAttachmentSelector="matAutocomplete"
                                      [displayWith]="getOptionText">
                        <mat-option disabled class="loading" *ngIf="loadingContracts">
                            <mat-spinner diameter="35"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="availableContracts?.length">
                            <mat-option *ngFor="let contract of (filteredContracts$ | async) | orderBy: 'title'"
                                        [value]="contract">
                                <span>{{ contract.title }}</span>
                                <ng-container *ngIf="contract.isWordTemplate">
                                    <fa-icon class="contract-word-icon" [icon]="faFileWord"></fa-icon>
                                </ng-container>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </div>

            <form-widget-date-range [parentFormGroup]="formGroup"
                                    headline="Vertragslaufzeit"></form-widget-date-range>

            <ng-container *ngIf="hasBoat">
                <mat-divider class="special-divider"></mat-divider>

                <ng-container *ngIf="!availableBoats?.length">
                    <div>
                        <u2b-message type="warning">
                            <span [innerHtml]="('personOrCompanyHasNoBoat' | translate : {name: (person?.fullName || company?.fullName)})"></span>
                        </u2b-message>

                        <!--                            <p>-->
                        <!--                                <mat-checkbox [(ngModel)]="captureBoatData" [ngModelOptions]="{standalone: true}">-->
                        <!--                                    Bootsdaten erfassen-->
                        <!--                                </mat-checkbox>-->
                        <!--                            </p>-->
                    </div>

                    <!--                        <ng-container *ngIf="captureBoatData">-->
                    <!--                            <form-widget-boat></form-widget-boat>-->
                    <!--                        </ng-container>-->
                </ng-container>

                <ng-container *ngIf="availableBoats?.length > 1">
                    <div>
                        <h2>{{ 'selectBoat' | translate }}</h2>
                    </div>

                    <div>
                        <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                            <mat-label>{{ 'boat' | translate }}</mat-label>
                            <mat-select matInput data-test="FI-Boot" formControlName="boat"
                                        [compareWith]="compareBoats">
                                <mat-option *ngFor="let boat of availableBoats"
                                            [value]="boat">
                                    <bcm-boat-link [disableLink]="true" [boat]="boat"></bcm-boat-link>
                                </mat-option>
                            </mat-select>
                            <mat-error>{{ formGroup.get('boat').errors | firstErrorMessage }}</mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

                <ng-container *ngIf="availableBoats?.length === 1">
                    <div>
                        <h2>{{ 'usedBoat' | translate }}: <strong>{{ formGroup.get('boat').value.name }}</strong>
                        </h2>
                        <u2b-message type="info">
                            <span [innerHtml]="'personOrCompanyHasBoat' | translate : {name: person.fullNameBackward}"></span>
                        </u2b-message>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="hasBerth && availableBerths">
                <mat-divider class="special-divider"></mat-divider>

                <ng-container *ngIf="formGroup.get('boat').value && !availableBerths?.length">
                    <div>
                        <u2b-message type="warning">
                            <p [innerHtml]="'boatHasNoBerthAssignment' | translate"></p>
                        </u2b-message>
                    </div>
                </ng-container>

                <ng-container *ngIf="availableBerths?.length > 1">
                    <div>
                        <h2>Wähle einen Liegeplatz</h2>
                    </div>

                    <div>
                        <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                            <mat-label>Liegeplatz</mat-label>
                            <mat-select matInput data-test="FI-Liegeplatz" formControlName="berth"
                                        [compareWith]="compareBerth">
                                <mat-option *ngFor="let berth of availableBerths"
                                            [value]="berth">
                                    {{ berth.handle }}
                                    <ng-container *ngIf="berth.pier?.handle">- {{ berth.pier?.handle }}
                                    </ng-container>
                                </mat-option>
                            </mat-select>
                            <mat-error>{{ formGroup.get('berth').errors | firstErrorMessage }}</mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

                <ng-container *ngIf="formGroup.get('berth').value && availableBerths?.length === 1">
                    <div>
                        <h2>Verwendeter Liegeplatz: <strong>{{ formGroup.get('berth')?.value?.handle }}</strong>
                        </h2>
                        <u2b-message type="info">
                            Dem ausgewählten {{ 'boat' | translate }} ist der
                            Liegeplatz {{ formGroup.get('berth')?.value?.handle }}
                            zugewiesen. Dieser wurde automatisch übernommen.
                        </u2b-message>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="hasCoOwner">
                <mat-divider class="special-divider"></mat-divider>
                <form-widget-contract-co-owner [parentFormGroup]="formGroup"
                                               [knownShortCodeList]="knownShortCodeList"></form-widget-contract-co-owner>
            </ng-container>

            <ng-container *ngIf="hasBkz">
                <mat-divider class="special-divider"></mat-divider>
                <form-widget-building-cost-subsidy [parentFormGroup]="formGroup"
                                                   [knownShortCodeList]="knownShortCodeList"></form-widget-building-cost-subsidy>
            </ng-container>

            <ng-container *ngIf="hasProducts">
                <mat-divider class="special-divider"></mat-divider>
                <form-widget-product-list [parentFormGroup]="formGroup"></form-widget-product-list>
            </ng-container>

            <ng-container *ngIf="hasKey">
                <mat-divider class="special-divider"></mat-divider>
                <ng-container *ngIf="person && !person.keys?.length">
                    <u2b-message type="warning">
                        <p>
                            Es wurde der Platzhalter [SCHLUESSEL_NUMMER] verwendet, aber {{ person?.fullName }}
                            wurde
                            kein Schlüssel zugewiesen.
                        </p>
                    </u2b-message>
                </ng-container>

                <ng-container *ngIf="person && person.keys?.length">
                    <div>
                        <h2>Wähle einen Schlüssel</h2>
                    </div>

                    <div>
                        <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                            <mat-label>Schlüssel</mat-label>
                            <mat-select matInput data-test="FI-Boot" formControlName="key"
                                        [compareWith]="compareKeys">
                                <mat-option *ngFor="let key of person.keys"
                                            [value]="key">
                                    <bcm-key-link [key]="key.key" [disableLink]="true"></bcm-key-link>
                                    (Nummer: {{ key.keyNumber }})
                                </mat-option>
                            </mat-select>
                            <mat-error>{{ formGroup.get('key').errors | firstErrorMessage }}</mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="unknownShortCodeList?.length && unknownShortCodeForm">

                <mat-divider class="special-divider"></mat-divider>

                <div>
                    <h2>Unbekannte Platzhalter</h2>
                </div>

                <ng-container *ngFor="let unknownShortCode of unknownShortCodeList">
                    <div [formGroup]="unknownShortCodeForm">
                        <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                            <mat-label>{{ unknownShortCode }}</mat-label>
                            <input matInput data-test="FI-Boot"
                                   [formControlName]="unknownShortCode">
                        </mat-form-field>
                    </div>
                </ng-container>

            </ng-container>

            <mat-divider class="special-divider"></mat-divider>

            <div class="mb-24" fxLayout="column">

                <ng-template #noMail>
                    <u2b-message>
                        <header>Keine E-Mail erfasst</header>
                        Der Vertrag kann nicht per E-Mail versendet werden, da {{ (person || company).fullName }}
                        keine E-Mal zugewiesen ist.
                    </u2b-message>
                </ng-template>

                <ng-container *ngIf="contractTemplateMissing">
                    <u2b-message type="error">Aktuell ist bei dieser Vorlage kein Word Dokument hinterlegt,
                        bitte
                        lade ein aktuelles Dokument in den Vertragsvorlagen hoch.
                    </u2b-message>
                </ng-container>

                <ng-container *ngIf="(person || company).mail; else noMail">
                    <p>
                        <mat-checkbox formControlName="sendMail"
                                      [disabled]="contractTemplateMissing">
                            Vertrag per E-Mail an <strong>{{ (person || company).mail }}</strong> versenden
                        </mat-checkbox>
                    </p>
                </ng-container>

                <p>
                    <mat-checkbox formControlName="downloadPdf"
                                  [disabled]="contractTemplateMissing">
                        Vertrag nach dem Speichern herunterladen (PDF)
                    </mat-checkbox>
                </p>

                <ng-container
                        *ngIf="selectedContract?.isWordTemplate && selectedContract.hasDifferentTemplates && !formGroup.get('sendMail').value">
                    <p>
                        <mat-radio-group class="contract-file-type-rg" fxFlex="100" fxLayout="row"
                                         formControlName="contractFileType">
                            <mat-radio-button [disabled]="!selectedContract?.templateMailFile"
                                              fxFlex="100" [value]="ContractFileType.MAIL">
                                E-Mail Vorlage
                            </mat-radio-button>
                            <mat-radio-button [disabled]="!selectedContract?.templatePrintFile"
                                              fxFlex="100" [value]="ContractFileType.PRINT">
                                Druck Vorlage
                            </mat-radio-button>
                        </mat-radio-group>
                    </p>
                </ng-container>

            </div>

            <mat-divider class="special-divider"></mat-divider>

            <form-widget-signature [parentFormGroup]="formGroup"
                                   conditionCheckboxLabel="Vertrag vom Vertragsempfänger jetzt unterschreiben lassen"></form-widget-signature>

            <ng-container
                    *ngIf="missingFields.person?.length || missingFields.company?.length || missingFields.tenant?.length || missingFields.berth?.length || missingFields.boat?.length">
                <div style="margin-top: 24px;">
                    <u2b-message type="warning" [collapsable]="true">
                        <header>Es fehlen Daten, die für den Vertrag notwendig sind</header>
                        <p>
                            Fehlende Daten werden im Vertragsdokument leer gelassen!
                        </p>
                        <ng-container *ngIf="missingFields.tenant?.length">
                            <p>
                                <strong>Fehlende Stammdaten des Hafens</strong>
                            </p>
                            <ul>
                                <li *ngFor="let item of missingFields.tenant">{{ item }}</li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="person && missingFields.person?.length">
                            <p>
                                <strong>Fehlende Personendaten</strong>
                            </p>
                            <ul>
                                <li *ngFor="let item of missingFields.person">{{ item }}</li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="company && missingFields.company?.length">
                            <p>
                                <strong>Fehlende Organisationsdaten</strong>
                            </p>
                            <ul>
                                <li *ngFor="let item of missingFields.company">{{ item }}</li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="missingFields.boat?.length">
                            <p>
                                <strong>Fehlende Bootsdaten</strong>
                            </p>
                            <ul>
                                <li *ngFor="let item of missingFields.boat">{{ item }}</li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="missingFields.berth?.length">
                            <p>
                                <strong>Fehlende Liegeplatzdaten</strong>
                            </p>
                            <ul>
                                <li *ngFor="let item of missingFields.berth">{{ item }}</li>
                            </ul>
                        </ng-container>
                    </u2b-message>
                </div>
            </ng-container>
        </ng-template>

    </div>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Abbrechen" mat-flat-button (click)="onClickClose()" type="button">
            Abbrechen
        </button>

        <div>

            <ng-template #saving>
                <mat-icon>save</mat-icon>
                <span>Speichert...</span>
                <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
            </ng-template>

            <ng-template #notSaving>
                <mat-icon>save</mat-icon>
                <span>Speichern</span>
            </ng-template>

            <button (click)="onClickSaveAndClose()" data-test="B-Speichern"
                    mat-flat-button color="accent"
                    [disabled]="isSaving || !formGroup?.dirty || !availableContracts?.length">
                <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
            </button>

        </div>

    </div>

</div>
