import { Component } from '@angular/core';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';
import { BaseTabComponent } from '@sharedComponents/dialogs/booking-dialog/tabs/abstract/base-tab.component';
import { BcmBookingGeneralData, BcmBookingGeneralDataDto, ParticipantType } from '@shared/models/bcm-booking';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { AbstractControlOptions, FormBuilder } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

@UntilDestroy()
@Component({
    selector: 'booking-general-tab',
    templateUrl: './general-tab.component.html',
    styleUrls: ['./general-tab.component.scss']
})
export class GeneralTabComponent extends BaseTabComponent<BcmBookingGeneralDataDto, BcmBookingGeneralDataDto> {

    readonly BookingType = BookingType;
    readonly ParticipantType = ParticipantType;

    constructor(
        bookingDialogService: BookingDialogService,
        formBuilder: FormBuilder
    ) {
        super(
            bookingDialogService,
            formBuilder,
            BookingAttribute.GENERAL,
            BcmBookingGeneralData
        );
    }

    formTemplate(value: BcmBookingGeneralDataDto | undefined): {
        controls: { [key: string]: any },
        options?: AbstractControlOptions | null
    } {
        return {
            controls: {
                bookingType: [value?.bookingType || BookingType.Reservation],
                reservedUntil: [value?.reservedUntil || null],
                reservationText: [value?.reservationText || null],
                note: [value?.note || null],
                status: [value?.status || null],
                isBoatOwner: [value?.isBoatOwner || false],
                personOrCompany: [value?.personOrCompany || null]
            }
        };
    }

    // TODO move to service
    // this.parentFormGroup.valueChanges
    //     .pipe(
    //         map(value => ({person: value.personForm?.person, company: value.companyForm?.company})),
    //         distinctUntilChanged((previous, current) =>
    //             previous.person?.id === current.person?.id && previous.company?.id === current.company?.id)
    //     )
    //     .subscribe(({person, company}) => {
    //         if (person?.id) {
    //             this.parentFormGroup.patchValue({
    //                 mail: person.primaryMail,
    //                 name: person.fullName,
    //                 contractForm: {mail: person.primaryMail},
    //                 invoiceForm: {mail: person.primaryMail}
    //             }, {emitEvent: false});
    //         } else if (company?.id) {
    //             this.parentFormGroup.patchValue({
    //                 mail: company.primaryMail,
    //                 name: company.fullName,
    //                 contractForm: {mail: company.primaryMail},
    //                 invoiceForm: {mail: company.primaryMail}
    //             }, {emitEvent: false});
    //         }
    //     });

// TODO move to service
//     if (changes.booking) {
//
//         await waitUntil(() => !!this.parentFormGroup?.get('personOrCompany'));
//
//         if (this.booking?.id) {
//             this.parentFormGroup.get('personOrCompany').disable();
//         }
//
//         if (this.booking?.person?.id) {
//
//             this.parentFormGroup.get('personOrCompany').setValue('person');
//
//             await waitUntil(() => !!this.parentFormGroup.get('personForm')?.get('person'));
//
//             this.parentFormGroup.get('personForm').patchValue({person: this.booking.person});
//
//             this.parentFormGroup.get('personForm').get('person').disable();
//
//         } else if (this.booking?.company?.id) {
//
//             this.parentFormGroup.get('personOrCompany').setValue('company');
//
//             await waitUntil(() => !!this.parentFormGroup.get('companyForm')?.get('company'));
//
//             this.parentFormGroup.get('companyForm').patchValue({company: this.booking.company});
//
//             this.parentFormGroup.get('companyForm').get('company').disable();
//
//         }

}
