import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { PlaceholderInfoDialogComponent } from '../../../../@shared/components/placeholder-info-dialog/placeholder-info-dialog.component';
import { defaultEditorOptions } from '../../../../@core/model/editor-options';
import { IMail } from '@shared/models/mail';
import { InvoiceApiService } from '../../../../@shared/services';
import { Invoice } from '@shared/models/invoice';
import { AppNotificationService } from '@core/services/app-notification.service';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';
import { isArrayAndNotEmpty } from '@shared/functions/is-array-and-not-empty';

@Component({
    selector: 'send-email-dialog',
    templateUrl: './send-email-dialog.component.html',
})
export class SendEmailDialogComponent {

    formGroup: UntypedFormGroup;

    editorOptions = defaultEditorOptions;

    invoiceId: number;

    invoice: Invoice;

    address: string;

    mails: IMail[] = [];

    checkbox = false;

    isSending = false;

    constructor(
        public dialogRef: MatDialogRef<SendEmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { invoice: Invoice, invoiceId: number, mails: IMail[] },
        private _matDialog: MatDialog,
        private _formBuilder: UntypedFormBuilder,
        private _invoiceApiService: InvoiceApiService,
        private _appNotificationService: AppNotificationService,
        private _informationDialogService: InformationDialogService,
    ) {
        dialogRef.addPanelClass('wider-dialog');

        const invoice = data.invoice;
        this.invoiceId = data.invoiceId;
        this.invoice = invoice;
        this.address = invoice.addressView;
        this.mails = isArrayAndNotEmpty(data.mails) ? data.mails : [];

        this.formGroup = this._createForm();
    }

    openPlaceholderInfo(): void {
        this._matDialog.open(PlaceholderInfoDialogComponent);
    }

    sendMail(): void {
        this.isSending = true;
        this._invoiceApiService
            .sendMail(this.invoiceId, this.formGroup.value)
            .subscribe(async () => {
                this._informationDialogService
                    .setTitle('E-Mail gesendet')
                    .setBody(
                        `E-Mail wurde an ${this.formGroup.value.sendTo} versendet.<br><br>` +
                        'Es kann etwas dauern, bis die E-Mail den Empfänger erreicht. ' +
                        'Je nachdem, wie der Postausgangsserver konfiguriert ist, über welchen die E-Mails versendet werden.'
                    )
                    .setTheme('accent')
                    .openWithCallback(
                        () => this.dialogRef.close(true)
                    );
            })
            .add(() => this.isSending = false);
    }

    checkboxChange(event): void {
        if (event) {
            this.formGroup.get('subject').enable();
            this.formGroup.get('content').enable();
        } else {
            this.formGroup.get('subject').disable();
            this.formGroup.get('content').disable();
        }

        this.formGroup.updateValueAndValidity();
    }

    private _createForm(): UntypedFormGroup {
        const sendTo = this.mails?.find(m => m.isPrimary)?.mail || this.mails[0]?.mail;
        return this._formBuilder.group({
            sendTo: [
                sendTo,
                [U2bValidators.required('Bitte E-Mail-Adresse angeben'), U2bValidators.email()]
            ],
            subject: [{value: null, disabled: !this.checkbox}, [U2bValidators.required('Bitte Betreff angeben'), U2bValidators.maxLength(100)]],
            content: [{value: null, disabled: !this.checkbox}, [U2bValidators.required('Bitte Inhalt der E-Mail angeben')]],
        });
    }
}
