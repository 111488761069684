import { Component, forwardRef, Input, ViewChild, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, AbstractControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance, MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { hours, minutes } from '@shared/constants/date';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BaseInputComponent } from '@sharedComponents/form/base-input.component';
import { DefaultInputErrorStateMatcher } from '@sharedComponents/form/base-input.error-state-matcher';

@Component({
    selector: 'app-input-date',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateInputComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: DateInputComponent
        }
    ],
})
export class DateInputComponent extends BaseInputComponent<Date> implements OnInit {

    errorStateMatcher: DefaultInputErrorStateMatcher;

    hours = hours;

    minutes = minutes;

    placeholder: string;

    @Input() fc: AbstractControl;

    @Input() appearance: MatFormFieldAppearance = 'outline';

    @ViewChild('dateInput') dateInput: HTMLInputElement;

    ngOnInit(): void {
        super.ngOnInit();

        if (this.ngControl) {
            this.errorStateMatcher = new DefaultInputErrorStateMatcher(this.ngControl);
        }
    }

    public writeValue(inputValue: Date): void {
        super.writeValue(inputValue);
        const {nativeElement} = this.el;
        this._renderer.setProperty(nativeElement, 'value', inputValue);
    }

    public setDisabledState(isDisabled: boolean): void {
        super.setDisabledState(isDisabled);
        const {nativeElement} = this.el;
        this._renderer.setProperty(nativeElement, 'disabled', isDisabled);
    }

    inputChange(event: MatDatepickerInputEvent<unknown, unknown | null>): void {
        super.value = event.value as Date;
    }

    resetControl(): void {
        this.value = null;
    }
}
