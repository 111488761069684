import { Pipe, PipeTransform } from '@angular/core';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { InvoicePosition } from '@shared/models/invoice-position';
import { getTaxRateValue } from '@core/functions/get-tax-rate-value';

@Pipe({
    name: 'bcmGrossToNetPrice'
})
export class GrossToNetPricePipe implements PipeTransform {

    constructor(private productService: ProductService) {
    }

    transform(grossPrice: number, position: InvoicePosition): number {
        if (!grossPrice) {
            return 0;
        }
        const taxRateValue = getTaxRateValue(position);
        return this.productService.getNetPrice(grossPrice, taxRateValue);
    }
}
