import { Component } from '@angular/core';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';

@Component({
    selector: 'booking-positions-tab',
    templateUrl: './positions-tab.component.html',
    styleUrls: ['./positions-tab.component.scss']
})
export class PositionsTabComponent {

    constructor(
        public bookingDialogService: BookingDialogService,
    ) {
    }

}
