import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileUploadModel } from '@shared/components/file-upload/file-upload.model';
import { BcmFile } from '../../models/bcm-file';
import { AppNotificationService } from '@core/services/app-notification.service';

export class ExtensionArray extends Array<string> {
    toString(): string {
        return this.join(', ');
    }
}

export const DEFAULT_FILE_INPUT_ACCEPT = new ExtensionArray(
    'image/*',
    'video/*',
    'audio/*',
    '.txt',
    '.pdf',
    '.doc',
    '.docx',
    '.xml',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
);

export const EMAIL_ATTACHMENT_FILE_INPUT_ACCEPT = new ExtensionArray(
    'image/*',
    '.txt',
    '.pdf',
);

export const EXCEL_ONLY_FILE_INPUT_ACCEPT = new ExtensionArray(
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
);

/**
 * Based on:
 * https://github.com/wannadream/angular-material-file-upload
 */
@Component({
    selector: 'file-upload-v2',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadV2Component implements OnInit, OnDestroy {
    /** Link text */
    @Input() buttonText = 'Neue Datei(en)';

    /** Name used in form which will be sent in HTTP request. */
    @Input() param = 'file';

    /** Don't show Error Message and failed Files */
    @Input() fileErrorDisable = null;

    /** Target URL for file uploading. */
    @Input() uploadEndpoint = null;

    /** File extension that accepted, same as 'accept' of <input type="file" />. By the default, it's set to 'image/*'. */
    @Input() acceptedFileExtensions: ExtensionArray = DEFAULT_FILE_INPUT_ACCEPT;

    /** File extension that accepted, same as 'accept' of <input typetrue="file" />. By the default, it's set to 'image/*'. */
    @Input() multiple = false;

    /** Allow you to configure drag and drop area shown or not. */
    @Input() showDropDownArea = true;

    @Input() fullscreenDropDownArea = false;

    @Input() disableWhileUploading: boolean;

    @Input() hideAcceptedFileExtensions = false;

    @Input() maxFileSize: string;

    /** Allow you to add handler after its completion. Bubble up response text from remote. */
    @Output() uploadCompleted = new EventEmitter<BcmFile>();

    @Output() uploadStarted = new EventEmitter();

    @Output() uploadProgress = new EventEmitter<number>();

    @Output() uploadFailed = new EventEmitter<HttpErrorResponse>();

    isUploading = false;

    canDrag = true;

    hasDragHover = false;

    accept: string;

    files: Array<FileUploadModel> = [];

    bannerTest: any;
    toolbar: any;
    sidebar: any;
    fullscreenContainer: any;
    dragIndicator: any;
    listenerFnMousedown: () => void;
    listenerFnMouseup: () => void;
    listenerFnDragover: () => void;
    listenerFnDragleave: () => void;
    listenerFnDrop: () => void;
    listenerFnDragstart: () => void;
    listenerFnDragend: () => void;

    constructor(private _http: HttpClient,
                private _appNotificationService: AppNotificationService,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {

        if (this.fullscreenDropDownArea) {
            try {
                // throws an error if element not available (nearly impossible)
                this.fullscreenContainer = this.renderer.selectRootElement('body', true);
            } catch (e) {
                return;
            }

            // we need those elements to set their z-index to 1 (we'll undo it later)
            try {
                // throws an error if element not available (nearly impossible)
                this.bannerTest = this.renderer.selectRootElement('.environment-banner-test', true);
            } catch (e) {
            }
            try {
                // throws an error if element not available (nearly impossible)
                this.toolbar = this.renderer.selectRootElement('toolbar', true);
            } catch (e) {
            }
            try {
                // throws an error if element not available (nearly impossible)
                this.sidebar = this.renderer.selectRootElement('fuse-sidebar', true);
            } catch (e) {
            }

            // additional text to upload button
            this.buttonText = this.buttonText + ' oder per Drag & Drop hierher ziehen';

            this.renderer.setAttribute(this.fullscreenContainer, 'draggable', 'true');

            // we need to set draggable to false, otherwise we cannot use text selection
            this.listenerFnMousedown = this.renderer.listen(this.fullscreenContainer, 'mousedown', () => {
                this.renderer.setAttribute(this.fullscreenContainer, 'draggable', 'false');
            });

            // after releasing the mouse button, we can set the draggable to true
            this.listenerFnMouseup = this.renderer.listen(this.fullscreenContainer, 'mouseup', () => {
                this.renderer.setAttribute(this.fullscreenContainer, 'draggable', 'true');
            });

            // block dragging when selecting text
            this.listenerFnDragstart = this.renderer.listen(this.fullscreenContainer, 'dragstart', () => {
                this.canDrag = false;
            });

            // release block dragging
            this.listenerFnDragend = this.renderer.listen(this.fullscreenContainer, 'dragend', () => {
                this.canDrag = true;
            });

            this.listenerFnDragover = this.renderer.listen(this.fullscreenContainer, 'dragover', this.handleDragOverFS.bind(this));

            // see app.component.html
            this.dragIndicator = this.renderer.selectRootElement('.drag-indicator', true);

            // add listeners for dragleave and drop
            this.listenerFnDragleave = this.renderer.listen(this.dragIndicator, 'dragleave', this.handleDragLeaveFS.bind(this));
            this.listenerFnDrop = this.renderer.listen(this.dragIndicator, 'drop', this.handleDropFS.bind(this));

        }

        this.accept = this.acceptedFileExtensions?.join(',');

        this.acceptedFileExtensions = (this.acceptedFileExtensions || [])
            .map(item => {
                if (item === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    return '.xlsx';
                }
                return item;
            });
    }

    ngOnDestroy(): void {
        if (this.fullscreenContainer) {
            this.renderer.removeAttribute(this.fullscreenContainer, 'draggable');
        }

        // without this, the listeners would persist and new ones would be instantiated each time the component was invoked
        if (this.listenerFnMousedown) {
            this.listenerFnMousedown();
        }
        if (this.listenerFnMouseup) {
            this.listenerFnMouseup();
        }
        if (this.listenerFnDragover) {
            this.listenerFnDragover();
        }
        if (this.listenerFnDragleave) {
            this.listenerFnDragleave();
        }
        if (this.listenerFnDrop) {
            this.listenerFnDrop();
        }
        if (this.listenerFnDragstart) {
            this.listenerFnDragstart();
        }
        if (this.listenerFnDragend) {
            this.listenerFnDragend();
        }
    }

    handleDragOverFS(event: DragEvent): void {

        event.preventDefault();

        if (this.hasDragHover || !this.canDrag) {
            return;
        }

        if (this.bannerTest) {
            this.renderer.setStyle(this.bannerTest, 'z-index', '1');
        }
        if (this.toolbar) {
            this.renderer.setStyle(this.toolbar, 'z-index', '1');
        }
        if (this.sidebar) {
            this.renderer.setStyle(this.sidebar, 'z-index', '1');
        }

        this.hasDragHover = true;

        // show drop area
        this.renderer.setStyle(this.dragIndicator, 'display', 'block');
        this.renderer.setAttribute(this.dragIndicator, 'draggable', 'true');
    }

    handleDragLeaveFS(event: DragEvent): void {
        this.hideDragArea(event);
    }

    handleDropFS(event: DragEvent): void {
        this.hideDragArea(event);
        this.dropHandler(event);
    }

    hideDragArea(event: DragEvent): void {
        event.preventDefault();

        // show drop area
        this.renderer.removeAttribute(this.dragIndicator, 'draggable');
        this.renderer.setStyle(this.dragIndicator, 'display', 'none');
        this.hasDragHover = false;

        if (this.bannerTest) {
            this.renderer.setStyle(this.bannerTest, 'z-index', '99999');
        }
        if (this.toolbar) {
            this.renderer.setStyle(this.toolbar, 'z-index', '4');
        }
        if (this.sidebar) {
            this.renderer.setStyle(this.sidebar, 'z-index', '1000');
        }
    }

    onClick(): void {
        const fileUpload = document.getElementById(
            'fileUpload'
        ) as HTMLInputElement;
        fileUpload.onchange = () => {

            Array.prototype.forEach.call(fileUpload.files, (file) => {

                this.files.push({
                    data: file,
                    state: 'in',
                    inProgress: false,
                    progress: 0,
                    canRetry: false,
                    canCancel: true
                });
            });

            this._uploadFiles();
        };
        fileUpload.click();
    }

    cancelFile(file: FileUploadModel, event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();

        if (file) {
            if (file.sub) {
                file.sub.unsubscribe();
            }
            this._removeFileFromArray(file);
        }
    }

    retryFile(file: FileUploadModel, event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        file.progress = 0;
        file.state = 'in';
        file.canRetry = false;
        this.uploadFile(file);
    }

    public dropHandler(ev: DragEvent): void {
        this.hasDragHover = false;

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        ev.stopPropagation(); // needed to not show the warning from app component drag event catch handler

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)

            Array.prototype.forEach.call(ev.dataTransfer.items, (item) => {
                // If dropped items aren't files, reject them
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    this.files.push({
                        data: file,
                        state: 'in',
                        inProgress: false,
                        progress: 0,
                        canRetry: false,
                        canCancel: true
                    });
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            Array.prototype.forEach.call(ev.dataTransfer.files, (file) => {
                this.files.push({
                    data: file,
                    state: 'in',
                    inProgress: false,
                    progress: 0,
                    canRetry: false,
                    canCancel: true
                });
            });
        }

        this._uploadFiles();
    }

    public dragLeaveHandler(ev: DragEvent): void {
        this.hasDragHover = false;

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    }

    public dragOverHandler(ev: DragEvent): void {
        this.hasDragHover = true;

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    }

    private uploadFile(file: FileUploadModel): void {
        this.uploadStarted.emit();
        this.isUploading = true;

        const formData = new FormData();
        formData.append(this.param, file.data);

        file.inProgress = true;
        file.sub = this._http
            .post(this.uploadEndpoint, formData, {
                reportProgress: true,
                observe: 'events',
                params: new HttpParams()
                    .set('fetchAfterInsert', '1')
                    .set('originalName', file.data.name),
            })
            .pipe(
                map(event => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            file.progress = Math.round((event.loaded * 100) / event.total);
                            this.uploadProgress.emit(file.progress);
                            break;
                        case HttpEventType.Response:
                            if (file.progress < 100) {
                                this.uploadProgress.emit(100);
                            }
                            return event;
                    }
                }),
                // last(), // todo: why was this added?
                catchError((error: HttpErrorResponse) => {
                    this.uploadFailed.emit(error);
                    file.inProgress = false;
                    file.canRetry = true;

                    if (error.name) {
                        return of(new Error(error?.error?.message));
                    }

                    return of(new Error(`Upload von "${file.data.name}" fehlgeschlagen.`));
                }),
            )
            .pipe(catchError((error: HttpErrorResponse) => {
                this.uploadFailed.emit(error);

                if (error.name) {
                    return of(new Error(error?.error?.message));
                }

                return of(new Error(`Upload von "${file.data.name}" fehlgeschlagen.`));
            }))
            .subscribe((event: HttpResponse<any>) => {
                if (event instanceof Error) {
                    if (!this.fileErrorDisable) {
                        file.state = 'err';
                        this.isUploading = false;
                        this._appNotificationService.showError(event.message);
                    } else {
                        this._removeFileFromArray(file);
                    }
                } else if (event instanceof HttpResponse) {
                    this._removeFileFromArray(file);
                    this.uploadCompleted.emit(event.body);
                    this.isUploading = false;
                }
            });
    }

    private _uploadFiles(): void {
        const fileUpload = document.getElementById(
            'fileUpload'
        ) as HTMLInputElement;

        fileUpload.value = '';

        this.files.forEach(file => {
            if (!file.inProgress) {
                this.uploadFile(file);
            }
        });
    }

    private _removeFileFromArray(file: FileUploadModel): void {
        const index = this.files.indexOf(file);
        if (index > -1) {
            this.files.splice(index, 1);
        }
    }

}
