<div fxFlex.gt-xs="48" fxFlex.gt-sm="100">
    <h2>Stromzähler</h2>

    <div class="fuse-card auto-width mb-16 electric-meter-assignment-card"
         [class.accent-box-highlight]="true">

        <div class="p-8 p-sm-16 overflow-wrap-anywhere"
             fxLayout="row"
             fxLayoutAlign="space-between start">
            <div class="pr-16" style="width: 100%">

                <span class="index">Belegung #{{ electricMeterAssignment.berthAssignmentIndex + 1 }}</span>

                <div class="electric-meter-assignment-content">


                    <table fxFlex="50">

                        <tr>
                            <td>Liegeplatz</td>
                            <td>{{ electricMeterAssignment.berthAssignment.berth.handle }}</td>
                        </tr>

                        <ng-container *ngIf="electricMeterAssignment.berthAssignment.berth?.pier?.handle">
                            <tr>
                                <td>Steg</td>
                                <td>{{ electricMeterAssignment.berthAssignment.berth.pier.handle }}</td>
                            </tr>
                        </ng-container>

                        <tr>
                            <td>von</td>
                            <td>{{ electricMeterAssignment.berthAssignment.from  | date: 'dd.MM.yyyy HH:mm' }}</td>
                        </tr>

                        <tr>
                            <td>bis</td>
                            <td>{{ electricMeterAssignment.berthAssignment.to ? (electricMeterAssignment.berthAssignment.to | date: 'dd.MM.yyyy HH:mm') : 'offen' }}</td>
                        </tr>

                    </table>

                    <table fxFlex="50">

                        <tr>
                            <td>Zählerschrank</td>
                            <td>{{ electricMeterAssignment.electricMeter?.cabinet?.handle || 'n/a' }}</td>
                        </tr>

                        <tr>
                            <td>Stromzähler</td>
                            <td>{{ electricMeterAssignment.electricMeter?.handle || 'n/a' }}</td>
                        </tr>

                        <tr>
                            <td>Zählerstand (Start)</td>
                            <td>{{ (electricMeterAssignment?.meterReadingStart | number : '1.2-2') + ' kWh' }}</td>
                        </tr>

                        <tr>
                            <td>Zählerstand (Ende)</td>
                            <td>{{ electricMeterAssignment?.to ? ((electricMeterAssignment?.meterReadingEnd | number : '1.2-2') + ' kWh') : 'n/a' }}</td>
                        </tr>

                    </table>

                </div>


                <div>

                    <ng-container *ngIf="electricMeterAssignment.to; else noToDate">
                        <div class="h5 secondary-text mt-16">
                            <b>Stromzähler getrennt am {{ electricMeterAssignment.to  | date: 'dd.MM.yyyy' }}</b>
                        </div>
                    </ng-container>

                    <ng-template #noToDate>
                        <div class="h5 secondary-text mt-16">
                            <ng-container [ngSwitch]="electricMeterAssignment.paymentType">
                                <ng-container *ngSwitchCase="ElectricMeterPaymentType.None">
                                    <b>Strom wird nicht berechnet</b>
                                </ng-container>
                                <ng-container *ngSwitchCase="ElectricMeterPaymentType.Flatrate">
                                    <b>Strom wird pauschal berechnet</b>
                                </ng-container>
                                <ng-container *ngSwitchCase="ElectricMeterPaymentType.BasicCharge">
                                    <b>Strom wird nach Verbrauch berechnet sobald der Zähler getrennt wird</b>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-template>

                    <ng-container *ngIf="electricMeterAssignment.positions?.length">
                        <div class="h5 secondary-text mt-8">
                            <table>
                                <thead>
                                <tr>
                                    <th>Positionen</th>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let position of electricMeterAssignment.positions">
                                    <tr class="with-background">
                                        <td>{{ position?.title }}</td>
                                        <td style="white-space: nowrap">{{ position?.quantity | number : '1.2-2' }} {{ position.unit?.name }}</td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                </div>

            </div>

            <ng-container
                    *hasPermission="permissionNames.BOATS_WRITE">
                <ng-container
                        *ngIf="!electricMeterAssignment?.to">
                    <div>
                        <button data-test="B-Menu"
                                mat-icon-button
                                [matMenuTriggerFor]="card21Menu"
                                aria-label="more">
                            <mat-icon>more_vert</mat-icon>
                        </button>

                        <mat-menu #card21Menu="matMenu">
                            <button fxLayout="row"
                                    fxLayoutAlign="start center"
                                    mat-menu-item
                                    (click)="disconnectOrRemoveElectricMeterAssignment(electricMeterAssignment)">
                                <mat-icon color="accent">
                                    layers_clear
                                </mat-icon>
                                <span>{{ electricMeterAssignment?.id ? 'Trennen' : 'Entfernen' }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

</div>
