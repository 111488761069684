import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { BcmBookingsApiService } from '@bcmApiServices/bcm-bookings.api-service';
import { BcmFinancialRecord } from '@shared/models/bcm-financial-record';

export class BookingDialogFinancialRecords extends BookingDialogBaseEntity<BcmFinancialRecord, BcmFinancialRecord[]> {

    constructor(
        bookingDialogService: BookingDialogService,
        private _bookingsApiService: BcmBookingsApiService,
    ) {
        super(bookingDialogService, BookingAttribute.FINANCIAL_RECORDS);
    }

    reload(): void {
        this._bookingsApiService.getFinancialRecords(this.bookingDialogService.booking.id)
            .subscribe(financialRecords => {
                this.bookingDialogService.booking.financialRecords = financialRecords;
                this.value = financialRecords;
            });
    }

}
