<mat-expansion-panel class="container" [class.mat-panel-padding]="!expanded" [(expanded)]="expanded">

    <mat-expansion-panel-header [class.expanded]="expanded">
        <mat-panel-description>
            <ng-container *ngIf="!expanded">
                <div>
                    {{ travelerFormGroup.get('name')?.value }}
                    <ng-container *ngIf="travelerFormGroup.get('birthDate')?.value">
                        <br><small>({{ travelerFormGroup.get('birthDate')?.value | date: 'dd.MM.yyyy' }})</small>
                    </ng-container>
                </div>

                <div class="mat-panel-description-contact-data">
                    <ng-container *ngIf="travelerFormGroup.get('phone')?.value">
                        <div class="contact-data">
                            {{ travelerFormGroup.get('phone')?.value }}
                            <mat-icon>phone</mat-icon>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="travelerFormGroup.get('email')?.value">
                        <div class="contact-data">
                            {{ travelerFormGroup.get('email')?.value }}
                            <mat-icon>email</mat-icon>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="travelerFormGroup" fxLayout="column">

        <form-widget-person fxFlex="100"
                            (personChanged)="updateFormData($event)"
                            [personId]="travelerFormGroup.get('person')?.value?.id"
                            [slimmedView]="true" [canCreate]="false"
                            appearance="outline"></form-widget-person>

        <mat-divider fxFlex="100" class="special-divider"></mat-divider>

        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50">
            <mat-label>Name</mat-label>
            <input matInput data-test="FI-Name" type="text" formControlName="name">
            <mat-error>{{ travelerFormGroup.get('name').errors | firstErrorMessage }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50">
            <mat-label>Telefonnummer</mat-label>
            <input matInput data-test="FI-Phone" type="text" formControlName="phone">
            <mat-error>{{ travelerFormGroup.get('phone').errors | firstErrorMessage }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50">
            <mat-label>E-Mail</mat-label>
            <input matInput data-test="FI-Email" type="email" formControlName="email">
            <mat-error>{{ travelerFormGroup.get('email').errors | firstErrorMessage }}</mat-error>
        </mat-form-field>

        <app-input-date formControlName="birthDate">
            <label>Geburtsdatum</label>
        </app-input-date>

    </form>

    <mat-action-row>
        <button mat-flat-button color="warn" class="remove-btn"
                (click)="removeTraveler.emit()">
            Reisenden entfernen
        </button>
    </mat-action-row>

</mat-expansion-panel>
