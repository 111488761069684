import { BcmBookingGeneralData } from '@shared/models/bcm-booking';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

export class BookingDialogGeneral extends BookingDialogBaseEntity<BcmBookingGeneralData, BcmBookingGeneralData> {

    constructor(bookingDialogService: BookingDialogService) {
        super(bookingDialogService, BookingAttribute.GENERAL);
    }

    protected afterValueSet(): void {
        // if (this.value.personOrCompany === 'person' && this.bookingDialogService.company.value?.id) {
        //     this.bookingDialogService.company.value = null;
        //     // this.bookingDialogService.company.sendUpdate();
        // } else if (this.value.personOrCompany === 'company' && this.bookingDialogService.person.value?.id) {
        //     this.bookingDialogService.person.value = null;
        //     // this.bookingDialogService.person.sendUpdate();
        // }
    }

}
