import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { ElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

export class BookingDialogElectricMeterAssignments extends BookingDialogBaseEntity<ElectricMeterAssignment, ElectricMeterAssignment[]> {

    constructor(bookingDialogService: BookingDialogService) {
        super(bookingDialogService, BookingAttribute.ELECTRIC_METER_ASSIGNMENTS);
    }

}
