<div fxFlex="100" fxLayout="column" class="berth-assignments-tab">

    <ng-container [formGroup]="boatFormGroup">
        <form-widget-boat appearance="outline"
                          (boatChanged)="bookingDialogService.boat.value = $event"
                          [optionalFields]="true"
                          [editable]="true"
                          [canSave]="true"
                          [showMetaData]="true"
                          [general$]="bookingDialogService.general.value$"
                          [person$]="bookingDialogService.person.value$"
                          [company$]="bookingDialogService.company.value$"
                          [boat$]="bookingDialogService.boat.value$"
                          [boatRequired]="true">
        </form-widget-boat>
    </ng-container>

    <mat-divider class="special-divider"></mat-divider>

    <div [class.mb-16]="(formGroups$ | async)?.length > 0" class="mt-16">
        <mat-accordion [multi]="false">
            <ng-container
                    *ngFor="let formGroup of (formGroups$ | async); let index = index">
                <booking-berth-assignment
                        [index]="index"
                        [expanded]="(!bookingDialogService.booking?.id || !formGroup.get('id').value) && ((formGroups$ | async)?.length === 1 || index === (formGroups$ | async)?.length - 1)"
                        [berthAssignmentFormGroup]="formGroup"
                        [defaultBerthReservationTimeUnit]="defaultBerthReservationTimeUnit"
                        (removeAssignment)="removeFormGroup(index)">
                </booking-berth-assignment>
            </ng-container>
        </mat-accordion>
    </div>

    <button class="add-assignment-btn" mat-stroked-button color="accent"
            (click)="addBerthAssignment()">Belegung hinzufügen
    </button>

</div>
