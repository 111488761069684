import { Invoice } from '@shared/models/invoice';
import { format } from '@core/date.facade';
import { isString } from '@shared/functions/is-string';
import { isValidDate } from '@core/functions/is-valid-date';
import { roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { BcmDynamicCurrency, toCurrencyString } from '@modules/bcm/@shared/pipes/dynamic-currency.pipe';

export function replaceDueDatePlaceholder(input: string,
                                          invoice: Invoice): string {
    if (!input || !isString(input)) {
        return input;
    }
    const iban = invoice.person?.IBAN || invoice.company?.IBAN;
    return input
        .replace('%r%', toCurrencyString(invoice.totalGrossPrice))
        .replace('%d%', isValidDate(invoice?.dueDate) ? format(invoice.dueDate, 'dd.MM.yyyy') : '<<<Kein Fälligkeitsdatum angegeben>>')
        .replace('%iban%', iban || '<<Keine IBAN im System gefunden>>');
}
