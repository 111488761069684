export enum BookingAttribute {
    BERTH_ASSIGNMENTS = 'berthAssignments',
    BOAT = 'boat',
    COMPANY = 'company',
    DOCUMENTS = 'documents',
    ELECTRIC_METER_ASSIGNMENTS = 'electricMeterAssignments',
    GENERAL = 'general',
    FINANCIAL_RECORDS = 'financialRecords',
    PERSON = 'person',
    POSITIONS = 'positions',
    SUBSCRIPTIONS = 'subscriptions',
    TENANT_RELATION_ASSIGNMENT = 'tenantRelationAssignment',
    TRAVELERS = 'travelers',
}
