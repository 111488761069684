<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">
            <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                <mat-label>Datum</mat-label>
                <input matInput data-test="FI-Datum" formControlName="date"
                       appMaskDate placeholder="TT.MM.JJJJ"
                       [matDatepicker]="datepicker"
                       [max]="dateMax"
                       [min]="dateMin">
                <mat-datepicker-toggle data-test="FIT-Datum" matSuffix
                                       [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
                <mat-error>{{formGroup.get('date').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                aria-label="Dialog schließen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>
        <button data-test="B-Speichern" mat-flat-button
                color="accent"
                (click)="save()"
                class="save-button mat-accent"
                aria-label="Speichern">
            Weiter
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>
