import { Component } from '@angular/core';
import { ElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { AddElectricMeterDialogComponent } from '@sharedComponents/dialogs/add-electric-meter-dialog/add-electric-meter-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'booking-electric-meters-tab',
    templateUrl: './electric-meters-tab.component.html',
    styleUrls: ['./electric-meters-tab.component.scss']
})
export class ElectricMetersTabComponent {

    electricMeterAssignments$: Observable<ElectricMeterAssignment[]>;

    constructor(
        private _matDialog: MatDialog,
        public bookingDialogService: BookingDialogService,
    ) {
        this.electricMeterAssignments$ = this.bookingDialogService.electricMeterAssignments.value$
            .pipe(
                untilDestroyed(this),
                map((electricMeterAssignments) => electricMeterAssignments.filter((electricMeterAssignment) => !electricMeterAssignment.deleted))
            );
    }

    async connectElectricMeter() {

        const addElectricMeterDialogRef = this._matDialog.open(AddElectricMeterDialogComponent, {
            data: {
                disableElectricMeterWithOpenReadings: true,
                berthAssignments: this.bookingDialogService.berthAssignments.value
            },
            panelClass: 'add-electric-meter-dialog'
        });

        addElectricMeterDialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (result) => {
                if (result) {
                    const newElectricMeterAssignment = new ElectricMeterAssignment(result);

                    this.bookingDialogService.electricMeterAssignments.add(newElectricMeterAssignment);
                    this.bookingDialogService.positions.addFromElectricMeterAssignment(newElectricMeterAssignment);
                }
            });
    }

}
