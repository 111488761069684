import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { BcmBooking, IBcmBooking } from '@shared/models/bcm-booking';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmBookingsApiService } from '@bcmApiServices/bcm-bookings.api-service';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';
import { TimeSchedulerAsset } from '@sharedComponents/time-scheduler/shared/models/classes/time-scheduler-asset';
import { TimeSchedulerItem } from '@sharedComponents/time-scheduler/shared/models/classes/time-scheduler-item';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { BcmSettingsSectionName } from '@shared/models/bcm-settings';
import {
    BookingDialogAppearance,
    BookingDialogFirstChoice
} from '@modules/bcm/settings/ui-settings/ui-settings.component';
import { Boat, IBoat } from '@shared/models/boat';
import { Berth, IBerth } from '@shared/models/berth';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { v4 as uuidv4 } from 'uuid';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';
import { TranslationService } from '@core/translation/translation.service';
import { InfoDialogComponent } from '@sharedComponents/dialogs/booking-dialog/booking-dialog-info.component';

@Component({
    selector: 'booking-dialog',
    templateUrl: './booking-dialog.component.html',
    styleUrls: ['./booking-dialog.component.scss'],
    providers: [InvoicesService]
})
export class BookingDialogComponent<T> implements OnInit {

    bcmTenantPermissions = BcmTenantPermission;
    permissionNames = BcmUserPermission;

    isSaving = false;

    tabIndex = 0;

    bookingDialogAppearance: BookingDialogAppearance;
    bookingDialogFirstChoice: BookingDialogFirstChoice;

    fromToChangedTenantRelation: boolean;
    fromToChangedPositions: boolean;

    isNew = true;

    readonly BookingDialogAppearance = BookingDialogAppearance;

    constructor(public dialogRef: MatDialogRef<BookingDialogComponent<T>>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    tabIndex: number,
                    booking: BcmBooking,
                    bookingType?: BookingType,
                    useBooking?: boolean,
                    asset?: TimeSchedulerAsset<any, any>,
                    item?: TimeSchedulerItem<any, T>,
                    preselect?: { [key: string]: any },
                    itemResized?: boolean,
                    itemMoved?: boolean,
                    cutEvent?: {
                        assetA: TimeSchedulerAsset<any, T>,
                        itemA: TimeSchedulerItem<any, T>,
                        assetB: TimeSchedulerAsset<any, T>,
                        itemB: TimeSchedulerItem<any, T>,
                    },
                },
                public bookingDialogService: BookingDialogService,
                private _appNotificationService: AppNotificationService,
                private _settingsFacade: BcmSettingsFacade,
                private _matDialog: MatDialog,
                private _bookingApiService: BcmBookingsApiService) {

        bookingDialogService.reset();

        if (data?.tabIndex) {
            this.tabIndex = data.tabIndex;
        }
    }

    readonly BookingDialogFirstChoice = BookingDialogFirstChoice;

    async ngOnInit(): Promise<void> {

        this.bookingDialogAppearance = this._settingsFacade.settings()[BcmSettingsSectionName.UISettings].bookingDialogAppearance || BookingDialogAppearance.ACCORDION;
        this.bookingDialogFirstChoice = this._settingsFacade.settings()[BcmSettingsSectionName.UISettings].bookingDialogFirstChoice || BookingDialogFirstChoice.BOAT;

        if (this.data?.item && this.data?.item.metaData instanceof BerthBoatAssignment) {

            const berthBoatAssignment = this.data.item.metaData;

            this._bookingApiService.getOne(berthBoatAssignment.booking.id).subscribe((booking) => {

                this.isNew = false;

                this.bookingDialogService.setBooking(booking);

                // TODO check if this is still needed with booking-dialog-service
                // const berthBoatAssignments = [];
                //
                // let previousItem = this.data.item.previousItem;
                //
                // while (previousItem) {
                //     const previousAssignment = previousItem.metaData as unknown as BerthBoatAssignment;
                //
                //     if (previousAssignment.id) {
                //         const existingAssignment = this.booking.berthAssignments.find(a => a.id === previousAssignment.id);
                //         berthBoatAssignments.push(existingAssignment);
                //     } else {
                //         berthBoatAssignments.push(previousAssignment);
                //     }
                //
                //     previousItem = previousItem.previousItem;
                // }

                if (berthBoatAssignment.id) {

                    // TODO move to booking-dialog-service
                    // const berthAssignmentFromDB = booking.berthAssignments.find(_ => _.id === berthBoatAssignment.id);
                    //
                    // const oldBerth = berthAssignmentFromDB?.berth;
                    // const currentBerth = this.data.asset.metaData as Berth;
                    //
                    // if (oldBerth.id !== currentBerth.id) {
                    //     const hasOpenReading = !!berthAssignmentFromDB.electricMeterAssignments.find(_ => !_.meterReadingEnd);
                    //
                    //     if (hasOpenReading) {
                    //         this._informationDialogService
                    //             .setTitle('Achtung')
                    //             .setBody('Du möchtest den Liegeplatz wechseln, obwohl noch ein offener Zählerstand vorhanden ist. Bitte trenne den Stromzähler zuerst.')
                    //             .setButton({text: 'Ok', color: 'primary'})
                    //             .open();
                    //     }
                    //
                    // }

                    if (this.data?.cutEvent) {

                        this.showElectricMeterWarning();

                        this.bookingDialogService.berthAssignments.cut({
                                id: berthBoatAssignment.id,
                                berth: this.data.cutEvent.assetA.metaData as Berth,
                                from: this.data.cutEvent.itemA.startDate,
                                to: this.data.cutEvent.itemA.endDate
                            },
                            {
                                berth: this.data.cutEvent.assetB.metaData as Berth,
                                from: this.data.cutEvent.itemB.startDate,
                                to: this.data.cutEvent.itemB.endDate
                            });

                    } else {

                        const index = this.bookingDialogService.berthAssignments.value.findIndex(a => a.id === berthBoatAssignment.id);

                        if (index !== -1) {

                            const currentBerth = this.bookingDialogService.berthAssignments.value[index].berth;
                            const newBerth = this.data.asset.metaData as Berth;

                            if (currentBerth.id !== newBerth.id) {
                                this.showElectricMeterWarning();
                            }

                            this.bookingDialogService.berthAssignments.value[index].berth = this.data.asset.metaData as Berth;
                            this.bookingDialogService.berthAssignments.value[index].from = this.data.item.startDate;
                            this.bookingDialogService.berthAssignments.value[index].to = this.data.item.endDate;
                        }

                    }
                } else {
                    this.bookingDialogService.berthAssignments.add(berthBoatAssignment);
                }

                // TODO check if this is still needed with booking-dialog-service
                // let nextItem = this.data.item.nextItem;
                //
                // while (nextItem) {
                //     const nextAssignment = nextItem.metaData as unknown as BerthBoatAssignment;
                //
                //     if (nextAssignment.id) {
                //         const existingAssignment = this.booking.berthAssignments.find(a => a.id === nextAssignment.id);
                //         berthBoatAssignments.push(existingAssignment);
                //     } else {
                //         berthBoatAssignments.push(nextAssignment);
                //     }
                //
                //     nextItem = nextItem.nextItem;
                // }
                //
                // this.booking.berthAssignments = berthBoatAssignments;


                // TODO set boat in tab after form is initialized
                // waitUntil(() => !!this.formGroup.controls.boatForm).then(() => {
                //     this.formGroup.get('boatForm').get('boat').setValue(berthBoatAssignment.boat);
                // });


                this.bookingDialogService.initialized = true;
                // setTimeout(() => this.bookingDialogService.initialized = true, 500);

            });

        } else if (this.data?.booking?.id) {

            if (this.data?.useBooking) {
                await this.bookingDialogService.setBooking(this.data.booking, true);
            } else {
                this._bookingApiService.getOne(this.data.booking.id).subscribe((booking) => {
                    this.bookingDialogService.setBooking(booking, true);
                });
            }

        } else {

            const defaultBookingType = this._settingsFacade.settings()[BcmSettingsSectionName.DefaultBookingType]?.type || BookingType.Reservation;

            const booking = new BcmBooking({
                bookingType: this.data?.bookingType || defaultBookingType,
            } as unknown as IBcmBooking);

            await this.bookingDialogService.setBooking(booking);

            let berth: Berth;
            let from: Date;
            let to: Date;

            if (this.data?.preselect?.boat) {
                this.bookingDialogService.boat.value = this.data?.preselect.boat as Boat;
            }


            // comes from WorkFlowService
            if (this.data?.preselect?.berth) {
                berth = this.data?.preselect.berth as Berth;
            }

            if (this.data?.preselect?.from) {
                from = this.data?.preselect.from;
            }

            if (this.data?.preselect?.to) {
                to = this.data?.preselect.to;
            }

            // comes from TimeScheduler
            if (this.data?.asset?.metaData && this.data?.item) {
                berth = this.data?.asset?.metaData;
                from = this.data?.item?.startDate;
                to = this.data?.item?.endDate;
            }

            if (berth) {
                const berthAssignment = new BerthBoatAssignment({
                    boat: this.bookingDialogService.boat.value as unknown as IBoat,
                    electricMeterAssignments: [],
                    berth: berth as unknown as IBerth,
                    from: from?.toISOString(),
                    to: to?.toISOString(),
                    uuid: uuidv4()
                });

                this.bookingDialogService.berthAssignments.add(berthAssignment);
                this.bookingDialogService.berthAssignments.sendUpdate();
            }

            this.bookingDialogService.initialized = true;
        }

    }


    // async updatePositionsDates(): Promise<void> {
    //
    //
    // TODO move to booking-dialog-service
    // const assignmentDates = (this.formGroup.get('berthAssignments') as FormArray<UntypedFormGroup>).getRawValue() as {
    //     uuid: string,
    //     from: Date,
    //     to: Date,
    //     durationOfStay: number
    // }[];
    //
    // if (assignmentDates.length === 0) {
    //     return;
    // }
    //
    // const fromDate = assignmentDates.reduce((acc, val) => acc < val.from ? acc : val.from, assignmentDates[0].from);
    // const toDate = assignmentDates.reduce((acc, val) => acc > val.to ? acc : val.to, assignmentDates[0].to);
    //
    // const durationOfStay = assignmentDates.reduce((acc, val) => acc + val.durationOfStay, 0);
    //
    // this.fromToChangedPositions = false;
    //
    // function _updatePosition(position: InvoicePosition, _productService: ProductService, _fromDate: Date, _toDate: Date): Observable<InvoicePosition> {
    //     let quantity = position.quantity;
    //
    //     const assignmentDate = assignmentDates.find(_assignmentDate => _assignmentDate.uuid === position.berthAssignmentUuid);
    //
    //     if (position.unit.uniqueName === UnitUniqueName.DAY || position.unit.uniqueName === UnitUniqueName.OVERNIGHT_STAY) {
    //         quantity = (position.berthAssignmentUuid === assignmentDate?.uuid) ? assignmentDate.durationOfStay : durationOfStay;
    //     }
    //
    //     position.quantity = quantity;
    //
    //     position.vestingPeriodFromDate = (position.berthAssignmentUuid === assignmentDate?.uuid) ? assignmentDate.from : _fromDate;
    //     position.vestingPeriodFrom = ((position.berthAssignmentUuid === assignmentDate?.uuid) ? assignmentDate.from : _fromDate).toISOString();
    //
    //     position.vestingPeriodUntilDate = (position.berthAssignmentUuid === assignmentDate?.uuid) ? assignmentDate.to : _toDate;
    //     position.vestingPeriodUntil = ((position.berthAssignmentUuid === assignmentDate?.uuid) ? assignmentDate.to : _toDate).toISOString();
    //
    //     position.vestingPeriodText = getReadableDateRange(position.vestingPeriodFromDate, position.vestingPeriodUntilDate);
    //
    //     if (position.dynamicPrice) {
    //         const params = {
    //             boat: position.dynamicPrice.boat,
    //             berth: position.dynamicPrice.berth,
    //             tenantRelation: position.dynamicPrice.tenantRelation,
    //         };
    //
    //         return _productService.evaluatePriceRule(position.product, params, position.quantity, null, position.vestingPeriodFromDate, position.vestingPeriodUntilDate)
    //             .pipe(
    //                 map(result => {
    //
    //                     position.dynamicPrice = {...result, id: position.dynamicPrice.id};
    //
    //                     position.price = result.rulePrice;
    //                     position.title = (position.unit.uniqueName === UnitUniqueName.KWH && !!position.berthAssignmentUuid) ? position.title : result.ruleName;
    //
    //                     return position;
    //                 })
    //             );
    //     } else {
    //         return of(position);
    //     }
    // }
    //
    //
    // const formGroupPositions = this.formGroup.get('invoiceForm').get('positions').value as InvoicePosition[];
    //
    // const updatePositionsObservables = [
    //     // Update booking positions
    //     ...this.booking.positions.map((position: InvoicePosition, index: number) =>
    //         _updatePosition(position, this._productService, fromDate, toDate).pipe(
    //             tap(updatedPosition => {
    //                 this.booking.positions[index] = updatedPosition;
    //             })
    //         )
    //     ),
    //     // Update form positions
    //     ...formGroupPositions.map((position: InvoicePosition, index: number) =>
    //         _updatePosition(position, this._productService, fromDate, toDate).pipe(
    //             tap(updatedPosition => {
    //                 formGroupPositions[index] = updatedPosition;
    //             })
    //         )
    //     )
    // ];
    //
    // forkJoin(updatePositionsObservables).subscribe(() => {
    //     this.formGroup.get('invoiceForm').patchValue({
    //         positions: formGroupPositions
    //     }, {emitEvent: true});
    // });
    //
    // this.fromToChangedPositions = false;
    // }

    showElectricMeterWarning(): void {
        this._matDialog.open(InfoDialogComponent, {
            disableClose: true,
            data: {
                title: 'Achtung - Verbundener Stromzähler',
                message: '<span>Du möchtest die Liegeplatzzuweisung ändern.<br></span>' +
                    '<span>Bitte beachte, dass derzeit ein Stromzähler mit dieser Buchung verknüpft ist.</span>'
            }
        });
    }

    nextTab() {
        this.tabIndex = (this.tabIndex + 1) % 7;
        this.scrollTop();
    }

    scrollTop(): void {
        setTimeout(() => {
            const dialogContent = document.querySelector('.booking-dialog-content');
            if (dialogContent) {
                dialogContent.scrollTop = 0;
            }
        }, 1);
    }

    scrollBottom(): void {
        setTimeout(() => {
            const dialogContent = document.querySelector('.booking-dialog-content');
            if (dialogContent) {
                dialogContent.scrollTop = dialogContent.scrollHeight;
            }
        }, 1);
    }

    save(keepDialogOpen?: boolean): void {

        this.isSaving = true;

        const saveErrors = this.bookingDialogService.canSave();

        if (saveErrors.length > 0) {
            saveErrors.forEach((error) => {
                this._appNotificationService.showError(TranslationService.translate(error));
            });
            this.isSaving = false;
            return;
        }

        this.bookingDialogService.saveBooking()
            .subscribe(async (booking) => {

                this._appNotificationService.showSuccess('Die Daten wurden erfolgreich gespeichert.');

                if (keepDialogOpen) {

                    // re-open dialog with updated booking and current tab
                    this._matDialog
                        .open(BookingDialogComponent,
                            {
                                disableClose: true,
                                panelClass: ['default-dialog', 'xxl-dialog'],
                                data: {
                                    tabIndex: this.tabIndex,
                                    booking,
                                    useBooking: false // does not work atm
                                }
                            });
                }

                // close this dialog
                this.dialogRef.close(booking);

            })
            .add(() => this.isSaving = false);

    }

    updateTenantRelationDates(): void {
    }

    updatePositionsDates(): void {
    }

}
