import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { Person } from '@shared/models/person';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

export class BookingDialogPerson extends BookingDialogBaseEntity<Person, Person> {

    constructor(bookingDialogService: BookingDialogService) {
        super(bookingDialogService, BookingAttribute.PERSON);
    }

    protected afterValueSet(): void {
        if (this.value?.id) {
            this.bookingDialogService.general.value.isBoatOwner = this.value?.id === this.bookingDialogService.boat.value?.owner?.id;
            this.bookingDialogService.company.value = null;
        }
    }

}
