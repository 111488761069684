<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Treffe eine Auswahl</span>
            <button data-test="B-Dialog schließen"  mat-icon-button (click)="dialogRef.close(false)" aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <div class="mb-24" fxLayout="column" fxLayoutAlign="start start">
                <mat-radio-group class="radio-group" formControlName="type">
                    <ng-container *ngFor="let entityType of selectableEntities">
                        <mat-radio-button class="radio-button" [value]="entityType">
                            {{entityTypeNames[entityType] | translate : null : entityTypeNames[entityType]}}
                        </mat-radio-button>
                    </ng-container>
                </mat-radio-group>
            </div>

            <ng-container *ngIf="formGroup.get('type').value === EntityType.Person">
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex>
                        <input type="text" matInput formControlName="person" placeholder="Personen durchsuchen..."
                               [matAutocomplete]="personSelector">
                        <mat-error>{{formGroup.get('person').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </div>

                <mat-autocomplete #personSelector="matAutocomplete" [displayWith]="displayEntityWith(EntityType.Person)">
                    <mat-option *ngFor="let person of (filteredPersons$ | async)" [value]="person">
                        <span>{{person.toString()}}</span>
                    </mat-option>
                </mat-autocomplete>
            </ng-container>

            <ng-container *ngIf="formGroup.get('type').value === EntityType.Company">
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex>
                        <input type="text" matInput formControlName="company" placeholder="Organisationen durchsuchen..."
                               [matAutocomplete]="companySelector">
                        <mat-error>{{formGroup.get('company').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </div>

                <mat-autocomplete #companySelector="matAutocomplete" [displayWith]="displayEntityWith(EntityType.Company)">
                    <mat-option *ngFor="let company of (filteredCompanies$ | async)" [value]="company">
                        <span>{{company.name}}</span>
                    </mat-option>
                </mat-autocomplete>
            </ng-container>

            <ng-container *ngIf="formGroup.get('type').value === EntityType.Boat">
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex>
                        <input type="text" matInput formControlName="boat" placeholder="Boote durchsuchen..."
                               [matAutocomplete]="boatSelector">
                        <mat-error>{{formGroup.get('boat').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </div>

                <mat-autocomplete #boatSelector="matAutocomplete" [displayWith]="displayEntityWith(EntityType.Boat)">
                    <mat-option *ngFor="let boat of (filteredBoats$ | async)" [value]="boat">
                        <span>{{boat.toString()}}</span>
                    </mat-option>
                </mat-autocomplete>
            </ng-container>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button mat-flat-button
                (click)="save()"
                class="save-button mat-accent"
                [disabled]="isSaving || !formGroup.dirty"
                aria-label="Speichern">
            Übernehmen
        </button>

    </div>
</div>
