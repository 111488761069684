import { Component, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppNotificationService } from '@core/services/app-notification.service';
import { de } from 'date-fns/locale';
import { BcmService } from '../../../bcm.service';

@Component({
    selector: 'placeholder-info-dialog',
    templateUrl: './placeholder-info-dialog.component.html',
    styleUrls: ['./placeholder-info-dialog.component.scss'],
})
export class PlaceholderInfoDialogComponent {

    dateToday = new Date();

    // todo: get from configuration
    locale = de;

    @Input()
    selectedTabIndex = 0;

    @Input()
    selectedContractsTabIndex = 0;

    selectedContractsTabIndex2 = 0;

    selectedContractsTabIndex3 = 0;

    selectedContractsTabIndex4 = 0;

    constructor(
        public dialogRef: MatDialogRef<PlaceholderInfoDialogComponent>,
        public bcmService: BcmService,
        private _appNotificationService: AppNotificationService
    ) {
        dialogRef.addPanelClass('wider-dialog');
    }

    copied(event): void {
        if (event.isSuccess) {
            this._appNotificationService.showSuccess(`"${event.content}" wurde in die Zwischenablage kopiert.`);
        }
    }
}
