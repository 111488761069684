import { Injectable } from '@angular/core';
import { StartDirectDebitDialogComponent } from '../@shared/components/start-direct-debit-dialog/start-direct-debit-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SepaRunsApiService } from '@bcmApiServices/sepa-runs.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmNavigationService } from '../bcm-navigation.service';
import { format } from '@core/date.facade';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class AccountingService {

    constructor(private _matDialog: MatDialog,
                private informationDialogService: InformationDialogService,
                private _sepaRunsApiService: SepaRunsApiService,
                private _bcmNavigationService: BcmNavigationService,
                private _appNotificationService: AppNotificationService) {
    }

    newSepaRun(navigateAfter = true): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const dialogRef = this._matDialog.open(StartDirectDebitDialogComponent);

            const data = await dialogRef.afterClosed().toPromise();

            if (!data) {
                resolve(false);
                return;
            }

            const {date, dueDate} = data;

            this._sepaRunsApiService
                .add({
                    date: format(date, 'yyyy-MM-dd'),
                    dueDate: dueDate ? format(dueDate, 'yyyy-MM-dd') : null,
                })
                .subscribe(
                    () => {
                        this._appNotificationService.showSuccess('SEPA-Lastschrift erfolgreich erzeugt.');

                        if (navigateAfter) {
                            return this._bcmNavigationService.navigate('accounting/sepa-runs');
                        } else {
                            resolve(true);
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.informationDialogService
                            .useWarnTheme()
                            .setBody(
                                `<h4>${error.error.message}</h4>` +
                                `<ol>` +
                                (error.error.metaData
                                    ? (error.error.metaData as {
                                        debtorName: string,
                                        debtorIBAN: string,
                                        debtorBIC: string
                                    }[])
                                        .map(debtor => {
                                            return debtor.debtorIBAN
                                                ? `<li>Ungültige IBAN für <strong>${debtor.debtorName}</strong> hinterlegt:<br><strong>${debtor.debtorIBAN}</strong></li>`
                                                : `<li>Keine IBAN für <strong>${debtor.debtorName}</strong> hinterlegt.</li>`;
                                        })
                                        .join('')
                                    : '') +
                                `</ol>`
                            )
                            .open();

                        reject(false);
                    }
                );
        });
    }
}
