<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Mit Stromzähler verbinden</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <div class="input-row"
                 fxLayout="row wrap"
                 fxLayoutGap="12px grid">

                <ng-container *ngIf="data?.berthAssignments?.length">
                    <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                        <mat-label>Liegeplatz Belegung</mat-label>
                        <mat-select #matSelect data-test="FS-Zeitraum-Position"
                                    formControlName="berthAssignment">
                            <ng-container
                                    *ngFor="let berthAssignment of data.berthAssignments; let index = index">
                                <mat-option class="mat-option-berth-assignment"
                                            [value]="berthAssignment">
                                    <span>Belegung #{{ index + 1 }}</span>
                                    <ng-container *ngIf="matSelect.panelOpen">
                                        <div class="mt-8" fxLayout="row" fxFlex="100"
                                             fxLayoutAlign="space-between">
                                            <small>
                                                <table>
                                                    <tr>
                                                        <td>Liegeplatz:</td>
                                                        <td>{{ berthAssignment.berth?.handle }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Steg:</td>
                                                        <td>{{ berthAssignment.berth?.pier?.handle || 'n/a' }}</td>
                                                    </tr>
                                                </table>
                                            </small>
                                            <small class="text-right">
                                                {{ berthAssignment.from | dateTimeFormat }}<br>
                                                bis {{ berthAssignment.to ? (berthAssignment.to | dateTimeFormat) : 'offen' }}
                                            </small>
                                        </div>
                                    </ng-container>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                    <mat-label>Anschlussdatum</mat-label>
                    <input matInput data-test="FI-Anschlussdatum" formControlName="from"
                           appMaskDate placeholder="TT.MM.JJJJ"
                           [matDatepicker]="fromDatePicker">
                    <mat-datepicker-toggle data-test="FIT-Anschlussdatum" matSuffix
                                           [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                    <mat-error>{{ formGroup.get('from').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                    <input type="text" matInput data-test="FI-Zählerschrank suchen/wählen"
                           formControlName="electricMeterCabinet"
                           placeholder="Zählerschrank suchen/wählen"
                           [matAutocomplete]="electricMeterCabinetSelector">
                    <mat-error>{{ formGroup.get('electricMeterCabinet').errors | firstErrorMessage }}</mat-error>

                    <mat-autocomplete #electricMeterCabinetSelector="matAutocomplete"
                                      [displayWith]="displayElectricMeterCabinetWith">
                        <mat-option disabled class="loading" *ngIf="loadingElectricMeterCabinets">
                            <mat-spinner diameter="35"></mat-spinner>
                        </mat-option>
                        <mat-option *ngFor="let electricMeterCabinet of (filteredElectricMeterCabinets$ | async)"
                                    [value]="electricMeterCabinet">
                            <span>{{ electricMeterCabinet.handle }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <ng-container *ngIf="!loadingElectricMeters && !electricMeters?.length">
                    <div fxFlex="100">
                        <u2b-message type="warning">
                            Der ausgewählte Zählerschrank hat keine freien Stromzähler.
                        </u2b-message>
                    </div>
                </ng-container>

                <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                    <input type="text" matInput data-test="FI-Stromzähler suchen/wählen" formControlName="electricMeter"
                           placeholder="Stromzähler suchen/wählen"
                           [matAutocomplete]="electricMeterSelector">
                    <mat-error>{{ formGroup.get('electricMeter').errors | firstErrorMessage }}</mat-error>

                    <mat-autocomplete #electricMeterSelector="matAutocomplete" [displayWith]="displayElectricMeterWith">
                        <mat-option disabled class="loading" *ngIf="loadingElectricMeters">
                            <mat-spinner diameter="35"></mat-spinner>
                        </mat-option>
                        <mat-option *ngFor="let electricMeter of (filteredElectricMeters$ | async)"
                                    [disabled]="data?.disableElectricMeterWithOpenReadings && electricMeter.hasOpenReading"
                                    [value]="electricMeter">
                            <span>{{ electricMeter.handle }}</span>
                            <ng-container
                                    *ngIf="data?.disableElectricMeterWithOpenReadings && electricMeter.hasOpenReading">
                                <br><small>(Kann nicht verwendet werden, da offene Ablesung(en) vorhanden)</small>
                            </ng-container>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>


                <ng-container *ngIf="formGroup.get('electricMeter').value">
                    <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                        <mat-label>Zählerstand am Anschlussdatum</mat-label>
                        <app-input-number matInput
                                          data-test="FI-Zählerstand am Anschlussdatum"
                                          [formControl]="formGroup.get('meterReadingStart') | formControl"></app-input-number>
                        <mat-error>{{ formGroup.get('meterReadingStart').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>
                </ng-container>

                <mat-radio-group fxFlex="100" fxLayout="column" formControlName="paymentType">
                    <mat-radio-button fxFlex="100" [value]="ElectricMeterPaymentType.None">
                        Strom nicht berechnen
                    </mat-radio-button>
                    <mat-radio-button fxFlex="100" [value]="ElectricMeterPaymentType.Flatrate">
                        Strom pauschal berechnen
                    </mat-radio-button>
                    <mat-radio-button fxFlex="100" [value]="ElectricMeterPaymentType.BasicCharge">
                        Strom nach Verbrauch bei nächster Ablesung berechnen
                    </mat-radio-button>
                </mat-radio-group>

                <ng-template #product let-label>
                    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between start" class="mb-24">
                        <mat-form-field fxFlex appearance="outline" fxFlex="70">
                            <mat-label>Strompreis für {{ label }}</mat-label>
                            <input type="text" matInput data-test="FI-Strompreis - Produkt wählen"
                                   formControlName="product"
                                   placeholder="Produkte durchsuchen..."
                                   [matAutocomplete]="productSelector">
                            <mat-hint>Produkte der Warengruppe "Stromgebühr"</mat-hint>
                            <mat-error>{{ formGroup.get('product').errors | firstErrorMessage }}</mat-error>
                        </mat-form-field>

                        <mat-autocomplete #productSelector="matAutocomplete"
                                          class="product-selector"
                                          panelWidth="auto"
                                          [displayWith]="displayProductWith"
                                          (optionSelected)="onSelectProduct($event);">
                            <mat-option
                                    *ngFor="let product of (filteredElectricityProducts$ | async)"
                                    [value]="product">
                                <span>{{ product.name }}</span>
                                <small>
                                    | {{ product.price | bcmDynamicCurrency: '1.2-2' }}</small>
                                <ng-container *ngIf="product.unit?.name">
                                    <small> / {{ product.unit?.name }}</small>
                                </ng-container>
                            </mat-option>
                        </mat-autocomplete>

                        <mat-form-field fxFlex="27" appearance="outline" floatLabel="always">
                            <mat-label>Anzahl / Einheiten</mat-label>
                            <app-input-number
                                    matInput
                                    data-test="FI-Anzahl / Einheiten"
                                    formControlName="quantity"></app-input-number>
                            <mat-error>{{ formGroup.get('quantity').errors | firstErrorMessage }}</mat-error>
                        </mat-form-field>
                    </div>
                </ng-template>

                <ng-container *ngIf="formGroup.get('paymentType').value === ElectricMeterPaymentType.Flatrate">
                    <ng-container *ngTemplateOutlet="product,context:{$implicit:'Pauschale'}"></ng-container>
                </ng-container>

                <ng-container *ngIf="formGroup.get('paymentType').value === ElectricMeterPaymentType.BasicCharge">
                    <mat-checkbox formControlName="additionalProduct">
                        Zusätzliche Grundgebühr
                    </mat-checkbox>

                    <ng-container *ngIf="formGroup.get('additionalProduct').value">
                        <ng-container *ngTemplateOutlet="product,context:{$implicit:'Grundgebühr'}"></ng-container>
                    </ng-container>
                </ng-container>


            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16 p-sm-24" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                [disabled]="isSaving"
                aria-label="Dialog schließen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>

        <button data-test="B-Speichern" mat-flat-button
                (click)="save()"
                class="save-button mat-accent"
                [disabled]="isSaving || !formGroup.dirty"
                aria-label="Speichern">
            Speichern
        </button>

    </div>
</div>
