<mat-form-field [appearance]="appearance"
                class="date-field"
                floatLabel="always">
    <mat-label>
        <ng-content select="label"></ng-content>
    </mat-label>
    <input matInput
           appMaskDate
           placeholder="TT.MM.JJJJ"
           [ngModel]="value"
           [disabled]="disabled"
           [errorStateMatcher]="errorStateMatcher"
           [matDatepicker]="fromDatePicker"
           (dateInput)="inputChange($event)">
    <button type="button" matSuffix mat-icon-button matTooltip="Felder Leeren"
            [disabled]="!value || disabled"
            (click)="resetControl()">
        <mat-icon>clear</mat-icon>
    </button>
    <mat-datepicker-toggle data-test="FIT-von" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #fromDatePicker></mat-datepicker>
    <mat-hint>
        <ng-content select=".hint"></ng-content>
    </mat-hint>
    <mat-error>{{ngControl?.errors | firstErrorMessage}}</mat-error>
</mat-form-field>
