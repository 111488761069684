import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mimeDescription'
})
export class MimeDescriptionPipe implements PipeTransform {

    mimeValues = {
        'application': 'Applikation',
        'application/dicom': 'application/dicom',
        'application/epub+zip': 'application/epub+zip',
        'application/jar': 'Jar-Archiv',
        'application/javascript': 'Javascript',
        'application/mac-binhex40': 'application/mac-binhex40',
        'application/marc': 'MARC21',
        'application/msword': 'MS-Word-Datei',
        'application/octet-stream': 'Unbekannte Binärdatei',
        'application/ogg': 'OGG-Daten',
        'application/pdf': 'PDF-Datei',
        'application/pgp': 'PGP-Daten',
        'application/pgp-encrypted': 'PGP-verschlüsselte Daten',
        'application/pgp-keys': 'PGP-Schlüssel',
        'application/pgp-signature': 'PGP-Signatur',
        'application/postscript': 'Postscript-Datei',
        'application/vnd.cups-raster': 'Cups-Raster-Datei',
        'application/vnd.fdf': 'FDF-Dokument',
        'application/vnd.font-fontforge-sfd': 'Spline-Font-Database',
        'application/vnd.google-earth.kml+xml': 'OpenGIS-KML-Dokument',
        'application/vnd.google-earth.kmz': 'Compressed-Google-KML-Document',
        'application/vnd.iccprofile': 'ICC-Profil',
        'application/vnd.lotus-wordpro': 'Lotus-WordPro',
        'application/vnd.ms-cab-compressed': 'CAB-Archive',
        'application/vnd.ms-excel': 'Excel-File',
        'application/vnd.ms-fontobject': 'MS-Embedded-OpenType',
        'application/vnd.ms-msi': 'MS-Installations-Archiv',
        'application/vnd.ms-office': 'MS-Office-Datei',
        'application/vnd.ms-opentype': 'OpenType-Font',
        'application/vnd.ms-powerpoint': 'Powerpoint-Datei',
        'application/vnd.ms-tnef': 'TNEF-Archive',
        'application/vnd.oasis.opendocument.': 'OpenDocument',
        'application/vnd.oasis.opendocument.chart': 'OpenDocument Chart',
        'application/vnd.oasis.opendocument.chart-template': 'OpenDocument Chart-Template',
        'application/vnd.oasis.opendocument.database': 'OpenDocument Datenbank',
        'application/vnd.oasis.opendocument.formula': 'OpenDocument Formel',
        'application/vnd.oasis.opendocument.formula-template': 'OpenDocument Formel-Template',
        'application/vnd.oasis.opendocument.graphics': 'OpenDocument Grafik',
        'application/vnd.oasis.opendocument.graphics-template': 'OpenDocument Grafik-Template',
        'application/vnd.oasis.opendocument.image': 'OpenDocument Bild',
        'application/vnd.oasis.opendocument.image-template': 'OpenDocument Bild-Template',
        'application/vnd.oasis.opendocument.presentation': 'OpenDocument Präsentation',
        'application/vnd.oasis.opendocument.presentation-template': 'OpenDocument Präsentations-Template',
        'application/vnd.oasis.opendocument.spreadsheet': 'OpenDocument Tabelle',
        'application/vnd.oasis.opendocument.spreadsheet-template': 'OpenDocument Tabellen-Template',
        'application/vnd.oasis.opendocument.text': 'OpenDocument Text',
        'application/vnd.oasis.opendocument.text-master': 'OpenDocument Text-Master',
        'application/vnd.oasis.opendocument.text-template': 'OpenDocument Text-Template',
        'application/vnd.oasis.opendocument.text-web': 'OpenDocument Text-Web',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Microsoft Word 2007+',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'Microsoft PowerPoint 2007+',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Microsoft Excel 2007+',
        'application/vnd.rn-realmedia': 'RealMedia-Datenstrom',
        'application/vnd.symbian.install': 'application/vnd.symbian.install',
        'application/vnd.tcpdump.pcap': 'tcpdump-Capture-Datei',
        'application/warc': 'WARC-Archiv',
        'application/x-123': 'Lotus-1-2-3',
        'application/x-7z-compressed': '7z-komprimierte Datei',
        'application/x-adrift': 'application/x-adrift',
        'application/x-arc': 'ARC-Archiv',
        'application/x-archive': 'application/x-archive',
        'application/x-arj': 'ARJ-Archiv',
        'application/x-awk': 'AWK-Skript',
        'application/x-bittorrent': 'Bittorrent-Datei',
        'application/x-bzip2': 'BZip2-komprimierte Datei',
        'application/x-compress': 'Compress-komprimierte Datei',
        'application/x-coredump': 'Linux-Elf-Coredump',
        'application/x-cpio': 'CPIO-Archiv',
        'application/x-dbase': 'DBase-Datei',
        'application/x-dbf': 'DBF-Datei',
        'application/x-dbm': 'application/x-dbm',
        'application/x-debian-package': 'Debian-Paket',
        'application/x-dosexec': 'DOS-Programm',
        'application/x-dvi': 'DVI-Datei',
        'application/x-eet': 'application/x-eet',
        'application/x-elc': 'Emacs-Lisp',
        'application/x-epoc-agenda': 'Epoc-Agenda',
        'application/x-epoc-app': 'Epoc-OPL-Programm',
        'application/x-epoc-data': 'Epoc-Daten',
        'application/x-epoc-jotter': 'Epoc-Jotter-Datei',
        'application/x-epoc-opl': 'Epoc-OPL-Programm',
        'application/x-epoc-opo': 'Epoc-OPL-Programm',
        'application/x-epoc-sheet': 'Epoc-Sheet-Datei',
        'application/x-epoc-word': 'Epoc-Word-Datei',
        'application/x-executable': 'Linux-Elf-Programm',
        'application/x-freemind': 'Freemind-Dokument',
        'application/x-freeplane': 'Freeplane-Dokument',
        'application/x-font-sfn': 'X11-SNF-Font',
        'application/x-font-ttf': 'X11-TTF-Font',
        'application/x-gdbm': 'application/x-gdbm',
        'application/x-gzip': 'GZip-komprimierte Datei',
        'application/x-hdf': 'Hierarchical-Data-Format',
        'application/x-hwp': 'Hangul (Korean) Word Processor File 2000',
        'application/x-ia-arc': 'Internet-Archive-Datei',
        'application/x-ichitaro4': 'Ichitaro-Dokument-v4',
        'application/x-ichitaro5': 'Ichitaro-Dokument-v5',
        'application/x-ichitaro6': 'Ichitaro-Dokument-v6',
        'application/x-ima': 'Floppy-Image',
        'application/x-iso9660-image': 'ISO9660-CD-Image',
        'application/x-java-applet': 'application/x-java-applet',
        'application/x-java-jce-keystore': 'application/x-java-jce-keystore',
        'application/x-java-keystore': 'Java-KeyStore',
        'application/x-java-pack200': 'Java-Pack-200',
        'application/x-gnucash': 'GnuCash-Datei',
        'application/x-gnumeric': 'Gnumeric-Spreadsheet',
        'application/x-gnupg-keyring': 'GnuPG-Keyring',
        'application/x-kdelnk': 'KDE-Link',
        'application/x-lha': 'LHA-Archiv',
        'application/x-lharc': 'LHArc-Archiv',
        'application/x-lrzip': 'LRZIP-komprimierte Datei',
        'application/x-lzma': 'LZMA-komprimierte Datei',
        'application/x-mdx': 'MDX-Datei',
        'application/x-mif': 'FrameMaker-Datei',
        'application/x-msaccess': 'MS-Access-Datei',
        'application/x-ms-reader': 'Microsoft-Reader-eBook-Data',
        'application/x-object': 'Linux-Elf-Objekt-Datei',
        'application/x-pgp-keyring': 'PGP-Keyring',
        'application/x-pnf': 'Windows Precompiled iNF',
        'application/x-quark-xpress-3': 'Quark-Xpress-Datei',
        'application/x-quicktime-player': 'Quicktime-Player',
        'application/x-rar': 'RAR-Archiv',
        'application/x-rpm': 'RPM-Paket',
        'application/x-sc': 'SC-Spreadsheet',
        'application/x-scribus': 'Scribus-Dokument',
        'application/x-setupscript': 'Microsoft-Windows-Autorun-Skript',
        'application/x-sharedlib': 'Linux-Elf-Bibliotheks-Datei',
        'application/x-shockwave-flash': 'Shockwave-Flash',
        'application/x-stuffit': 'StuffIt-Archiv',
        'application/x-svr4-package': 'pkg-Datenstrom (SVR4)',
        'application/x-tar': 'TAR-Archiv',
        'application/x-tex-tfm': 'TeX-Schrift',
        'application/x-tokyocabinet-btree': 'Tokyocabinet-Btree',
        'application/x-tokyocabinet-fixed': 'Tokyocabinet-Fixed',
        'application/x-tokyocabinet-hash': 'Tokyocabinet-Hash',
        'application/x-tokyocabinet-table': 'Tokyocabinet-Table',
        'application/x-wine-extension-ini': 'Windows-INI',
        'application/x-xz': 'XZ-komprimierte Datei',
        'application/x-zoo': 'ZOO-Archiv',
        'application/xml': 'XML-Datei',
        'application/xml-sitemap': 'XML-Sitemap',
        'application/zip': 'ZIP-Archiv',
        'audio': 'Audio',
        'audio/basic': 'Basic-/mu-law-/PCM-Audio',
        'audio/midi': 'Midi-Audio-Datei',
        'audio/mp4': 'MP4-Audio-Datei',
        'audio/mpeg': 'MPEG-ADTS-Datei,MP3',
        'audio/vnd.dolby.dd-raw': 'ATSC A/52,AC-3,Dolby Digital stream',
        'audio/x-adpcm': 'ISDN mu-law compressed audio',
        'audio/x-aiff': 'AIFF-Audio',
        'audio/x-ape': 'Monkey\'s Audio compressed format',
        'audio/x-dec-basic': 'Basic-/mu-law-/PCM-Audio',
        'audio/x-flac': 'Flac-Datei',
        'audio/x-hx-aac-adif': 'MPEG-ADIF,AAC',
        'audio/x-hx-aac-adts': 'MPEG-ADTS,AAC',
        'audio/x-mod': 'audio/x-mod',
        'audio/x-mp4a-latm': 'MPEG-4 LOAS',
        'audio/x-musepack': 'Musepack-Audio',
        'audio/x-pn-realaudio': 'RealAudio',
        'audio/x-w64': 'Wave-64-Audio',
        'audio/x-wav': 'WAV-Audio-Datei',
        'audio/x-unknown': 'Unbekannte-Audio-Datei',
        'chemical': 'Chemical',
        'chemical/x-pdb': 'Protein Data Bank data',
        'image': 'Bild',
        'image/gif': 'GIF-Bild',
        'image/jp2': 'JP2-Bild',
        'image/jpeg': 'JPEG-Bild',
        'image/jpm': 'JPM-Bild',
        'image/jpx': 'JPK-Bild',
        'image/pcx': 'PCX-Bild',
        'image/png': 'PNG-Bild',
        'image/svg+xml': 'SVG+XML-Grafik',
        'image/tiff': 'TIFF-Bild',
        'image/vnd.adobe.photoshop': 'Photoshop-Grafik',
        'image/vnd.djvu': 'Dejavu-Grafik',
        'image/vnd.dwg': 'DWG AutoDesk AutoCAD',
        'image/x-award-bioslogo': 'Award-BIOS-Logo',
        'image/x-award-bmp': 'Award Bitmap',
        'image/x-canon-cr2': 'Canon-CR2-raw-image',
        'image/x-canon-crw': 'Canon-CIFF-raw-image',
        'image/x-coreldraw': 'CorelDraw-Datei',
        'image/x-cpi': 'CPI-Grafik',
        'image/x-dpx': 'DPX-Grafik',
        'image/x-epoc-mbm': 'Epoc-MBM-Grafik',
        'image/x-epoc-sketch': 'Epoc-Sketch-Grafik',
        'image/x-exr': 'EXR-Grafik',
        'image/x-icon': 'MS-Windows-Icon',
        'image/x-lss16': 'SYSLINUX-LSS16-Image',
        'image/x-ms-bmp': 'MS-Bitmap-Grafik',
        'image/x-niff': 'image/x-niff',
        'image/x-olympus-orf': 'Olympus-ORF-raw-image',
        'image/x-paintnet': 'Paint.NET-Grafik',
        'image/x-pcx': 'PCX-Datei',
        'image/x-portable-bitmap': 'image/x-portable-bitmap',
        'image/x-portable-greymap': 'image/x-portable-greymap',
        'image/x-portable-pixmap': 'image/x-portable-pixmap',
        'image/x-quicktime': 'Quicktime-Grafik',
        'image/x-unknown': 'unbekannte Grafik',
        'image/x-x3f': 'Foveon-X3F-raw-image',
        'image/x-xcf': 'GIMP-XCF-Grafik',
        'image/x-xcursor': 'Xcursor-Daten',
        'image/x-xpmi': 'XPM-Bild',
        'image/x-xwindowdump': 'XWD-X-Window-Dump-image',
        'message': 'Nachricht',
        'message/news': 'News-Nachricht',
        'message/rfc822': 'rfc822-Nachricht',
        'model': 'Modell',
        'model/vrml': 'VRML-Datei',
        'model/x3d': 'X3D-Datei',
        'rinex': 'RINEX-Datei',
        'rinex/broadcast': 'RINEX-Daten: GEO SBAS Broadcast',
        'rinex/clock': 'RINEX-Daten: Clock',
        'rinex/meteorological': 'RINEX-Daten: Meteorologisch',
        'rinex/navigation': 'RINEX-Daten: Navigation',
        'rinex/observation': 'RINEX-Daten: Observation',
        'text': 'Text',
        'text/csv': 'CSV-Dateien',
        'text/calendar': 'vCalendar-Datei',
        'text/html': 'HTML-Daten',
        'text/inf': 'Windows-Setup-Informationen',
        'text/PGP': 'GPG-verschlüsselte Daten',
        'text/plain': 'Einfacher Text',
        'text/rtf': 'RTF-Text',
        'text/texmacs': 'TeXmacs-Dokument',
        'text/troff': 'Troff-Dokument',
        'text/vnd.graphviz': 'Graphviz-Grafik-Text',
        'text/x-asm': 'Assembler-Quelltext',
        'text/x-awk': 'AWK-Skript',
        'text/x-bcpl': 'BCPL-Quelltext',
        'text/x-c': 'C-Quelltext',
        'text/x-c++': 'C++-Quelltext',
        'text/x-diff': 'Diff-Ausgabe',
        'text/x-fortran': 'Fortran-Quelltext',
        'text/x-gawk': 'GNU-AWK-Skript',
        'text/x-info': 'text/x-info',
        'text/x-inform': 'text/x-inform',
        'text/x-java': 'Java-Quelltext',
        'text/x-lisp': 'Lisp-/Scheme-Quelltext',
        'text/x-lua': 'Lua-Skript',
        'text/x-m4': 'M4-Makro',
        'text/x-makefile': 'Makefile-Skript',
        'text/x-msdos-batch': 'DOS-Batch-Datei',
        'text/x-nawk': 'New-AWK-Skript',
        'text/x-pascal': 'Pascal-Quelltext',
        'text/x-perl': 'Perl-Skript',
        'text/x-php': 'PHP-Skript',
        'text/x-po': 'GNU-Gettext-Nachrichten-Katalog',
        'text/x-python': 'Python-Skript',
        'text/x-ruby': 'Ruby-Skript',
        'text/x-shellscript': 'Shell-Skript',
        'text/x-tcl': 'Tcl/Tk-Skript',
        'text/x-tex': 'TeX-Quelltext',
        'text/x-texinfo': 'text/x-texinfo',
        'text/x-vcard': 'VCard',
        'text/x-xmcd': 'XMCD-Datei',
        'text/xml': 'XML-Datei',
        'video': 'Video',
        'video/3gpp': 'MPEG4-3GPP',
        'video/3gpp2': 'MPEG4-3GPP2',
        'video/h264': 'H264-Video',
        'video/mj2': 'JPEG-MJ2-Video',
        'video/mp2p': 'MP2P-Video',
        'video/mp2t': 'MP2T-Video',
        'video/mp4': 'MPEG4-Video',
        'video/mp4v-es': 'video/mp4v-es',
        'video/mpeg': 'MPEG-Video',
        'video/mpeg4-generic': 'MPEG4-Generisch',
        'video/mpv': 'video/mpv',
        'video/quicktime': 'Quicktime-Video',
        'video/sgi': 'SGI-Video',
        'video/unknown': 'unbekanntes Video',
        'video/vnd.rn-realvideo': 'Real-Video',
        'video/webm': 'WebM-Video',
        'video/x-flc': 'video/x-flc',
        'video/x-fli': 'video/x-fli',
        'video/x-flv': 'video/x-flv',
        'video/x-jng': 'video/x-jng',
        'video/x-matroska': 'Matroska-Video',
        'video/x-mng': 'MNG-Video',
        'video/x-ms-asf': 'Microsoft-ASF-Video',
        'video/x-msvideo': 'video/x-msvideo',
        'video/x-sgi-movie': 'video/x-sgi-movie',
        'video/x-unknown': 'Unbekanntes Video-Format',
        'x-epoc/x-sisx-app': 'Symbian-Installationsdatei'
    };

    transform(value: string): any {
        if (!value) {
            return value;
        }
        return this.mimeValues[value] || (this.mimeValues[value.split('/')[0]] || value);
    }
}
