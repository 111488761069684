<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Lastschrift(en) erzeugen</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <p>
                Für die Lastschrift wird folgende Bankverbindung verwendet:
            </p>

            <p>
                <strong>{{tenant.primaryBankAccount?.bankName}}</strong><br>
                <strong>IBAN:</strong> {{tenant.primaryBankAccount?.iban}}<br>
                <ng-container>
                    <strong>BIC:</strong> {{tenant.primaryBankAccount?.bic}}
                </ng-container>
            </p>

            <u2b-message [collapsable]="true">
                <header>Info zum <strong>Einzugsdatum</strong>:</header>
                <p>
                    Bitte wähle das Einzugsdatum, zu wann die Bank den Einzug tätigen soll. Dies sollte 5 Tage oder mehr
                    in der Zukunft liegen, damit die Bank eine vernünftige Vorlaufzeit hat.
                </p>
            </u2b-message>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex="100%" appearance="outline">
                    <mat-label>Einzugsdatum</mat-label>
                    <input matInput
                           data-test="FI-Einzugsdatum"
                           formControlName="date"
                           appMaskDate placeholder="TT.MM.JJJJ"
                           [matDatepicker]="datePicker"
                           [min]="minDate">
                    <mat-datepicker-toggle data-test="FIT-Einzugsdatum" matSuffix
                                           [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>

                    <mat-error>
                        <ng-container *ngIf="formGroup.get('date').hasError('matDatepickerMin');">
                            Das Datum darf nicht in der Vergangenheit liegen
                        </ng-container>
                        <ng-container *ngIf="formGroup.get('date').hasError('matDatepickerParse');">
                            Bitte gib ein korrektes Datumsformat ein. TT.MM.JJJJ
                        </ng-container>
                        {{formGroup.get('date').errors | firstErrorMessage}}
                    </mat-error>

                </mat-form-field>
            </div>

            <u2b-message [collapsable]="true">
                <header>Info zu <strong>Fälligkeit bis einschließlich (optional)</strong>:</header>
                <p>
                    Diese Angabe ist optional.
                </p>
                <p>
                    Wenn angegeben, werden nur offene {{'invoices' | translate}} berücksichtigt, deren Fälligkeitsdatum vor oder gleich
                    dem ausgewählten Datum sind. Andernfalls alle offenen {{'invoices' | translate}}.
                </p>
            </u2b-message>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex="100%" appearance="outline">
                    <mat-label>Fälligkeit bis (optional)</mat-label>
                    <input matInput
                           formControlName="dueDate"
                           appMaskDate placeholder="TT.MM.JJJJ"
                           [matDatepicker]="datePickerDueDate"
                           [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="datePickerDueDate"></mat-datepicker-toggle>
                    <mat-datepicker #datePickerDueDate></mat-datepicker>

                    <mat-error>
                        <ng-container *ngIf="formGroup.get('dueDate').hasError('matDatepickerMin');">
                            Das Datum darf nicht in der Vergangenheit liegen
                        </ng-container>
                        <ng-container *ngIf="formGroup.get('dueDate').hasError('matDatepickerParse');">
                            Bitte gib ein korrektes Datumsformat ein. TT.MM.JJJJ
                        </ng-container>
                        {{formGroup.get('dueDate').errors | firstErrorMessage}}
                    </mat-error>

                </mat-form-field>
            </div>

            <u2b-message type="warning">
                <header>Wichtig!</header>
                <p>
                    Nur offene {{'invoices' | translate}}(*), die die Zahlungsart "SEPA-Lastschrift" haben, werden für den
                    Zahlungslauf berücksichtigt.
                </p>
                <p>
                    (*) Wenn <strong>Fälligkeit bis (optional)</strong> angegeben, werden nur offenen {{'invoices' | translate}}
                    berücksichtigt, deren Fälligkeitsdatum vor oder gleich dem ausgewählten Datum ist.
                </p>
            </u2b-message>

            <p>SEPA-Lastschrift(en) jetzt erzeugen?</p>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                class="save-button mat-primary"
                aria-label="Abbrechen">
            <mat-icon>cancel</mat-icon>
            Nein, jetzt nicht
        </button>

        <button data-test="B-Speichern" mat-flat-button
                color="accent"
                (click)="dialogRef.close(formGroup.value)"
                class="save-button"
                [disabled]="!formGroup.dirty || formGroup.invalid"
                aria-label="Speichern">
            <mat-icon>save</mat-icon>
            Ja, los geht's!
        </button>

    </div>
</div>
