import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { U2bDateValidators } from '@shared/validators/date/date-validators';
import { ElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { Observable } from 'rxjs';
import { IProduct, Product } from '@shared/models/product';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { U2bProductValidators } from '@shared/validators/product/product-validators';
import { Router } from '@angular/router';
import { ProductsApiService } from '@modules/bcm/@shared/services';
import { BcmService } from '@modules/bcm/bcm.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { isString } from '@shared/functions/is-string';
import { BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { ElectricMeterPaymentType } from '@modules/bcm/@shared/enums/electric-meter-payment-type';

@Component({
    selector: 'remove-electric-meter-dialog',
    templateUrl: './remove-electric-meter-dialog.component.html',
    styleUrls: ['./remove-electric-meter-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RemoveElectricMeterDialogComponent {
    dialogTitle: string;
    formGroup: UntypedFormGroup;

    ElectricMeterPaymentType = ElectricMeterPaymentType;

    meterReadingDateMax = new Date();

    products: Product[];
    loadingProducts: boolean;
    filteredProducts$: Observable<Product[]>;

    electricMeterAssignment: ElectricMeterAssignment;

    showNoProductHint = false;
    isSaving = false;

    meterReadingStart: number;

    showPaymentType = false;

    constructor(
        public dialogRef: MatDialogRef<RemoveElectricMeterDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: {
            dialogTitle?: string,
            showPaymentType?: boolean,
            electricMeterAssignment: ElectricMeterAssignment
        },
        private _formBuilder: UntypedFormBuilder,
        private _appNotificationService: AppNotificationService,
        private _productsApiService: ProductsApiService,
        private _router: Router,
        private _bcmService: BcmService,
        private _bcmSettingsFacade: BcmSettingsFacade,
    ) {
        this.dialogTitle = data?.dialogTitle || 'Verbindung zum Stromzähler entfernen';
        this.electricMeterAssignment = data.electricMeterAssignment;
        this.formGroup = this._createForm(data.electricMeterAssignment);

        this.showPaymentType = data?.showPaymentType;

        this._loadProducts();
    }

    public save(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;
        this.dialogRef.close({...this.formGroup.value, meterReadingStart: this.meterReadingStart});
    }

    public displayProductWith(product: IProduct): string {
        return product ? product.name : '';
    }

    public addNewProduct(): void {
        this.dialogRef.close();
        this._router.navigate([this._bcmService.baseUrl, 'products', 'new']);
    }

    private _createForm(electricMeterAssignment: ElectricMeterAssignment): UntypedFormGroup {
        const reading = electricMeterAssignment.reading
            || electricMeterAssignment.readings?.filter(r => r.endDate == null && r.endMeterReading == null)[0];
        this.meterReadingStart = reading?.startMeterReading;

        const form = this._formBuilder.group({
            to: [
                new Date(),
                [
                    U2bValidators.required('Bitte gib ein korrektes Datumsformat ein. TT.MM.JJJJ'),
                    U2bDateValidators.minDate(tryParseDate(reading?.startDate, reading?.startDate) || electricMeterAssignment.from),
                    U2bDateValidators.maxDate(new Date())
                ]
            ],
            meterReadingEnd: [
                this.meterReadingStart,
                [
                    U2bValidators.required('Bitte gib den Zählerstand ein'),
                    U2bNumericValidators.numberFormat(),
                    ...(
                        this.meterReadingStart
                            ? [U2bNumericValidators.numberMin(this.meterReadingStart, `Der Wert darf nicht kleiner als der letzte Zählerstand (${this.meterReadingStart}) sein.`)]
                            : []
                    ),
                ]
            ],
            product: [
                {
                    value: null,
                    disabled: this.electricMeterAssignment.paymentType !== ElectricMeterPaymentType.BasicCharge
                },
                [
                    U2bValidators.required('Bitte Produkt aus der Liste auswählen'),
                    U2bProductValidators.productExists(),
                ]
            ]
        });


        this.filteredProducts$ = form.get('product').valueChanges
            .pipe(
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterProducts(value) : this.products)
            );

        return form;
    }

    private _filterProducts(filter: string): Product[] {
        return this.products.filter((product: Product) => product.name.toLowerCase().includes(filter.toLowerCase()));
    }

    private _loadProducts(): void {
        this.products = [];
        this.loadingProducts = true;
        this._bcmSettingsFacade
            .settings$
            .pipe(map(t => t[BcmSettingsSectionName.ElectricMeter]))
            .pipe(
                switchMap(({productID}) => {

                    if (productID) {
                        return this._productsApiService.getOne(productID).pipe(map(p => [p]));
                    }

                    return this._productsApiService.getAll()
                        .pipe(
                            // todo: filter in backend and use HttpParams() in getAll()...
                            map((products: Product[]) => {
                                const possibleProducts = products.filter((product: Product) => {
                                    return product.category && product.category?.uniqueId === 'Stromgebühr'
                                        || product.unit && product.unit.name === 'kWh';
                                });

                                if (!possibleProducts.length && this.electricMeterAssignment.paymentType !== ElectricMeterPaymentType.BasicCharge) {
                                    this.showNoProductHint = true;
                                }

                                return possibleProducts;
                            })
                        );
                })
            )
            .subscribe((products) => {
                this.products = products;
                this.loadingProducts = false;
                if (products?.length === 1) {
                    this.formGroup.get('product').setValue(products[0]);
                }
            });
    }
}
