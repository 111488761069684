import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Person } from '@shared/models/person';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { U2bAddressValidators } from '@shared/validators/address/address-validators';
import { U2bValidators } from '@shared/validators/validators';
import { CompanyApiService, PersonApiService } from '../../../../@shared/services';
import { U2bCompanyValidators } from '@shared/validators/company/company-validators';
import { Company } from '@shared/models/company';
import { AppNotificationService } from '@core/services/app-notification.service';
import { isString } from '@shared/functions/is-string';
import { DEFAULT_DEBOUNCE_TIME } from '../../../../@shared/constants';

enum InvoiceAddressType {
    Person,
    Company
}

@Component({
    selector: 'get-invoice-address-dialog',
    templateUrl: './get-invoice-address-dialog.component.html',
    styleUrls: ['./get-invoice-address-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GetInvoiceAddressDialogComponent {
    dialogTitle: string;
    formGroup: UntypedFormGroup;
    person: Person;
    persons$: Observable<Person[]>;
    filteredPersons$: Observable<Person[]>;
    company: Company;
    companies$: Observable<Company[]>;
    filteredCompanies$: Observable<Company[]>;
    invoiceAddressType = InvoiceAddressType;
    isSaving: boolean;

    constructor(
        public dialogRef: MatDialogRef<GetInvoiceAddressDialogComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _personApiService: PersonApiService,
        private _companyApiService: CompanyApiService,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogTitle = 'Adresse aus Stammdaten wählen';
        this.formGroup = this._createForm();

        this.filteredPersons$ = this.formGroup.get('person').valueChanges
            .pipe(
                startWith(this.persons$),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((value) => isString(value) ? this._filterPersons({name: value}) : this.persons$)
            );

        this.filteredCompanies$ = this.formGroup.get('company').valueChanges
            .pipe(
                startWith(this.companies$),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((value) => isString(value) ? this._filterCompanies({name: value}) : this.companies$)
            );

        this.formGroup.get('type').valueChanges.subscribe((value) => {
            switch (value) {
                case InvoiceAddressType.Person:
                    this.formGroup.get('person').enable();
                    this.formGroup.get('company').disable();
                    break;
                case InvoiceAddressType.Company:
                    this.formGroup.get('company').enable();
                    this.formGroup.get('person').disable();
                    break;
            }
        });

        this._loadPersons();
        this._loadCompanies();
    }

    save(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;
        switch (this.formGroup.get('type').value) {
            case InvoiceAddressType.Person:
                const person = this.formGroup.get('person').value;
                this.dialogRef.close({person});
                break;
            case InvoiceAddressType.Company:
                const company = this.formGroup.get('company').value;
                this.dialogRef.close({company});
                break;
        }
    }

    public displayPersonWith(person: Person): string {
        return person ? person.toString() : '';
    }

    private _filterPersons(filter: { name: string } = {name: ''}): Observable<Person[]> {
        return this.persons$
            .pipe(
                map((persons: Person[]) => persons.filter(person => (person.firstName + person.lastName).toLowerCase().includes(filter.name.toLowerCase())))
            );
    }

    public displayCompanyWith(company: Company): string {
        return company ? company.name : '';
    }

    private _filterCompanies(filter: { name: string } = {name: ''}): Observable<Company[]> {
        return this.companies$
            .pipe(
                map((companies: Company[]) => companies.filter(company => (company.name).toLowerCase().includes(filter.name.toLowerCase())))
            );
    }

    private _createForm(): UntypedFormGroup {
        return this._formBuilder.group({
            type: [InvoiceAddressType.Person],
            person: [
                '',
                [
                    U2bValidators.required('Bitte Person auswählen'),
                    U2bAddressValidators.addressExists()
                ]
            ],
            company: [
                {value: '', disabled: true},
                [
                    U2bValidators.required('Bitte Organisation auswählen'),
                    U2bCompanyValidators.companyExists()
                ]
            ]
        });
    }

    private _loadPersons(): void {
        this.persons$ = this._personApiService.getAll();
    }

    private _loadCompanies(): void {
        this.companies$ = this._companyApiService.getAll();
    }
}
