import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ElectricMeter, IElectricMeter } from '@shared/models/electric-meter';
import { U2bValidators } from '@shared/validators/validators';
import { U2bElectricMeterValidators } from '@shared/validators/electric-meter/electric-meter-validators';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { Router } from '@angular/router';
import { U2bDateValidators } from '@shared/validators/date/date-validators';
import {
    ElectricMeterApiService,
    ElectricMeterCabinetApiService,
    ProductsApiService
} from '@modules/bcm/@shared/services';
import { BcmService } from '@modules/bcm/bcm.service';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ElectricMeterCabinet } from '@shared/models/electric-meter-cabinet';
import { HttpParams } from '@angular/common/http';
import { BcmApiService } from '@bcmApiServices/bcm-api.service';
import { format } from '@core/date.facade';
import { AppNotificationService } from '@core/services/app-notification.service';
import { isString } from '@shared/functions/is-string';
import { InformationDialogService } from '../information-dialog/information-dialog.service';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isValid, startOfDay } from 'date-fns';
import { IProduct, Product } from '@shared/models/product';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { ElectricMeterPaymentType } from '@modules/bcm/@shared/enums/electric-meter-payment-type';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'add-assignment-dialog',
    templateUrl: './add-electric-meter-dialog.component.html',
    styleUrls: ['./add-electric-meter-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddElectricMeterDialogComponent {
    formGroup: UntypedFormGroup;

    ElectricMeterPaymentType = ElectricMeterPaymentType;

    electricMeterCabinets: ElectricMeterCabinet[];
    loadingElectricMeterCabinets: boolean;
    filteredElectricMeterCabinets$: Observable<ElectricMeterCabinet[]>;

    electricMeters: ElectricMeter[];
    loadingElectricMeters: boolean;
    filteredElectricMeters$: Observable<ElectricMeter[]>;

    loadingProducts: boolean;
    price: number;
    totalPrice: number;
    electricityProducts: Product[];
    filteredElectricityProducts$!: Observable<Product[]>;
    categories: string[];

    isSaving: boolean;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            disableElectricMeterWithOpenReadings: boolean,
            berthAssignments: BerthBoatAssignment[]
        },
        public dialogRef: MatDialogRef<AddElectricMeterDialogComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _electricMeterCabinetApiService: ElectricMeterCabinetApiService,
        private _electricMeterApiService: ElectricMeterApiService,
        private _productsApiService: ProductsApiService,
        private _appNotificationService: AppNotificationService,
        private _router: Router,
        private _bcmService: BcmService,
        private _bcmNavigationService: BcmNavigationService,
        private _bcmApiService: BcmApiService,
        private _informationDialogService: InformationDialogService,
    ) {
        this.formGroup = this._createForm();

        this._loadElectricMeterCabinets();
        this._loadElectricMeters();
        this._loadElectricityProducts();

        const electricMeterCabinetField = this.formGroup.get('electricMeterCabinet');
        const electricMeterField = this.formGroup.get('electricMeter');
        const meterReadingStartField = this.formGroup.get('meterReadingStart');
        const paymentTypeField = this.formGroup.get('paymentType');
        const fromField = this.formGroup.get('from');

        electricMeterCabinetField
            .valueChanges
            .subscribe((electricMeterCabinet) => {
                if (electricMeterCabinet) {
                    this._loadElectricMeters(
                        electricMeterCabinetField.value,
                        fromField.value,
                    );
                    electricMeterField.enable();
                    electricMeterField.reset(null);
                    meterReadingStartField.reset(null);
                } else {
                    electricMeterField.disable();
                }
            });

        fromField.valueChanges
            .subscribe(() => {
                this._loadElectricMeters(
                    electricMeterCabinetField.value,
                    fromField.value
                );
            });

        electricMeterField
            .valueChanges
            .subscribe((electricMeter) => {
                const readings = (electricMeter?.readings || []);
                const hasOpenReadings = readings.find(reading => reading.startMeterReading != null && reading.endMeterReading == null);
                const maxValue = readings.length ? Math.max(...readings.map(reading => {
                    return Math.max(reading.startMeterReading, reading.endMeterReading);
                })) : 0;

                meterReadingStartField.setValidators([
                    U2bValidators.required('Bitte Zählerstand angeben'),
                    U2bNumericValidators.numberFormat(),
                    U2bNumericValidators.numberMin(maxValue, `Der Wert darf nicht kleiner als der letzte Zählerstand (${maxValue}) sein.`)
                ]);
                meterReadingStartField.updateValueAndValidity();
                meterReadingStartField.setValue(maxValue);

                if (hasOpenReadings) {
                    this._informationDialogService
                        .setTheme('warn')
                        .setTitle('Achtung')
                        .setLeftButton({
                            text: 'Ablesungen anzeigen',
                            onClick: () => {
                                this._bcmNavigationService
                                    .navigate(['electric-meters', electricMeter.id, 'readings'])
                                    .catch(() => this._appNotificationService.showError('Navigation ist aktuell nicht möglich.'));
                                this.dialogRef.close();
                            }
                        })
                        .setBody(
                            'Es wurden offene Ablesungen gefunden.<br><br>' +
                            'Eine lückenlose Ablesehistorie ist sehr wichtig!<br><br>' +
                            'Bitte ergänze die offene(n) Ablesung(en) um den Endzählerstand bevor Du den ausgewählten Stromzähler verwendest'
                        )
                        .open();
                }
            });

        paymentTypeField.valueChanges
            .subscribe((value: ElectricMeterPaymentType) => {
                this.formGroup.get('product').reset();
                this.formGroup.get('quantity').reset();

                if (value === ElectricMeterPaymentType.Flatrate) {
                    this.formGroup.get('product').setValidators(U2bValidators.required('Bitte Produkt wählen'));
                    this.formGroup.get('quantity').setValidators(U2bValidators.required('Bitte Menge angeben'));
                } else if (value === ElectricMeterPaymentType.BasicCharge) {
                    this.formGroup.get('product').setValidators(null);
                    this.formGroup.get('quantity').setValidators(null);
                }
            });
    }

    save(): void {
        this.isSaving = true;

        if (this.formGroup.invalid) {
            this.isSaving = false;
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.dialogRef.close(this.formGroup.value);
    }

    public displayElectricMeterCabinetWith(electricMeterCabinet: ElectricMeterCabinet): string {
        return electricMeterCabinet ? electricMeterCabinet.handle : '';
    }

    public displayElectricMeterWith(electricMeter: IElectricMeter): string {
        return electricMeter ? electricMeter.handle : '';
    }

    public displayProductWith(product: IProduct): string {
        return product ? product.name : '';
    }

    private _createForm(): UntypedFormGroup {
        const form = this._formBuilder.group(
            {
                electricMeterCabinet: [
                    null,
                    [
                        U2bValidators.required('Bitte Zählerschrank auswählen'),
                    ]
                ],
                electricMeter: [
                    {value: null, disabled: true},
                    [
                        U2bValidators.required('Bitte Stromzähler auswählen'),
                        U2bElectricMeterValidators.isElectricMeter(),
                    ]
                ],
                from: [
                    this.data?.berthAssignments?.length === 1 ? startOfDay(this.data.berthAssignments[0].from) : null,
                    [
                        U2bValidators.required('Bitte gib ein korrektes Datumsformat ein. TT.MM.JJJJ'),
                        U2bDateValidators.maxDate(new Date()),
                    ]
                ],
                meterReadingStart: [
                    this.data?.berthAssignments?.length === 1 ? this.data.berthAssignments[0].to : null,
                    [
                        U2bValidators.required('Bitte Zählerstand angeben'),
                        U2bNumericValidators.numberFormat(),
                    ]
                ],
                paymentType: [ElectricMeterPaymentType.None],
                electricMeterProduct: [null],
                quantity: [null],
                product: [null],
                additionalProduct: [null],
                berthAssignment: [this.data?.berthAssignments?.length === 1 ? this.data.berthAssignments[0] : null],
            }
        );

        form.get('berthAssignment').valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((berthAssignment: BerthBoatAssignment) => {
                this.formGroup.get('from').setValue(berthAssignment.from);
                this.formGroup.get('to').setValue(berthAssignment.to);
            });

        if (this.data?.berthAssignments?.length < 1) {
            form.get('berthAssignment').disable();
        }

        return form;

    }

    private _filterElectricMeterCabinets(filter: string): ElectricMeterCabinet[] {
        return this.electricMeterCabinets.filter(
            electricMeterCabinet => electricMeterCabinet.handle.toLowerCase().includes(filter.toLowerCase())
        );
    }

    private _filterElectricMeters(filter: string): ElectricMeter[] {
        return this.electricMeters.filter(
            electricMeter => electricMeter.handle.toLowerCase().includes(filter.toLowerCase())
        );
    }

    private _filterElectricityProducts(filter: string): Observable<Product[]> {
        return of(this.electricityProducts)
            .pipe(
                map((products: Product[]) => products.filter((product: Product) => product.name.toLowerCase().includes(filter.toLowerCase())))
            );
    }

    private _loadElectricMeterCabinets(): void {
        this.electricMeterCabinets = [];
        this.loadingElectricMeterCabinets = true;

        this._electricMeterCabinetApiService.getAll()
            .subscribe(electricMeterCabinets => this.electricMeterCabinets = electricMeterCabinets.filter(cabinet => cabinet.electricMeters.length > 0))
            .add(() => {
                this.loadingElectricMeterCabinets = false;
                this.filteredElectricMeterCabinets$ = this.formGroup.get('electricMeterCabinet').valueChanges
                    .pipe(
                        startWith(''),
                        debounceTime(DEFAULT_DEBOUNCE_TIME),
                        map((value) => isString(value) ? this._filterElectricMeterCabinets(value) : this.electricMeterCabinets)
                    );
            });

    }

    private _loadElectricMeters(electricMeterCabinet?: ElectricMeterCabinet, from?: Date): void {

        let params = new HttpParams();

        if (electricMeterCabinet && electricMeterCabinet.id) {
            params = params
                .set('bcm_electric_meter_cabinets_id', electricMeterCabinet.id.toString());

            if (isValid(from)) {
                params = params.set('from', format(from, 'yyyy-MM-dd'));
            }
        }

        this.electricMeters = [];
        this.loadingElectricMeters = true;

        this._electricMeterApiService.getAll(params)
            .subscribe(electricMeters => {
                this.electricMeters = electricMeters
                    .map(electricMeter => {
                        electricMeter.hasOpenReading = electricMeter.readings
                            .some(reading => reading.startMeterReading != null && reading.endMeterReading == null) || false;
                        return electricMeter;
                    });
            })
            .add(() => {
                this.loadingElectricMeters = false;
                this.filteredElectricMeters$ = this.formGroup.get('electricMeter').valueChanges
                    .pipe(
                        startWith(''),
                        debounceTime(DEFAULT_DEBOUNCE_TIME),
                        map((value) => isString(value) ? this._filterElectricMeters(value) : this.electricMeters)
                    );
            });
    }

    public onSelectProduct(event: MatAutocompleteSelectedEvent): void {

        const product = event.option.value as Product;

        this.formGroup
            .patchValue({
                account: product.account,
                product,
                title: product.name,
                unit: product.unit,
                price: product.price,
                taxRate: product.taxRate,
            });
    }

    private _loadElectricityProducts(): void {
        this.electricityProducts = [];
        this.loadingProducts = true;
        this._productsApiService
            .getAll()
            .subscribe(products => this.electricityProducts = products.filter(
                product => product.category && product.category?.uniqueId === 'Stromgebühr'))
            .add(() => {
                this.filteredElectricityProducts$ = this.formGroup.get('electricMeterProduct').valueChanges
                    .pipe(
                        startWith(this.electricityProducts),
                        debounceTime(DEFAULT_DEBOUNCE_TIME),
                        switchMap((value) => isString(value) ? this._filterElectricityProducts(value) : of(this.electricityProducts)),
                    );
                this.loadingProducts = false;
            });
    }

}
