import { Component, Input, OnInit } from '@angular/core';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { ElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { ElectricMeterPaymentType } from '@modules/bcm/@shared/enums/electric-meter-payment-type';
import { RemoveElectricMeterDialogComponent } from '@sharedComponents/dialogs/remove-electric-meter-dialog/remove-electric-meter-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Product } from '@shared/models/product';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';

export interface DisconnectElectricMeterEvent {
    meterReadingStart: number;
    meterReadingEnd: number;
    from: Date;
    to: Date;
    electricMeterAssignment: ElectricMeterAssignment;
    product?: Product;
    quantity?: number;
}

@UntilDestroy()
@Component({
    selector: 'app-booking-assignment-electric-meters',
    templateUrl: './assignment-electric-meters.component.html',
    styleUrls: ['./assignment-electric-meters.component.scss']
})
export class AssignmentElectricMetersComponent implements OnInit {

    readonly ElectricMeterPaymentType = ElectricMeterPaymentType;

    readonly bcmTenantPermissions = BcmTenantPermission;
    readonly permissionNames = BcmUserPermission;

    @Input()
    set electricMeterAssignment(value: ElectricMeterAssignment) {
        this._electricMeterAssignment = value;
        this.electricMeterAssignment.berthAssignmentIndex = this._getBerthAssignmentIndex(value.berthAssignment?.uuid);
    }

    get electricMeterAssignment(): ElectricMeterAssignment {
        return this._electricMeterAssignment;
    }

    private _electricMeterAssignment: ElectricMeterAssignment;

    constructor(
        public bookingDialogService: BookingDialogService,
        private _matDialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    // TODO: move to service
    disconnectOrRemoveElectricMeterAssignment(electricMeterAssignment: ElectricMeterAssignment) {

        if (!electricMeterAssignment.id) {
            this.bookingDialogService.positions.removeFromElectricMeterAssignment(electricMeterAssignment);
            this.bookingDialogService.electricMeterAssignments.remove(electricMeterAssignment);

            return;
        }

        const removeElectricMeterDialogRef = this._matDialog
            .open(RemoveElectricMeterDialogComponent, {
                panelClass: 'remove-electric-meter-dialog',
                data: {electricMeterAssignment}
            });

        removeElectricMeterDialogRef.afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (formData) => {

                const disconnectElectricMeterEvent: DisconnectElectricMeterEvent = {
                    electricMeterAssignment: {
                        ...electricMeterAssignment,
                        meterReadingEnd: formData.meterReadingEnd,
                        to: formData.to,
                    },
                    meterReadingStart: formData.meterReadingStart,
                    meterReadingEnd: formData.meterReadingEnd,
                    from: electricMeterAssignment.from,
                    to: formData.to,
                    product: formData.product,
                    quantity: formData.meterReadingEnd - formData.meterReadingStart,
                };

                const index = this.bookingDialogService.electricMeterAssignments.value.findIndex((assignment) => assignment.id === electricMeterAssignment.id);

                this.bookingDialogService.electricMeterAssignments.value[index] = {
                    ...this.bookingDialogService.electricMeterAssignments.value[index],
                    ...disconnectElectricMeterEvent.electricMeterAssignment
                };

                this.bookingDialogService.electricMeterAssignments.sendUpdate(true);

                this.bookingDialogService.positions.addFromElectricMeterAssignmentDisconnect(disconnectElectricMeterEvent);
            });

    }

    // TODO: move to service
    private _getBerthAssignmentIndex(berthAssignmentUuid: string): number {
        return (this.bookingDialogService.berthAssignments.value || [])
            .filter(assignment => !assignment.deleted)
            .findIndex(assignment => assignment.uuid === berthAssignmentUuid);
    }

}
