import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { BcmPaymentType } from '@shared/models/person';
import { EMPTY, forkJoin } from 'rxjs';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { GetDateDialogComponent } from '@sharedComponents/dialogs/get-date-dialog/get-date-dialog.component';
// import { CashRegisterService } from '@bcmServices/cash-registers/cash-register.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { endOfToday } from 'date-fns';
// import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { InvoicePaymentDto } from '@shared/models/invoice-payment';
import { BcmFinancialRecord } from '@shared/models/bcm-financial-record';

@Injectable({providedIn: 'root'})
export class FinancialRecordsPaymentService {

    constructor(private invoicesService: InvoicesService,
                // private cashRegisterService: CashRegisterService,
                private appNotificationService: AppNotificationService,
                // private confirmDialogService: ConfirmDialogService,
                private matDialog: MatLegacyDialog) {
    }

    markInvoicesAsPaid(...financialRecords: BcmFinancialRecord[]) {
        const invoices = financialRecords.map(financialRecord => financialRecord.invoice).filter(_ => !!_);

        const dateDialogRef = this.matDialog.open(GetDateDialogComponent, {
            data: {
                dialogTitle: 'Zahldatum wählen',
                dateDefault: new Date(),
                dateMax: endOfToday(),
            }
        });

        return dateDialogRef
            .afterClosed()
            .pipe(
                // todo: add support for cash payments?
                // switchMap(date => {
                //     return this.confirmDialogService
                //         .appendSelectToBody({
                //             name: 'paymentType',
                //             type: 'select',
                //             label: 'Zahlart',
                //             list: [
                //                 {value: BcmPaymentType.Invoice, translation: paymentTypeTranslationDe[BcmPaymentType.Invoice]},
                //                 {value: BcmPaymentType.DirectDebit, translation: paymentTypeTranslationDe[BcmPaymentType.DirectDebit]},
                //                 {value: BcmPaymentType.Cash, translation: paymentTypeTranslationDe[BcmPaymentType.Cash]},
                //                 {value: BcmPaymentType.CreditCard, translation: paymentTypeTranslationDe[BcmPaymentType.CreditCard]},
                //                 {value: BcmPaymentType.EcCard, translation: paymentTypeTranslationDe[BcmPaymentType.EcCard]},
                //             ],
                //             listAttribute: 'value',
                //             listAttributeTranslation: 'translation',
                //             validators: [U2bValidators.required('Bitte Auswahl treffen.')]
                //         })
                //         .openAndReturnResult<{ paymentType: BcmPaymentType }>()
                //         .pipe(map(data => ({date, paymentType: data?.paymentType})));
                // }),
                // switchMap(({date, paymentType}) => {
                switchMap((date: Date | undefined) => {
                    if (!date) {
                        return EMPTY;
                    }

                    return forkJoin(
                        invoices.map(invoice => {
                            return this.invoicesService.addPayment(invoice, {
                                date,
                                paymentType: invoice.paymentType || BcmPaymentType.Invoice,
                                amount: invoice.totalGrossPrice,
                                note: 'Mehrfachstorno'
                            } as InvoicePaymentDto);
                        })
                    );
                }),
                tap(() => {
                    this.appNotificationService.showSuccess(`Zahlung gespeichert.`);
                })
            );

    }
}
