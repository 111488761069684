import { DataFilterOperator } from './filter-operator';
import { FILTER_OPERATORS_DEFAULT } from './constants';
import { FilterCondition } from './filter-condition';
import { DataFilter } from './datafilter';
import { defineDefaultOperators } from '@core/datafilter/operators';

let filterId = 0;

export class Filter<Entity> {
    private _name: string;
    private _fromDb: boolean;
    private _isFavorite: boolean;
    private _hasChanges: boolean;
    private readonly _id: number;
    private readonly _dataFilter: DataFilter<Entity>;
    private readonly _dataFilterOperators: DataFilterOperator[];

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }

    get fromDb(): boolean {
        return this._fromDb;
    }

    set fromDb(value: boolean) {
        this._fromDb = value;
    }

    get isFavorite(): boolean {
        return this._isFavorite;
    }

    set isFavorite(value: boolean) {
        this._isFavorite = value;
    }

    get hasChanges(): boolean {
        return this._hasChanges;
    }

    set hasChanges(value: boolean) {
        this._hasChanges = value;
    }

    get dataFilter(): DataFilter<Entity> {
        return this._dataFilter;
    }

    get conditions(): FilterCondition[] {
        return this._dataFilter.conditions || [];
    }

    get dataFilterOperators(): DataFilterOperator[] {
        return this._dataFilterOperators;
    }

    constructor(name?: string, dataFilterOperators?: DataFilterOperator[], fromDb = false) {
        this._id = ++filterId;
        this._name = name;
        this._fromDb = fromDb;
        this._dataFilterOperators = dataFilterOperators || FILTER_OPERATORS_DEFAULT;
        this._dataFilter = new DataFilter();
        defineDefaultOperators(this._dataFilter);
    }

    addCondition(fieldCondition: FilterCondition | Partial<FilterCondition> , initial = false): Filter<Entity> {
        if (fieldCondition instanceof FilterCondition) {
            this._dataFilter.add(fieldCondition);
        }
        this._hasChanges = !initial;
        return this;
    }

    removeCondition(fieldCondition: FilterCondition): Filter<Entity> {
        this._dataFilter.remove(fieldCondition);
        this._hasChanges = true;
        return this;
    }

    clearConditions(): Filter<Entity> {
        this._dataFilter.clear();
        this._hasChanges = true;
        return this;
    }
}
