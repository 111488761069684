import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { HttpParams } from '@angular/common/http';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';
import { BcmBooking, BcmBookingStatus, IBcmBooking } from '@shared/models/bcm-booking';
import { BcmDocument, BcmDocumentDto } from '@shared/models/bcm-document';
import { BcmFinancialRecord, BcmFinancialRecordDto } from '@shared/models/bcm-financial-record';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';

@Injectable({
    providedIn: 'root'
})
export class BcmBookingsApiService extends BcmApi2Service {

    getAll(params: HttpParams = new HttpParams()): Observable<BcmBooking[]> {
        return super.get<IBcmBooking[]>('bookings', params)
            .pipe(parseHttpResponseList<IBcmBooking, BcmBooking>(BcmBooking));
    }

    getAllStatus(): Observable<BcmBookingStatus[]> {
        return super.get<BcmBookingStatus[]>('bookings/status')
            .pipe(parseHttpResponseList<BcmBookingStatus, BcmBookingStatus>(BcmBookingStatus));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<BcmBooking> {
        return super.get<IBcmBooking>(`bookings/${id}`, params)
            .pipe(parseHttpResponseItem<IBcmBooking, BcmBooking>(BcmBooking));
    }

    add(dto: Partial<BcmBooking>): Observable<BcmBooking> {
        return super.post<BcmBooking>(`bookings`, dto);
    }

    update(id: number, dto: Partial<BcmBooking>): Observable<BcmBooking> {
        return super.put<BcmBooking>(`bookings/${id}`, dto);
    }

    remove(id: number, {
        cancelInvoice = false,
        removeInvoicePositions = false,
        removeDocuments = false
    }): Observable<any> {
        return super.delete(`bookings/${id}`, new HttpParams()
            .set('cancelInvoice', cancelInvoice)
            .set('removeInvoicePositions', removeInvoicePositions)
            .set('removeDocuments', removeDocuments)
        );
    }

    getDocuments(bookingId: number, params: HttpParams = new HttpParams()): Observable<BcmDocument[]> {
        return super.get<BcmDocumentDto[]>(`bookings/${bookingId}/documents`, params)
            .pipe(parseHttpResponseList<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    getFinancialRecords(bookingId: number, params: HttpParams = new HttpParams()): Observable<BcmFinancialRecord[]> {
        return super.get<BcmFinancialRecordDto[]>(`bookings/${bookingId}/financial-records`, params)
            .pipe(parseHttpResponseList<BcmFinancialRecordDto, BcmFinancialRecord>(BcmFinancialRecord));
    }

    getPositions(bookingId: number, params: HttpParams = new HttpParams()): Observable<InvoicePosition[]> {
        return super.get<InvoicePositionDto[]>(`bookings/${bookingId}/positions`, params)
            .pipe(parseHttpResponseList<InvoicePositionDto, InvoicePosition>(InvoicePosition));
    }

}

