export enum DataFilterOperator {
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  Equal = 'eq',
  NotEqual = 'neq',
  Contains = 'c',
  NotContains = 'nc',
  StartsWith = 'sw',
  EndsWith = 'ew',
  IsEmpty = 'e', // empty string, null or undefined
}
