import { Component, OnInit } from '@angular/core';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { v4 as uuidv4 } from 'uuid';
import { AbstractControlOptions, FormBuilder } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BcmBookingTraveler, IBcmBookingTraveler } from '@shared/models/bcm-booking';
import { BaseArrayTabComponent } from '@sharedComponents/dialogs/booking-dialog/tabs/abstract/base-array-tab.component';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { U2bValidators } from '@shared/validators/validators';

@UntilDestroy()
@Component({
    selector: 'booking-travelers-tab',
    templateUrl: './travelers-tab.component.html',
    styleUrls: ['./travelers-tab.component.scss']
})
export class TravelersTabComponent extends BaseArrayTabComponent<IBcmBookingTraveler, BcmBookingTraveler> implements OnInit {

    constructor(
        bookingDialogService: BookingDialogService,
        formBuilder: FormBuilder,
    ) {
        super(
            bookingDialogService,
            formBuilder,
            BookingAttribute.TRAVELERS,
            BcmBookingTraveler
        );
    }

    formTemplate(value: BcmBookingTraveler): {
        controls: { [key: string]: any },
        options?: AbstractControlOptions | null
    } {
        return {
            controls: {
                id: [value?.id || null],
                name: [value?.name || null, U2bValidators.required('Der Name ist erforderlich')],
                email: [value?.email || null],
                phone: [value?.phone || null],
                birthDate: [value?.birthDate || null],
                person: [value?.person || null],
                uuid: [value?.uuid || uuidv4()],
                deleted: [value?.deleted || false]
            },
        };
    }

    ngOnInit(): void {
        // TODO check if this is needed
        // this._bookingDialogService.formGroup.valueChanges
        //     .pipe(
        //         map(value => ({person: value.personForm?.person})),
        //         distinctUntilChanged((previous, current) =>
        //             previous.person?.id === current.person?.id)
        //     )
        //     .subscribe(({person}) => {
        //         if (person && this.value.length === 0) {
        //             this.add(person);
        //         }
        //     });
    }

}
