import { Component, Inject, Optional } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { U2bDateValidators } from '@shared/validators/date/date-validators';
import { BcmPaymentType, paymentTypeTranslationDe } from '@shared/models/person';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Invoice } from '@shared/models/invoice';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { CashRegisterService } from '@bcmServices/cash-registers/cash-register.service';
import { BcmCashRegister } from '@shared/models/bcm-cash-register';
import { switchMap } from 'rxjs';
import { InvoicePaymentDto } from '@shared/models/invoice-payment';
import { FinancialRecordsFacade } from '@modules/bcm/@core/state-management/financial-records/financial-records.facade';

@UntilDestroy()
@Component({
    selector: 'capture-payment-dialog',
    templateUrl: './capture-payment-dialog.component.html',
    styleUrls: ['./capture-payment-dialog.component.scss'],
})
export class CapturePaymentDialogComponent {

    protected BcmPaymentType = BcmPaymentType;

    protected formGroup: UntypedFormGroup;

    protected cashRegister: BcmCashRegister;

    protected maxDate = new Date();

    protected allowedPaymentTypes = [
        BcmPaymentType.Invoice,
        BcmPaymentType.DirectDebit,
        BcmPaymentType.Cash,
        BcmPaymentType.EcCard,
        BcmPaymentType.CreditCard,
    ];

    protected paymentTypeTranslationDe = paymentTypeTranslationDe;

    protected invoice: Invoice;

    protected isSaving = false;

    constructor(
        public dialogRef: MatDialogRef<CapturePaymentDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: { invoice: Invoice },
        private _formBuilder: UntypedFormBuilder,
        private _invoicesService: InvoicesService,
        private _appNotificationService: AppNotificationService,
        private _cashRegisterService: CashRegisterService,
        private _financialRecordsFacade: FinancialRecordsFacade
    ) {
        this.invoice = data.invoice;
        this._createForm();
    }

    save() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
            return;
        }

        this.isSaving = true;

        if ([BcmPaymentType.Cash, BcmPaymentType.CreditCard, BcmPaymentType.EcCard].includes(this.formGroup.get('paymentType').value)) {
            this._cashRegisterService.addInvoicePayIn(
                this.cashRegister,
                this.invoice,
                this.formGroup.get('paymentType').value,
                this.formGroup.get('amount').value,
            ).pipe(
                switchMap((receipt) => {
                    const data: InvoicePaymentDto = {
                        ...this.formGroup.value,
                        bcm_receipts_id: receipt.id
                    };

                    return this._invoicesService.addPayment(this.invoice, data);
                })
            ).subscribe((updatedInvoice) => {
                this._financialRecordsFacade.reloadCurrentListOrPage();
                this._appNotificationService.showSuccess(`Zahlung gespeichert.`);
                this.dialogRef.close(updatedInvoice);
            }).add(() => this.isSaving = false);
        } else {
            this._invoicesService
                .addPayment(this.invoice, this.formGroup.value)
                .subscribe((updatedInvoice) => {
                    this._appNotificationService.showSuccess(`Zahlung gespeichert.`);
                    this.dialogRef.close(updatedInvoice);
                })
                .add(() => this.isSaving = false);
        }
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            date: [new Date(), [U2bValidators.required('Bitte gib ein Datum an.'), U2bDateValidators.dateRequired()]],
            paymentType: [this.invoice.paymentType || BcmPaymentType.Invoice, [U2bValidators.required('Bitte Zahlart angeben.')]],
            amount: [this.getDefaultAmount(), [U2bValidators.required('Bitte Betrag angeben.')]],
            checkPayByCash: [false],
            note: []
        });
    }

    private getDefaultAmount(): number | null {
        return Math.round((this.invoice.missingAmount + Number.EPSILON) * 100) / 100;
    }
}
