import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { U2bDateValidators } from '@shared/validators/date/date-validators';
import { BcmPaymentType, paymentTypeTranslationDe } from '@shared/models/person';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { InvoicePayment } from '@shared/models/invoice-payment';
import { Invoice } from '@shared/models/invoice';

@Component({
    selector: 'edit-payment-dialog',
    templateUrl: './edit-payment-dialog.component.html',
    styleUrls: ['./edit-payment-dialog.component.scss'],
})
export class EditPaymentDialogComponent {

    protected formGroup: UntypedFormGroup;

    protected maxDate = new Date();

    protected paymentTypeTranslationDe = paymentTypeTranslationDe;

    protected invoice: Invoice;

    protected payment: InvoicePayment;

    protected isSaving = false;

    protected allowedPaymentTypes = [
        BcmPaymentType.Invoice,
        BcmPaymentType.DirectDebit,
    ];

    constructor(
        public dialogRef: MatDialogRef<EditPaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { invoice: Invoice, payment: InvoicePayment },
        private _formBuilder: UntypedFormBuilder,
        private _invoicesService: InvoicesService,
        private _appNotificationService: AppNotificationService,
    ) {
        this.invoice = data.invoice;
        this.payment = data.payment;

        this._createForm();
    }

    save() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
            return;
        }

        this.isSaving = true;

        this._invoicesService
            .updatePayment(this.payment, this.formGroup.value)
            .subscribe((updatedInvoice) => {
                this._appNotificationService.showSuccess(`Zahlung gespeichert.`);
                this.dialogRef.close(updatedInvoice);
            })
            .add(() => this.isSaving = false);
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            date: [this.payment.date, [U2bValidators.required('Bitte gib ein Datum an.'), U2bDateValidators.dateRequired()]],
            paymentType: [this.payment.paymentType, [U2bValidators.required('Bitte Zahlart angeben.')]],
            amount: [this.payment.amount, [U2bValidators.required('Bitte Betrag angeben.')]],
            note: [this.payment.note]
        });
    }
}
