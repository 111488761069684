import { Component, Inject, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { InvoiceService } from '../invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Invoice } from '@shared/models/invoice';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmPaymentType, Person } from '@shared/models/person';
import { Tenant } from '@shared/models/tenant';
import { BcmService } from '@modules/bcm/bcm.service';
import { GetInvoiceAddressDialogComponent } from '../dialogs/get-invoice-address-dialog/get-invoice-address-dialog.component';
import { Company } from '@shared/models/company';
import { U2bValidators } from '@shared/validators/validators';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { ConfirmDialogService } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.service';
import { Product } from '@shared/models/product';
import { IUnit } from '@shared/models/unit';
import { ITaxRate } from '@shared/models/tax-rate';
import { addDays, isValid, startOfDay } from '@core/date.facade';
import { U2bInformationDialogComponent } from '@shared/components/dialogs/information-dialog/information-dialog.component';
import { detectIeAndNonWebkitEdge } from '@shared/functions/detect-ie-and-non-webkit-edge';
import { InvoiceTypes, ReceiptTypes } from '@shared/invoice-types';
import { AppNotificationService } from '@core/services/app-notification.service';
import { replaceDueDatePlaceholder } from '../../replace-due-date-placeholder';
import { SendEmailDialogComponent } from '../dialogs/send-email-dialog/send-email-dialog.component';
import {
    BcmInvoiceTemplateSettings,
    BcmSettings,
    BcmSettingsSectionName,
    FooterLayout,
    TextAlign
} from '@shared/models/bcm-settings';
import { TWO_WEEKS_IN_DAYS } from '@shared/constants/data';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { BcmDynamicCurrency } from '@modules/bcm/@shared/pipes/dynamic-currency.pipe';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmTenantService } from '@modules/bcm/bcm-tenant.service';
import { downloadFile } from '@shared/functions/download-file';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PayByCashDialogComponent } from '@sharedComponents/dialogs/pay-by-cash-dialog/pay-by-cash-dialog.component';
import { BcmPersonsFacade } from '@bcmServices/persons/bcm-persons-facade';
import { BcmCompaniesFacade } from '@bcmServices/companies/bcm-companies-facade';
import { BcmInvoiceWdTemplate } from '@shared/models/bcm-invoice-wd-template';
import { BcmInvoiceWdTemplatesFacade } from '@bcmServices/invoices/bcm-invoice-wd-templates-facade';
import { faFileWord } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BcmInvoicePositionsService } from '@bcmServices/invoice-positions.service';
import { TranslationService } from '@core/translation/translation.service';
import { CostCentersFacade } from '@modules/bcm/@core/state-management/cost-centers/cost-centers.facade';

let positionId = 0;

export function isTsePayment(paymentType: BcmPaymentType): boolean {
    return paymentType === BcmPaymentType.Cash ||
        paymentType === BcmPaymentType.CreditCard ||
        paymentType === BcmPaymentType.EcCard ||
        paymentType === BcmPaymentType.PaymentProvider;
}

@UntilDestroy()
@Component({
    selector: 'invoice-form',
    templateUrl: './invoice-form.component.html',
    styleUrls: ['./invoice-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [BcmDynamicCurrency]
})
export class InvoiceFormComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject<any>();

    readonly InvoiceTypes = InvoiceTypes;
    readonly ReceiptTypes = ReceiptTypes;
    readonly faFileWord = faFileWord;

    formGroup: UntypedFormGroup;

    defaultUp2BoatTemplate: BcmInvoiceWdTemplate = {
        id: -1,
        creator: {firstName: '', lastName: ''},
        creatorName: '',
        note: '',
        title: 'Up2Boat Beleglayout (Standard)'
    };

    wordTemplate: BcmInvoiceWdTemplate;

    invoice: Invoice;

    BcmSettingsSectionName = BcmSettingsSectionName;

    settings: BcmSettings;

    invoiceSettings: BcmInvoiceTemplateSettings;

    isSaving = false;

    isLoadingTestPdf = false;

    formSubmitted = false;

    editClerk = false;

    showNote = false;

    newInvoice: boolean;

    pdfDataUrl: SafeUrl;

    dueDateNoteInvoice: string;

    dueDateNoteDirectDebit: string;

    certificateOfAchievementNote: string;

    products: Product[];

    filteredProducts$: Observable<Product[]>;

    taxRates: ITaxRate[];

    units: IUnit[];

    isIEOrNonWebkitEdge: boolean;

    forceShowEmptyPositionsError = false;

    get showEmptyPositionsError(): boolean {
        return (this.forceShowEmptyPositionsError || this.formSubmitted) && !this.invoice?.positions?.length;
    }

    invoicePaymentType = BcmPaymentType;

    bcmTenantPermissions = BcmTenantPermission;

    FooterLayout = FooterLayout;

    footersPreview: { headline?: string, text: SafeHtml, align: TextAlign }[];

    additionalDataUnderLogo: SafeHtml;

    positionsSelected: boolean;

    manuallyCreatedPositions: InvoicePosition[] = [];

    dragEnabled = false;

    invoiceTemplates: BcmInvoiceWdTemplate[] = [];

    showTaxRateColumn = true;

    invoicePositionsColspan = 9;

    constructor(
        @Optional() public dialogRef: MatDialogRef<InvoiceFormComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            positions: InvoicePosition[],
            person: Person,
            company: Company,
            invoiceFormData: {
                settings: BcmSettings,
                products: Product[],
                units: IUnit[],
                taxRates: ITaxRate[],
            }
        },
        private _invoiceService: InvoiceService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _matDialog: MatDialog,
        private _formBuilder: UntypedFormBuilder,
        private _appNotificationService: AppNotificationService,
        private _sanitizer: DomSanitizer,
        private _confirmDialogService: ConfirmDialogService,
        private _bcmTenantService: BcmTenantService,
        private _bcmPersonsFacade: BcmPersonsFacade,
        private _bcmCompaniesFacade: BcmCompaniesFacade,
        private _bcmInvoiceWdTemplatesFacade: BcmInvoiceWdTemplatesFacade,
        private _bcmInvoicePositionsService: BcmInvoicePositionsService,
        public bcmSettingsFacade: BcmSettingsFacade,
        public bcmService: BcmService,
        public _costCentersFacade: CostCentersFacade,
    ) {
        this.isIEOrNonWebkitEdge = detectIeAndNonWebkitEdge();
    }

    ngOnInit(): void {
        const {products, units, taxRates, settings, invoice} = this._activatedRoute.snapshot.data;

        if (this.data) {
            this.invoice = new Invoice();
            this.products = this.data.invoiceFormData.products;
            this.units = this.data.invoiceFormData.units;
            this.taxRates = this.data.invoiceFormData.taxRates;
            this.settings = this.data.invoiceFormData.settings;
        } else {
            this.products = products;
            this.settings = settings || {} as BcmSettings;
            this.units = units;
            this.taxRates = taxRates;
            this.invoice = invoice;

            this.pdfDataUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.invoice.pdfDataUrlUnsafe);
        }

        this.invoiceSettings = this.settings[BcmSettingsSectionName.InvoiceTemplate] || {} as BcmInvoiceTemplateSettings;
        this.showTaxRateColumn = this.taxRates?.length > 1 || (this.taxRates?.length === 0 && this.taxRates[0].value !== 0);

        this.invoicePositionsColspan = 10
            + (this.invoiceSettings.showItemNumber ? 1 : 0)
            + (this.invoiceSettings.showAccount ? 1 : 0)
            + (this.showTaxRateColumn ? 1 : 0);

        this.additionalDataUnderLogo = this._sanitizer.bypassSecurityTrustHtml(
            this.invoiceSettings.additionalDataUnderLogo?.replace(/\n/g, '<br>') || ''
        );

        const hasHeadlines = (this.invoiceSettings.footers || []).find(footer => !!footer.headline);
        this.footersPreview = (this.invoiceSettings.footers || [])
            .map(item => ({
                headline: item.headline,
                text: this._sanitizer.bypassSecurityTrustHtml(
                    (hasHeadlines ? `<div class="mb-4 font-size-12"><strong>${item.headline || '&nbsp;'}</strong></div>` : '') +
                    (item.text?.replace(/\n/g, '<br>') || '')
                ),
                align: item.align
            }));

        this.newInvoice = !this.invoice.id;

        if (!this.invoice.tenant?.id) {
            this.bcmService.selectedTenant$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((tenant: Tenant) => this.invoice.tenant = tenant);
        }

        if (this.invoiceSettings.footer) {
            this.invoiceSettings.footer = this.invoiceSettings.footer?.replace(/\n/g, '<br>') || '';
        }

        this.updateMessages();

        if (this.invoiceSettings.certificateOfAchievementNote) {
            this.certificateOfAchievementNote =
                this.invoiceSettings.certificateOfAchievementNote?.replace(/\n/g, '<br>') || '';
        }

        this.createForm();

        this._bcmInvoiceWdTemplatesFacade.templates$
            .pipe(
                untilDestroyed(this),
                map(templates => [this.defaultUp2BoatTemplate, ...templates])
            )
            .subscribe(templates => {
                this.invoiceTemplates = templates;

                const invoiceType = this.formGroup.get('invoiceType')?.value || InvoiceTypes.Invoice;

                this.setWordTemplate(invoiceType);

            });

        const personId = this.data?.person?.id ? String(this.data?.person?.id) :
            (this._activatedRoute.snapshot.paramMap.get('person') ||
                this._activatedRoute.snapshot.queryParamMap.get('person'));

        const companyId = this.data?.company?.id ? String(this.data?.company?.id) :
            (this._activatedRoute.snapshot.paramMap.get('company') ||
                this._activatedRoute.snapshot.queryParamMap.get('company'));

        const positionIds = this.data?.positions?.length ? this.data?.positions?.map(p => p.id)?.join(',') :
            (this._activatedRoute.snapshot.paramMap.get('positions') ||
                this._activatedRoute.snapshot.queryParamMap.get('positions'));

        this.loadData(personId, companyId, positionIds);
    }

    drop(event: CdkDragDrop<InvoicePosition[]>): void {
        moveItemInArray(this.invoice.positions, event.previousIndex, event.currentIndex);
    }

    createForm(): void {
        this.formGroup = this._formBuilder.group({
            address: [this.invoice.address, U2bValidators.required('Bitte Adresse eingeben oder auswählen')],
            invoiceType: [this.invoice.invoiceType, U2bValidators.required('Bitte Belegtyp wählen')],
            date: [this.invoice.date, U2bValidators.required(TranslationService.translate('enterValidInvoiceDate'))],
            dueDate: [this.invoice.dueDate, U2bValidators.required('Bitte gültiges Fälligkeitsdatum angeben')],
            clerk: [this.invoice.clerk],
            internalNote: [this.invoice.internalNote],
            note: [this.invoiceSettings.defaultNote],
            paymentType: [this.invoiceSettings.defaultPaymentType || BcmPaymentType.Invoice],
            subject: [this.invoice.subject],
            taxNumber: [this.invoice.taxNumber],
            yourSign: [this.invoice.yourSign]
        });

        const invoiceTypeControl = this.formGroup.get('invoiceType');
        const paymentTypeControl = this.formGroup.get('paymentType');
        const dateControl = this.formGroup.get('date');
        const dueDateControl = this.formGroup.get('dueDate');
        let defaultDueDate: Date;

        if (this.invoice.invoiceType === InvoiceTypes.Invoice
            && !!this.invoiceSettings.dueDateDefaultEmpty !== true
            && (!this.invoiceSettings.dueDateDefaultDays || this.invoiceSettings.dueDateDefaultDays > -1)) {
            defaultDueDate = addDays(startOfDay(new Date()), this.invoiceSettings.dueDateDefaultDays || TWO_WEEKS_IN_DAYS);
            dueDateControl.setValue(defaultDueDate);
            this.invoice.dueDate = defaultDueDate;
        }

        invoiceTypeControl
            .valueChanges
            .subscribe((invoiceType: InvoiceTypes | ReceiptTypes) => {

                this.setWordTemplate(invoiceType);

                switch (invoiceType) {
                    case InvoiceTypes.Invoice:
                        this.formGroup.get('paymentType').setValue(this.invoiceSettings.defaultPaymentType || BcmPaymentType.Invoice);
                        dueDateControl.enable();
                        paymentTypeControl.enable();
                        break;
                    case InvoiceTypes.Crediting:
                        this.formGroup.get('paymentType').setValue(BcmPaymentType.Invoice);
                        dueDateControl.disable();
                        paymentTypeControl.enable();
                        break;
                    case ReceiptTypes.Receipt:
                        this.formGroup.get('paymentType').setValue(BcmPaymentType.Cash);
                        dueDateControl.disable();
                        paymentTypeControl.setValue(BcmPaymentType.Cash);
                        break;
                }
            });

        dateControl
            .valueChanges
            .pipe(startWith(this.invoice.date))
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((date: Date) => {
                if (!!this.invoiceSettings.dueDateDefaultEmpty !== true && (!this.invoiceSettings.dueDateDefaultDays || this.invoiceSettings.dueDateDefaultDays > -1)) {
                    const dueDate = isValid(date) ? addDays(date, this.invoiceSettings.dueDateDefaultDays) : null;
                    dueDateControl.setValue(dueDate);
                    this.invoice.dueDate = dueDate;
                }
            });

        const dueDate$ = dueDateControl.valueChanges.pipe(startWith(dueDateControl.value), distinctUntilChanged());
        const totalGrossPriceChange$ = this.invoice.totalGrossPriceChange.pipe(startWith(this.invoice.totalGrossPrice || 0), distinctUntilChanged());

        const dueDateUpdater$ = combineLatest([dueDate$, totalGrossPriceChange$])
            .pipe(debounceTime(0));

        dueDateUpdater$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([dueDate]) => {
                this.invoice.dueDate = dueDate;

                this.updateMessages();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    loadData(personIdStr: string, companyIdStr: string, positionIdsStr: string): void {

        if (!personIdStr && !companyIdStr) {
            return;
        }

        const personId = parseInt(personIdStr, 10);
        const companyId = parseInt(companyIdStr, 10);
        const positionIds = positionIdsStr
            ? positionIdsStr.split(',').map(Number)
            : [];

        if (!!personId) {
            this._loadPersonById(personId, positionIds);
        } else if (!!companyId) {
            this._loadCompanyById(companyId, positionIds);
        }
    }

    showFoundPositionsDialog(positions: InvoicePosition[]): void {
        if (positions?.length === 0) {
            return;
        }

        this._confirmDialogService
            .setTitle(TranslationService.translate('openInvoicePositions'))
            .setDialogConfig({disableClose: true}) // forcing the user to click either yes or no and not accidentally missclick without taking over invoice positions
            .setBody(TranslationService.translate('foundOpenInvoicePositions', {positionsLength: positions.length}))
            .openWithCallback(() => {
                    this.addPositions(positions);
                }
            );
    }

    displayProductWith(product: Product): string {
        return product ? product.name : '';
    }

    onEditTemplateClick(): void {
        if (this.wordTemplate?.id) {
            this._router.navigate([this.bcmService.baseUrl, 'accounting', 'invoice-templates', 'word', this.wordTemplate.id]);
        } else {
            this._router.navigate([this.bcmService.baseUrl, 'accounting', 'invoice-settings']);
        }
    }

    onClickAddAddress(): void {

        const dialogRef = this._matDialog.open(GetInvoiceAddressDialogComponent);

        dialogRef.afterClosed()
            .subscribe((result: { person?: Person, company?: Company }) => {
                if (!result) {
                    return;
                }

                this.removePositionsFromPreviousInvoiceRecipient();

                if (result.person?.id) {
                    this._loadPersonById(result.person.id);
                } else if (result.company?.id) {
                    this._loadCompanyById(result.company.id);
                }
            });
    }

    private _loadPersonById(personId: number, positionIds?: number[]) {
        this._bcmPersonsFacade
            .loadOne(personId)
            .pipe(take(1))
            .subscribe(person => {
                this.invoice.company = new Company();
                this.invoice.person = person;
                this.invoice.address = person.getAddressString('\n', true, true);

                this._updateFormFields(person.invoicePaymentType, person.taxNumber);

                if (positionIds?.length) {
                    const relevantPositions = positionIds.length
                        ? person.positions.filter(p => positionIds.includes(p.id))
                        : person.positions;

                    this.addPositions(relevantPositions);
                } else {
                    this.showFoundPositionsDialog(person.positions);
                }
            });
    }

    private _loadCompanyById(companyId: number, positionIds?: number[]) {
        this._bcmCompaniesFacade
            .loadOne(companyId)
            .pipe(take(1))
            .subscribe(company => {
                this.invoice.person = new Person();
                this.invoice.company = company;
                this.invoice.address = company.getAddressString('\n', true, true, true);

                this._updateFormFields(company.invoicePaymentType, company.taxNumber);

                if (positionIds?.length) {
                    const relevantPositions = positionIds.length
                        ? company.positions.filter(p => positionIds.includes(p.id))
                        : company.positions;

                    this.addPositions(relevantPositions);
                } else {
                    this.showFoundPositionsDialog(company.positions);
                }
            });
    }

    private _updateFormFields(givenPaymentType: string | BcmPaymentType, taxNumber?: string): void {
        const paymentType = this.invoice.invoiceType === InvoiceTypes.Crediting
            ? BcmPaymentType.Invoice
            : (givenPaymentType || this.invoiceSettings.defaultPaymentType || BcmPaymentType.Invoice);

        if (paymentType === BcmPaymentType.Cash) {
            this.formGroup.get('invoiceType').setValue(ReceiptTypes.Receipt);
        }

        if (this.formGroup.get('invoiceType').value === ReceiptTypes.Receipt) {
            this.formGroup.get('paymentType').setValue(BcmPaymentType.Cash);
        } else {
            this.formGroup.get('paymentType').setValue(paymentType);
        }

        if (taxNumber?.length > 0) {
            this.formGroup.get('taxNumber').setValue(taxNumber, {emitEvent: false});
        }

        this.formGroup.get('address').setValue(this.invoice.address);
        this.formGroup.get('address').markAsDirty();

        this.updateMessages();
    }

    onClickEditAddress(): void {
        let name: string;

        if (this.invoice.person?.id) {
            name = this.invoice.person.toString(false);
        } else if (this.invoice.company?.id) {
            name = this.invoice.company.toString();
        }

        this._confirmDialogService
            .setTitle('Adresse ändern')
            .setBody(`Möchtest Du die Adresse von ${name} in den Stammdaten ändern, oder verwerfen und eine manuelle Adresse eingeben?`)
            .setYesButton({text: 'Stammdaten ändern'})
            .setNoButton({text: 'verwerfen'})
            .openWithCallback(() => {
                if (this.invoice.person?.id) {
                    this._router.navigate([this.bcmService.baseUrl, 'persons', this.invoice.person.id]);
                } else if (this.invoice.company?.id) {
                    this._router.navigate([this.bcmService.baseUrl, 'companies', this.invoice.company.id]);
                }
            }, () => {
                this.invoice.address = '';
                this.invoice.person = new Person();
                this.invoice.company = new Company();

                if (this.formGroup.get('invoiceType').value === ReceiptTypes.Receipt) {
                    this.formGroup.get('paymentType').setValue(BcmPaymentType.Cash);
                } else if (this.formGroup.get('invoiceType').value === InvoiceTypes.Crediting) {
                    this.formGroup.get('paymentType').setValue(BcmPaymentType.Invoice);
                } else {
                    this.formGroup.get('paymentType').setValue(this.invoiceSettings.defaultPaymentType || BcmPaymentType.Invoice);
                }
                this.formGroup.get('address').setValue(this.invoice.address);

                this.removePositionsFromPreviousInvoiceRecipient();
                this.formGroup.get('address').markAsPristine();
            });
    }

    removePositionsFromPreviousInvoiceRecipient(): void {
        this.invoice.removePositionsFromPreviousInvoiceRecipient();
    }

    editPosition(position: InvoicePosition): void {
        if (position.voucher) {
            return;
        }
        this._bcmInvoicePositionsService
            .editInvoicePosition(position)
            .pipe(filter(positions => positions?.length > 0))
            .subscribe((positions: InvoicePosition[]) => {
                for (const newOrUpdatedPosition of positions) {

                    const index = this.invoice.positions.findIndex(p => p.positionId === newOrUpdatedPosition.positionId);

                    if (index !== -1) {
                        this.invoice.positions.splice(index, 1, newOrUpdatedPosition);

                        const manuallyCreatedPositionsIndex = this.manuallyCreatedPositions.findIndex(p => p.positionId === newOrUpdatedPosition.positionId);
                        this.manuallyCreatedPositions.splice(manuallyCreatedPositionsIndex, 1, newOrUpdatedPosition);
                    } else {
                        positionId = Math.max(positionId, ...this.invoice.positions.map(p => p.positionId)) + 1;
                        newOrUpdatedPosition.positionId = positionId;
                        this.invoice.positions.push(newOrUpdatedPosition);
                        this.manuallyCreatedPositions.push(newOrUpdatedPosition);
                    }
                }

                this.formGroup.markAsTouched();
                this.invoice.updatePrices();

            });
    }

    onClickAddPosition(markAsTouched = true): void {
        this._bcmInvoicePositionsService
            .getInvoicePosition({
                person: this.invoice?.person,
                company: this.invoice?.company,
                positions: this.invoice.positions
            })
            .pipe(filter(p => p?.length > 0))
            .subscribe(positions => {
                for (const position of positions) {
                    position.positionId = (--positionId);

                    this.invoice.positions.push(position);
                    this.manuallyCreatedPositions.push(position);
                }

                if (markAsTouched) {
                    this.formGroup.markAsTouched();
                }

                this.invoice.updatePrices();

                this.forceShowEmptyPositionsError = false;
            });
    }

    addPositions(positions: InvoicePosition[]): void {
        for (const position of positions) {
            position.positionId = (--positionId);
            this.invoice.positions.push(position);
        }

        // remove manually created items from positions and add them to the end again.
        this.invoice.positions.splice(0, this.manuallyCreatedPositions.length);
        this.manuallyCreatedPositions.forEach(pos => this.invoice.positions.push(pos));

        // setting the sorting index for all the positions again
        this.invoice.positions.forEach((entry, index) => entry.sortIndex = index + 1);
        this.invoice.updatePrices();

        this.forceShowEmptyPositionsError = false;
    }

    onClickRemovePosition(position: InvoicePosition, index: number): void {
        this._confirmDialogService
            .setTitle('Position entfernen')
            .setBody(`Möchtest Du die Position <strong>${position.title}</strong> wirklich von dem aktuellen Beleg entfernen?`)
            .useWarnTheme()
            .openWithCallback(() => {
                this.invoice.removePosition(position, index);
                this.forceShowEmptyPositionsError = true;
            });
    }

    async saveInvoice(testOnly = false): Promise<void> {
        this.formSubmitted = true;

        if (this.invoice.positions?.length === 0) {
            this._appNotificationService.showError(TranslationService.translate('invoiceHasNoPositions'));
            return;
        }

        if (this.formGroup.invalid) {
            this._appNotificationService.showError(TranslationService.translate('invoiceMissingValues'));
            return;
        }

        this.isSaving = !testOnly;
        this.isLoadingTestPdf = testOnly;

        const formValue = this.formGroup.value;

        this.invoice.date = formValue.date;
        this.invoice.dueDate = formValue.dueDate;
        this.invoice.clerk = this.editClerk && formValue.clerk ? formValue.clerk : '';
        this.invoice.internalNote = formValue.internalNote;
        this.invoice.note = formValue.note;
        this.invoice.paymentType = formValue.paymentType;
        this.invoice.address = formValue.address;
        this.invoice.invoiceType = formValue.invoiceType;
        this.invoice.wordTemplate = this.wordTemplate;
        this.invoice.subject = formValue.subject;
        this.invoice.taxNumber = formValue.taxNumber;
        this.invoice.yourSign = formValue.yourSign;

        if (testOnly) {
            this._invoiceService
                // Need to add it like this, because of circular dependencies, where JSON cries...
                .getInvoiceTestPDF({
                    date: this.invoice.date,
                    dueDate: this.invoice.dueDate,
                    note: this.invoice.note,
                    internalNote: this.invoice.internalNote,
                    tenant: this.invoice.tenant,
                    totalDiscount: this.invoice.totalDiscount,
                    totalNetPrice: this.invoice.totalNetPrice,
                    totalGrossPrice: this.invoice.totalGrossPrice,
                    totalTaxes: this.invoice.totalTaxes,
                    totalTax: this.invoice.totalTax,
                    person: this.invoice.person,
                    company: this.invoice.company,
                    positions: this.invoice.positions,
                    clerk: this.invoice.clerk,
                    paymentType: this.invoice.paymentType,
                    address: this.invoice.address,
                    invoiceType: this.invoice.invoiceType,
                    wordTemplate: this.invoice.wordTemplate,
                    subject: this.invoice.subject,
                    taxNumber: this.invoice.taxNumber,
                    yourSign: this.invoice.yourSign,
                })
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(pdfBlob => this._downloadTestPdf(pdfBlob))
                .add(() => {
                    this.isLoadingTestPdf = false;
                    this.formSubmitted = false;
                });
            return;
        }

        if (isTsePayment(this.formGroup.get('paymentType').value)) {
            this._matDialog.open(PayByCashDialogComponent, {
                data: {
                    positions: this.invoice.positions,
                    person: this.invoice.person,
                    company: this.invoice.company,
                    formGroup: this.formGroup,
                    wordTemplate: this.invoice.wordTemplate
                },
                disableClose: true
            })
                .afterClosed()
                .subscribe(() => {
                    this.isSaving = false;
                    this.formSubmitted = false;
                });
        } else {

            this._invoiceService
                // Need to add it like this, because of circular dependencies, where JSON cries...
                .saveInvoice({
                    date: this.invoice.date,
                    dueDate: this.invoice.dueDate,
                    note: this.invoice.note,
                    internalNote: this.invoice.internalNote,
                    tenant: this.invoice.tenant,
                    totalDiscount: this.invoice.totalDiscount,
                    totalNetPrice: this.invoice.totalNetPrice,
                    totalGrossPrice: this.invoice.totalGrossPrice,
                    totalTaxes: this.invoice.totalTaxes,
                    totalTax: this.invoice.totalTax,
                    person: this.invoice.person,
                    company: this.invoice.company,
                    positions: this.invoice.positions,
                    clerk: this.invoice.clerk,
                    paymentType: this.invoice.paymentType,
                    address: this.invoice.address,
                    invoiceType: this.invoice.invoiceType,
                    wordTemplate: this.invoice.wordTemplate,
                    subject: this.invoice.subject,
                    taxNumber: this.invoice.taxNumber,
                    yourSign: this.invoice.yourSign
                })
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(async (response: any) => {
                    this.formGroup.markAsPristine();

                    const person = this.invoice.person?.id && this.invoice.person;
                    const company = this.invoice.company?.id && this.invoice.company;

                    const invoicesByMail = (person || company)?.invoicesByMail;
                    const mails = (person || company)?.mails;
                    const invoiceId = response.id;

                    if (invoicesByMail && this._bcmTenantService.hasOnePermissionOf(this.bcmTenantPermissions.INVOICE_MAILING)) {
                        const sendEmailDialogRef = this._matDialog.open(SendEmailDialogComponent, {
                            data: {
                                invoice: this.invoice,
                                mails,
                                invoiceId
                            }
                        });

                        await sendEmailDialogRef.afterClosed().toPromise();
                    }

                    if (!this.data) {

                        this._router.navigate([this.bcmService.baseUrl, 'accounting', 'invoices', 'view', response.invoiceNumber], {relativeTo: this._activatedRoute})
                            .then(() => {
                                this.newInvoice = false;

                                if (this.invoice.invoiceType === InvoiceTypes.Invoice) {
                                    this._appNotificationService.showSuccess(TranslationService.translate('createdInvoiceWithNumber', {invoiceNumber: response.invoiceNumber}));
                                }

                                if (this.invoice.invoiceType === InvoiceTypes.Crediting) {
                                    this._appNotificationService.showSuccess(TranslationService.translate('createdCreditingWithNumber', {invoiceNumber: response.invoiceNumber}));
                                }

                            });

                    }else {
                        this.dialogRef.close(response);
                    }

                })
                .add(() => {
                    this.isSaving = false;
                    this.formSubmitted = false;
                });

        }

        return;
    }

    async _downloadTestPdf(pdfBlob): Promise<void> {
        if (!pdfBlob || !pdfBlob.size) {
            const confirmDialogRef = this._matDialog.open(U2bInformationDialogComponent);

            confirmDialogRef.componentInstance.dialogTitle = `Download fehlgeschlagen`;
            confirmDialogRef.componentInstance.dialogBody = `Leider konnte keine Rechung erstellt werden. Wenn dieser Fehler erneut auftritt, melde Dich bitte bei unserem Up2Boat Support Team.`;

            return;
        }

        const documentTitle = TranslationService.translate('invoiceExample');

        downloadFile(pdfBlob, documentTitle, 'pdf', 'application/pdf');
    }

    private updateMessages(): void {
        if (this.invoiceSettings.dueDateNoteInvoice) {
            this.dueDateNoteInvoice =
                replaceDueDatePlaceholder(
                    this.invoiceSettings.dueDateNoteInvoice,
                    this.invoice,
                );
        }

        if (this.invoiceSettings.dueDateNoteDirectDebit) {
            this.dueDateNoteDirectDebit =
                replaceDueDatePlaceholder(
                    this.invoiceSettings.dueDateNoteDirectDebit,
                    this.invoice,
                );
        }
    }

    changePositionSelection(id: number): void {
        this.invoice.positions[id].selected = !this.invoice.positions[id].selected;
        this.positionsSelected = this.invoice.positions.filter(pos => pos.selected).length > 0;
    }

    onClickDeleteMultiplePositions(): void {
        this._confirmDialogService
            .setTitle('Ausgewählte Positionen entfernen')
            .setBody('Möchtest Du die ausgewählten Positionen wirklich von dem aktuellen Beleg entfernen?')
            .useWarnTheme()
            .openWithCallback(() => {

                for (const position of this.invoice.positions.filter(pos => pos.selected)) {
                    const index = this.invoice.positions.indexOf(position);
                    this.invoice.removePosition(position, index);
                }

                this.forceShowEmptyPositionsError = true;
                this.positionsSelected = false;
            });
    }

    private setWordTemplate(invoiceType: InvoiceTypes | ReceiptTypes): void {
        const invoiceTypeStr = invoiceType.charAt(0).toUpperCase() + invoiceType.slice(1); // dirty solution ..

        if (this.settings[BcmSettingsSectionName.InvoiceDefaultTemplates][invoiceTypeStr]) {
            this.wordTemplate = this.invoiceTemplates.find(t => t.id === this.settings[BcmSettingsSectionName.InvoiceDefaultTemplates][invoiceTypeStr].id);
        } else {
            this.wordTemplate = this.invoiceTemplates[0];
        }
    }

}
