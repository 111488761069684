import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IFile } from '@shared/interfaces/file';
import { FilesApiService } from '@bcmApiServices/files.api-service';
import { isString } from '../../functions/is-string';

@Component({
    selector: 'u2b-secured-image-bcm',
    styles: [`
        img {
            width: 100%;
        }
        .image-loader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    `],
    template: `
        <ng-container *ngIf="dataUrl; else imageLoader">
            <img class="{{class}}"
                 alt=""
                 [class.is-placeholder]="isPlaceholder"
                 [class.is-real-image]="!isPlaceholder"
                 [src]="dataUrl"/>
        </ng-container>
        <ng-template #imageLoader>
            <div class="image-loader">
                <mat-spinner mode="indeterminate" [diameter]="20"></mat-spinner>
            </div>
        </ng-template>
    `
})
export class SecuredImageBcmComponent implements OnChanges {

    @Input() public class: string;

    @Input() public file: IFile;

    @Input() public fileId: number;

    @Input() public tempUrl: SafeUrl;

    @Input() public placeholder = 'assets/images/placeholder/default.jpg';

    private src$: BehaviorSubject<[IFile, number]>;

    public dataUrl: SafeUrl;

    public isPlaceholder = true;

    constructor(private _filesApiService: FilesApiService,
                private domSanitizer: DomSanitizer,
                cdr: ChangeDetectorRef) {
        this.src$ = new BehaviorSubject([this.file, this.fileId]);
        this.src$
            .pipe(switchMap((file: [IFile, number]) => this.loadImage(file)))
            .subscribe((url: SafeUrl) => {
                this.dataUrl = url;
                cdr.markForCheck();
            });
    }

    ngOnChanges(): void {
        if (this.tempUrl) {
            this.src$.next([null, null] as [IFile, number]);
            return;
        }
        this.src$.next([this.file, this.fileId]);
    }

    private loadImage([file, fileId]: [IFile, number]): Observable<any> {

        if (!file?.id && !fileId) {
            this.isPlaceholder = true;
            return of(this.tempUrl || this.placeholder);
        }

        return this._filesApiService.getById(file?.id || fileId)
            .pipe(
                map((e: Blob | string) => {
                    this.isPlaceholder = false;

                    if (!(e as Blob)?.size) {
                        throw new Error();
                    }

                    if (isString(e)) {
                        return this.domSanitizer.bypassSecurityTrustUrl(e as string);
                    }

                    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e as Blob));
                }),
                catchError(() => {
                    this.isPlaceholder = true;
                    return of(this.tempUrl || this.placeholder);
                }),
            );
    }
}
