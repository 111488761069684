<ng-container *ngIf="acceptedFileExtensions && !hideAcceptedFileExtensions">
    <u2b-message>
        <header>Erlaubte Dateitypen</header>
        {{acceptedFileExtensions}}
    </u2b-message>
</ng-container>

<ng-container *ngIf="maxFileSize">
    <u2b-message type="warning">
        Die maximal erlaubte Dateigröße beträgt {{maxFileSize}}.
    </u2b-message>
</ng-container>

<div id="drop-zone"
     class="mb-16"
     *ngIf="showDropDownArea"
     [class.has-drag-hover]="hasDragHover"
     [class.disabled]="isUploading && disableWhileUploading"
     (drop)="dropHandler($event)"
     (dragleave)="dragLeaveHandler($event)"
     (dragover)="dragOverHandler($event)"
     (click)="onClick()">
    <p style="margin: 60px auto 0; width: 80%; line-height: 36px;">
        <ng-container *ngIf="hasDragHover; else defaultMessage">
            <ng-container *ngIf="multiple; else singleFileText">
                Datei(en) hier ablegen.
            </ng-container>
            <ng-template #singleFileText>
                Datei hier ablegen.
            </ng-template>
        </ng-container>
        <ng-template #defaultMessage>
            <ng-container *ngIf="multiple; else singleFileText">
                Bereich anklicken oder eine oder mehrere Datei(en) hierher ziehen.
            </ng-container>
            <ng-template #singleFileText>
                Bereich anklicken oder Datei hierher ziehen.
            </ng-template>
        </ng-template>
    </p>
</div>

<input type="file" id="fileUpload" name="fileUpload" style="display:none;"
       [attr.multiple]="multiple"
       [disabled]="isUploading && disableWhileUploading"
       [attr.accept]="acceptedFileExtensions?.join(',')"/>

<button mat-flat-button
        color="accent"
        style="width: 100%"
        class="mb-24"
        type="button"
        [disabled]="isUploading && disableWhileUploading"
        (click)="onClick()">
    <mat-icon>add_circle</mat-icon>
    {{buttonText}}
</button>

<ng-container *ngFor="let file of files">

    <div fxLayout="row" fxLayoutAlign="space-between center">

        <div>
            <strong>
                {{file.data.name}}
            </strong>
            ({{file.progress}} %
            <ng-container *ngIf="file.state === 'err'"> - fehlgeschlagen</ng-container>
            )
        </div>
        <div>
            <button mat-icon-button
                    matTooltip="Erneut versuchen"
                    [disabled]="!file.canRetry"
                    (click)="retryFile(file, $event)">
                <mat-icon>refresh</mat-icon>
            </button>
            <ng-container *ngIf="file.canCancel">
                <button mat-icon-button
                        matTooltip="Abbrechen"
                        [disabled]="!file.canRetry"
                        (click)="cancelFile(file, $event)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </ng-container>
        </div>
    </div>
    <div>
        <mat-progress-bar [value]="file.progress"></mat-progress-bar>
    </div>
</ng-container>

<ng-content></ng-content>
