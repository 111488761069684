import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { Person } from '@shared/models/person';

@UntilDestroy()
@Component({
    selector: 'booking-traveler',
    templateUrl: './traveler.component.html',
    styleUrls: ['./traveler.component.scss'],
})
export class TravelerComponent implements OnInit {

    constructor(public bookingDialogService: BookingDialogService) {
    }

    @Input()
    travelerFormGroup: UntypedFormGroup;

    @Output()
    removeTraveler: EventEmitter<void> = new EventEmitter<void>();

    expanded: boolean;

    ngOnInit(): void {
        this.expanded = !this.travelerFormGroup.get('id')?.value;
    }

    updateFormData(person: Person): void {
        if (person) {
            this.travelerFormGroup.patchValue({
                person: person,
                name: person.fullName,
                email: person.mail,
                phone: person.phone,
                birthDate: person.birthDate,
            });
        }
    }

}
