<div [formGroup]="formGroupDirective.form">

    <form-widget-tenant-relation [parentFormGroup]="formGroupDirective.form"
                                 [prefilledTenantRelationId]="bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.id"
                                 [givenBoat]="bookingDialogService.boat.value$ | async"
                                 [appearance]="'outline'"
                                 [slimmedView]="true"></form-widget-tenant-relation>

    <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
        <h2>Laufzeit der Beziehung</h2>
        <div>"Ende" leer lassen, wenn Ende der Beziehung offen.</div>
    </div>

        <app-input-date-time formControlName="fromDate">
            <label>Start</label>
        </app-input-date-time>

        <app-input-date-time formControlName="toDate">
            <label>Ende</label>
        </app-input-date-time>

    <ng-container
            *ngIf="bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.products?.length">

        <mat-divider class="my-24"></mat-divider>

        <u2b-message type="info">
            <header>Produkte gefunden</header>
            <p>
                Der Beziehung
                <strong>{{ bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.name }}</strong>
                sind Produkte
                und die Zahlweise
                <strong>{{ bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.payableOption?.name }}</strong>
                zugewiesen.
                Bitte gib an, wann das Abo starten soll (Ende optional). Gib zudem den 1.&nbsp;Leistungszeitraum
                an, für den die folgenden Produkte berechnet werden sollen:
            </p>
            <ng-container
                    *ngFor="let product of bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.products">
                <div>
                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="pr-16">{{ product.name }} (Anzahl: {{product.quantity}})</div>
                        <div>{{ product.price | bcmDynamicCurrency: '1.2-2' }}</div>
                    </div>
                </div>
            </ng-container>
        </u2b-message>

        <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
            <br>
            <strong>
                Regelmäßige Zahlung
                <ng-container
                        *ngIf="bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.payableOption?.name">
                            <span style="text-transform: lowercase;">
                                - {{ bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.payableOption?.name || 'Laufzeit' }}
                            </span>
                </ng-container>
            </strong>
        </div>

        <h3>Wann soll die <strong>erste</strong> Position erstellt werden?</h3>
        <app-input-date-time formControlName="periodFromDate">
            <label>Erste Position am</label>
        </app-input-date-time>

        <h3>
            Wann soll die <strong>letzte</strong> Position erzeugt werden?<br>
            <small>Leer lassen, wenn Laufzeitende offen.</small>
        </h3>
        <app-input-date-time formControlName="periodToDate">
            <label>Letzte Position am</label>
        </app-input-date-time>

        <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
            <h2>Erster Leistungszeitraum</h2>
            <p>
                Definiere den 1. Leistungszeitraum, für den eine Position erstellt werden soll.
                Nachfolgende Positionen werden
                <ng-container
                        *ngIf="bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.payableOption; else other">
                    <strong style="text-transform: lowercase;">{{ bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.payableOption?.name }}</strong>
                </ng-container>
                <ng-template #other>im ausgewählten Zahlungsintervall</ng-template>
                erneut erzeugt.
            </p>
        </div>

        <ng-container
                *ngIf="bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.payableOption?.id === PayableOptionId.Yearly && seasons?.length">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" floatLabel="always" fxFlex>
                    <mat-label>Wähle aus periodischen Saisons</mat-label>
                    <mat-select data-test="FS-Saisons"
                                [formControl]="seasonFormControl"
                                [compareWith]="compareSeasons">
                        <mat-option [value]="null">- Keine Saison -</mat-option>
                        <mat-option *ngFor="let season of seasons" [value]="season">
                            {{ season.name }} / {{ season.startDate | dfnsFormat : 'dd.MM.yyyy' }} -
                            {{ season.endDate | dfnsFormat : 'dd.MM.yyyy' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>

        <app-input-date-time formControlName="vestingPeriodFrom">
            <label>Leistungszeitraum Start</label>
        </app-input-date-time>

        <app-input-date-time formControlName="vestingPeriodUntil">
            <label>Leistungszeitraum Ende</label>
        </app-input-date-time>

        <ng-container
                *ngIf="[PayableOptionId.Monthly, PayableOptionId.HalfYearly, PayableOptionId.QuarterYearly].includes(bookingDialogService.tenantRelationAssignment?.value?.tenantRelation?.payableOption?.id)">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-checkbox formControlName="lastDayOfMonth">
                    Leistungszeitraum Ende automatisch auf den letzten Tag des Monats setzen
                </mat-checkbox>
            </div>
        </ng-container>

    </ng-container>

</div>
