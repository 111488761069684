<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <ng-container *ngIf="bookingDialogService.initialized">
                <span class="title dialog-title">Buchung {{ isNew ? 'erstellen' : 'bearbeiten' }}</span>
            </ng-container>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <ng-container *ngIf="bookingDialogService.initialized; else loading">

        <div mat-dialog-content class="m-0 booking-dialog-content" fusePerfectScrollbar>

            <ng-container *ngIf="fromToChangedTenantRelation || fromToChangedPositions">
                <u2b-message type="warning">
                    Der Zeitraum der Buchung hat sich geändert. Soll der aktualisierte Zeitraum für die Beziehung
                    und/oder die Leistungen übernommen werden?
                </u2b-message>
                <div class="from-to-changed-buttons">
                    <ng-container *ngIf="fromToChangedTenantRelation">
                        <button mat-raised-button color="accent"
                                (click)="updateTenantRelationDates()">
                            Zeitraum für Beziehung übernehmen
                        </button>
                    </ng-container>
                    <ng-container *ngIf="fromToChangedPositions">
                        <button mat-raised-button color="accent"
                                (click)="updatePositionsDates()">
                            Zeitraum für Leistungen übernehmen (Positionen aktualisieren)
                        </button>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="bookingDialogAppearance === BookingDialogAppearance.TABS; else useAccordion">
                <mat-tab-group [animationDuration]="0" [(selectedIndex)]="tabIndex">

                    <ng-container
                            *ngIf="bookingDialogFirstChoice === BookingDialogFirstChoice.PERSON_OR_COMPANY; else firstChoiceBoat">
                        <mat-tab label="Allgemein">
                            <booking-general-tab>
                            </booking-general-tab>
                        </mat-tab>

                        <mat-tab label="Liegeplatz">
                            <booking-berth-assignments-tab
                                    [scrollOnCreate]="true">
                            </booking-berth-assignments-tab>
                        </mat-tab>
                    </ng-container>

                    <ng-template #firstChoiceBoat>
                        <mat-tab label="Liegeplatz">
                            <booking-berth-assignments-tab
                                    [scrollOnCreate]="true">
                            </booking-berth-assignments-tab>
                        </mat-tab>

                        <mat-tab label="Allgemein">
                            <booking-general-tab>
                            </booking-general-tab>
                        </mat-tab>
                    </ng-template>

                    <ng-container
                            *hasTenantPermission="bcmTenantPermissions.ELECTRIC_METERS">
                        <ng-container *hasPermission="permissionNames.ELECTRIC_METERS_READ">
                            <mat-tab label="Stromzähler">
                                <booking-electric-meters-tab>
                                </booking-electric-meters-tab>
                            </mat-tab>
                        </ng-container>
                    </ng-container>

                    <mat-tab label="Reisende">
                        <booking-travelers-tab>
                        </booking-travelers-tab>
                    </mat-tab>

                    <mat-tab label="Beziehung">
                        <booking-tenant-relation-tab>
                        </booking-tenant-relation-tab>
                    </mat-tab>

                    <mat-tab label="Leistungen">
                        <booking-positions-tab>
                        </booking-positions-tab>
                    </mat-tab>

                    <mat-tab label="Dokumente / Verträge">
                        <booking-documents-tab>
                        </booking-documents-tab>
                    </mat-tab>

                </mat-tab-group>
            </ng-container>

            <ng-template #useAccordion>
                <mat-accordion [multi]="true">

                    <ng-container
                            *ngIf="bookingDialogFirstChoice === BookingDialogFirstChoice.PERSON_OR_COMPANY; else firstChoiceBoat">
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Allgemein</mat-panel-title>
                            </mat-expansion-panel-header>
                            <booking-general-tab>
                            </booking-general-tab>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Liegeplatz</mat-panel-title>
                            </mat-expansion-panel-header>
                            <booking-berth-assignments-tab>
                                (boatEditModeEmitter)="boatEditMode = $event"
                            </booking-berth-assignments-tab>
                        </mat-expansion-panel>
                    </ng-container>

                    <ng-template #firstChoiceBoat>
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Liegeplatz</mat-panel-title>
                            </mat-expansion-panel-header>
                            <booking-berth-assignments-tab>
                                (boatEditModeEmitter)="boatEditMode = $event"
                            </booking-berth-assignments-tab>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Allgemein</mat-panel-title>
                            </mat-expansion-panel-header>
                            <booking-general-tab>
                            </booking-general-tab>
                        </mat-expansion-panel>
                    </ng-template>

                    <ng-container
                            *hasTenantPermission="bcmTenantPermissions.ELECTRIC_METERS">
                        <ng-container *hasPermission="permissionNames.ELECTRIC_METERS_READ">
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Stromzähler</mat-panel-title>
                                </mat-expansion-panel-header>
                                <booking-electric-meters-tab>
                                </booking-electric-meters-tab>
                            </mat-expansion-panel>
                        </ng-container>
                    </ng-container>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Reisende</mat-panel-title>
                        </mat-expansion-panel-header>
                        <booking-travelers-tab>
                        </booking-travelers-tab>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Beziehung</mat-panel-title>
                        </mat-expansion-panel-header>
                        <booking-tenant-relation-tab>
                        </booking-tenant-relation-tab>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Kosten</mat-panel-title>
                        </mat-expansion-panel-header>
                        <booking-positions-tab>
                        </booking-positions-tab>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Dokumente / Verträge</mat-panel-title>
                        </mat-expansion-panel-header>
                        <booking-documents-tab>
                        </booking-documents-tab>
                        <ng-container *ngIf="!isNew">
                            <button data-test="B-Weiter" mat-flat-button
                                    color="accent"
                                    [disabled]="!bookingDialogService.initialized || isSaving || (!bookingDialogService.unsavedChanges && !isNew)"
                                    (click)="$event.stopPropagation(); save();">
                                <mat-icon>save</mat-icon>
                                <span>Speichern</span>
                            </button>
                        </ng-container>
                    </mat-expansion-panel>

                </mat-accordion>
            </ng-template>

        </div>

        <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">

            <div>
                <ng-container *ngIf="bookingDialogService.unsavedChanges">
                    <button data-test="B-Abbrechen" mat-button
                            (click)="dialogRef.close(false)"
                            aria-label="Dialog schließen">
                        <mat-icon>cancel</mat-icon>
                        Abbrechen
                    </button>
                </ng-container>
            </div>

            <div>

                <ng-template #saving>
                    <mat-icon>save</mat-icon>
                    <span>Speichert...</span>
                    <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                </ng-template>

                <ng-template #notSavingClose>
                    <mat-icon>save</mat-icon>
                    <mat-icon fxShow fxHide.gt-xs>exit</mat-icon>
                    <span fxHide fxShow.gt-xs>Speichern und Beenden</span>
                </ng-template>

                <ng-container *ngIf="!isSaving">
                    <ng-container *ngIf="bookingDialogAppearance === BookingDialogAppearance.TABS">
                        <button data-test="B-Weiter" mat-flat-button
                                color="accent"
                                [disabled]="!bookingDialogService.initialized || isSaving"
                                (click)="$event.stopPropagation(); nextTab();">
                            <mat-icon>arrow_forward</mat-icon>
                            <span fxHide fxShow.gt-xs>Weiter</span>
                        </button>
                    </ng-container>

                    <button data-test="B-Speichern" mat-flat-button
                            color="accent"
                            [disabled]="!bookingDialogService.initialized || isSaving || (!bookingDialogService.unsavedChanges && !(data.itemMoved || data.itemResized || data.cutEvent))"
                            (click)="$event.stopPropagation(); save(true);">
                        <mat-icon>save</mat-icon>
                        <span fxHide fxShow.gt-xs>Speichern</span>
                    </button>
                </ng-container>

                <ng-container
                        *ngIf="!bookingDialogService.unsavedChanges && !isSaving && !data.itemMoved && !data.itemResized && !data.cutEvent; else saveExitBtn">
                    <button data-test="B-Beenden" mat-flat-button
                            color="accent"
                            [disabled]="!bookingDialogService.initialized"
                            (click)="$event.stopPropagation(); dialogRef.close();">
                        Beenden
                    </button>
                </ng-container>
                <ng-template #saveExitBtn>
                    <button data-test="B-Speichern und Beenden" mat-flat-button
                            color="accent"
                            [disabled]="!bookingDialogService.initialized || isSaving || (!bookingDialogService.unsavedChanges && !(data.itemMoved || data.itemResized || data.cutEvent))"
                            (click)="$event.stopPropagation(); save();">
                        <ng-container *ngIf="isSaving; then saving else notSavingClose"></ng-container>
                    </button>
                </ng-template>

            </div>

        </div>

    </ng-container>

    <ng-template #loading>
        <div class="loading">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>

</div>
