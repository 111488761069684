<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Soll der Beleg per E-Mail versendet werden?</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <div class="mb-24">
            <strong>Rechnungsadresse:</strong>
            <div [innerHTML]="address"></div>
        </div>

        <form [formGroup]="formGroup">

            <div fxLayout="row wrap"
                 fxLayout.lt-sm="column"
                 fxLayoutGap="24px grid"
                 fxLayoutGap.lt-sm="0px">

                <ng-container *ngIf="mails?.length; else manualInput">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>E-Mail-Adresse</mat-label>
                        <mat-select data-test="FS-E-Mail-Adresse"
                                    formControlName="sendTo">
                            <ng-container *ngFor="let mail of mails">
                                <mat-option [value]="mail.mail">{{mail.mail}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error>{{formGroup.get('sendTo').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </ng-container>

                <ng-template #manualInput>
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>E-Mail-Adresse</mat-label>
                        <input matInput data-test="FS-E-Mail-Adresse"
                                    formControlName="sendTo">
                        <mat-error>{{formGroup.get('sendTo').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </ng-template>

                <div fxFlex="100">
                    <mat-checkbox [(ngModel)]="checkbox" [ngModelOptions]="{standalone: true}" (ngModelChange)="checkboxChange($event)">
                        Die im System hinterlegten Daten für Betreff und E-Mail-Nachricht überschreiben
                    </mat-checkbox>
                </div>

                <ng-container *ngIf="checkbox">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Betreffzeile der E-Mail</mat-label>
                        <input matInput data-test="FI-Betreff" type="text"
                               formControlName="subject">
                        <mat-error>{{formGroup.get('subject').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>

                    <div fxFlex="100">
                        <div class="mb-24">
                            <button mat-stroked-button (click)="openPlaceholderInfo()">
                                Verfügbare Platzhalter anzeigen
                            </button>
                        </div>

                        <editor [init]="editorOptions" formControlName="content"></editor>
                    </div>
                </ng-container>
            </div>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                class="save-button mat-primary"
                aria-label="Abbrechen">
            <mat-icon>cancel</mat-icon>
            Nicht senden
        </button>

        <ng-template #sending>
            <mat-icon>send</mat-icon>
            sendet...
            <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
        </ng-template>

        <ng-template #notSending>
            <mat-icon>send</mat-icon>
            Senden
        </ng-template>

        <button data-test="B-Speichern" mat-flat-button
                color="accent"
                (click)="sendMail()"
                class="save-button"
                [disabled]="isSending || formGroup.invalid"
                aria-label="Senden">
            <ng-container *ngIf="isSending; then sending else notSending"></ng-container>
        </button>

    </div>
</div>
