import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { ElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { ElectricMeterPaymentType } from '@modules/bcm/@shared/enums/electric-meter-payment-type';
import { TranslationService } from '@core/translation/translation.service';
import { DisconnectElectricMeterEvent } from '@sharedComponents/dialogs/booking-dialog/tabs/electric-meter-tab/electric-meter/assignment-electric-meters.component';
import { toDateStringDE } from '@core/functions/to-date-string';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { Product } from '@shared/models/product';
import { IUnit, UnitUniqueName } from '@shared/models/unit';
import { concatMap, from, Observable, of, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { BcmBookingsApiService } from '@bcmApiServices/bcm-bookings.api-service';
import { differenceInCalendarDays, isSameDay } from 'date-fns';
import { BcmSettingsSectionName, DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { calculateProportionalTime } from '@shared/functions/calculate-proportional-time';

export class BookingDialogPositions extends BookingDialogBaseEntity<InvoicePosition, InvoicePosition[]> {

    constructor(
        bookingDialogService: BookingDialogService,
        private _bookingsApiService: BcmBookingsApiService,
        private _productService: ProductService,
        private _bcmSettingsFacade: BcmSettingsFacade,
    ) {
        super(bookingDialogService, BookingAttribute.POSITIONS);

        this._defaultBerthReservationTimeUnit = this._bcmSettingsFacade.settings()[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];
    }

    private _defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit;

    protected isDuplicate(value: InvoicePosition): boolean {
        return this.bookingDialogService.positions.value.findIndex(sub => {
            return sub.product.id === value.product.id &&
                sub.quantity === value.quantity &&
                sub.fromTenantRelation &&
                !sub.id;
        }) !== -1;
    }

    reload(): void {
        this._bookingsApiService.getPositions(this.bookingDialogService.booking.id)
            .subscribe(positions => {

                positions.forEach(p => {
                    if (p.bcm_berth_has_assignments_id) {
                        p.berthAssignmentUuid = this.bookingDialogService.berthAssignments.value.find(a => a.id === p.bcm_berth_has_assignments_id)?.uuid;
                    }
                    if (p.bcm_electric_meter_has_readings_id) {
                        p.electricMeterAssignmentUuid = this.bookingDialogService.electricMeterAssignments.value.find(a => a.id === p.bcm_electric_meter_has_boats_id)?.uuid;
                    }
                    return p;
                });

                this.bookingDialogService.booking.positions = positions;
                this.value = positions;
            });
    }

    addFromTenantRelation(assignment: TenantRelationAssignment): void {

        const positions = assignment.tenantRelation.products.map(product => {

            const proportionalTime = calculateProportionalTime(assignment.fromDate, assignment.toDate, assignment.payableOption);

            const positionDto = {
                product,
                quantity: product.quantity * proportionalTime,
                account: product.account,
                unit: product.unit,
                price: product.price,
                taxRate: product.taxRate,
                fromTenantRelation: true,
                tenantRelationAssignment: assignment,
                tenantRelation: assignment.tenantRelation,
                title: product.name,
            } as InvoicePositionDto;

            return new InvoicePosition(positionDto);
        });

        from(positions).pipe(
            concatMap(position => this.getPositionWithDynamicPrice(position))
        ).subscribe({
            next: dynamicPricePosition => {
                this.add(dynamicPricePosition);
            }
        });

    }

    removeUnsavedFromTenantRelation(): void {
        this.value = this.value.filter(position => position.id || !position.fromTenantRelation);
    }

    addFromBerthAssignment(assignment: BerthBoatAssignment): void {

        const positions = assignment.berth.products.map(product => {
            const positionDto = {
                product,
                quantity: product.quantity,
                account: product.account,
                unit: product.unit,
                price: product.price,
                taxRate: product.taxRate,
                fromBerth: true,
                bcm_berth_has_assignments_id: assignment.id,
                berthAssignmentUuid: assignment.uuid,
                title: product.name,
            } as InvoicePositionDto;

            return new InvoicePosition(positionDto);
        });

        from(positions).pipe(
            concatMap(position => this.getPositionWithDynamicPrice(position))
        ).subscribe({
            next: dynamicPricePosition => {
                this.add(dynamicPricePosition);
            }
        });

    }

    removeFromBerthAssignment(assignment: BerthBoatAssignment): void {
        this.value = this.value.filter(position => position.berthAssignmentUuid !== assignment.uuid);
    }

    addFromElectricMeterAssignment(assignment: ElectricMeterAssignment): void {

        if (assignment.paymentType === ElectricMeterPaymentType.Flatrate && assignment.product) {

            const title = TranslationService.translate('electricityForBoatAccordingToFlatrate',
                    {'boatName': (this.bookingDialogService.boat.value?.licensePlate || this.bookingDialogService.boat.value?.name)}) +
                (assignment.electricMeter.cabinet?.handle ? ` Schrank: ${assignment.electricMeter.cabinet.handle} ` : '') +
                (assignment.electricMeter.handle ? `Zähler: ${assignment.electricMeter.handle} ` : '') +
                (assignment.electricMeter.manufacturerHandle ? `Nr.: ${assignment.electricMeter.manufacturerHandle} ` : '');


            const position = {
                product: assignment.product,
                quantity: assignment.quantity ?? 1,
                account: assignment.product.account,
                unit: assignment.product.unit,
                price: assignment.product.price,
                taxRate: assignment.product.taxRate,
                berthAssignmentUuid: assignment.berthAssignment?.uuid,
                electricMeterAssignmentUuid: assignment.uuid,
                title
            } as InvoicePositionDto;

            this.bookingDialogService.positions.add(new InvoicePosition(position));

        } else if (assignment.paymentType === ElectricMeterPaymentType.BasicCharge && assignment.product) {

            const boat = this.bookingDialogService.boat.value;
            const title = TranslationService.translate('electricityForBoatAccordingToBasicChargeAdditionalFee', {'boatName': (boat.licensePlate || boat.name)}) +
                (assignment.electricMeter.cabinet?.handle ? ` Schrank: ${assignment.electricMeter.cabinet.handle} ` : '') +
                (assignment.electricMeter.handle ? `Zähler: ${assignment.electricMeter.handle} ` : '') +
                (assignment.electricMeter.manufacturerHandle ? `Nr.: ${assignment.electricMeter.manufacturerHandle} ` : '');

            const positionDto = {
                product: assignment.product,
                quantity: assignment.quantity ?? 1,
                account: assignment.product.account,
                unit: assignment.product.unit,
                price: assignment.product.price,
                taxRate: assignment.product.taxRate,
                berthAssignmentUuid: assignment.berthAssignment?.uuid,
                electricMeterAssignmentUuid: assignment.uuid,
                title
            } as InvoicePositionDto;

            this.add(new InvoicePosition(positionDto));
        }

    }

    addFromElectricMeterAssignmentDisconnect(event: DisconnectElectricMeterEvent): void {

        if (event.product) {

            const boat = this.bookingDialogService.boat.value;

            const title = TranslationService.translate('electricityForBoatAccordingToReadingMeter', {'boatName': (boat.licensePlate || boat.name)}) +
                (event.electricMeterAssignment.electricMeter.cabinet?.handle ? ` Schrank: ${event.electricMeterAssignment.electricMeter.cabinet.handle} ` : '') +
                (event.electricMeterAssignment.electricMeter.handle ? `Zähler: ${event.electricMeterAssignment.electricMeter.handle} ` : '') +
                (event.electricMeterAssignment.electricMeter.manufacturerHandle ? `Nr.: ${event.electricMeterAssignment.electricMeter.manufacturerHandle} ` : '') +
                `Startablesung: ${toDateStringDE(event.from)} ` +
                `Zählerstand: ${event.meterReadingStart} ` +
                `Schlussablesung: ${toDateStringDE(event.to)} ` +
                `Zählerstand: ${event.meterReadingEnd}`;

            const positionDto = {
                product: event.product,
                quantity: event.quantity ?? 1,
                account: event.product.account,
                unit: event.product.unit,
                price: event.product.price,
                taxRate: event.product.taxRate,
                berthAssignmentUuid: event.electricMeterAssignment.berthAssignment?.uuid,
                electricMeterAssignmentUuid: event.electricMeterAssignment.uuid,
                electricMeterReadingUuid: event.electricMeterAssignment.reading?.uuid,
                title
            } as InvoicePositionDto;

            this.add(new InvoicePosition(positionDto));
        }

    }

    removeFromElectricMeterAssignment(assignment: ElectricMeterAssignment): void {
        this.value = this.value.filter(position => position.electricMeterAssignmentUuid !== assignment.uuid);
    }

    getPositionWithDynamicPrice(position: InvoicePosition): Observable<InvoicePosition> {

        if (position.product.hasDynamicPrice) {

            const boat = this.bookingDialogService.boat.value;
            const berth = this.bookingDialogService.berthAssignments.value.find(assignment => assignment.uuid === position.berthAssignmentUuid)?.berth;
            const tenantRelation = this.bookingDialogService.tenantRelationAssignment.value?.tenantRelation;

            let fromDate: Date | null = null;
            let toDate: Date | null = null;

            if (position.berthAssignmentUuid) {
                const berthAssignment = this.bookingDialogService.berthAssignments.value.find(assignment => assignment.uuid === position.berthAssignmentUuid);
                if (berthAssignment) {
                    fromDate = berthAssignment.from;
                    toDate = berthAssignment.to;
                }
            } else if (position.fromTenantRelation) {
                const tenantRelationAssignment = this.bookingDialogService.tenantRelationAssignment.value;
                if (tenantRelationAssignment) {
                    fromDate = tenantRelationAssignment.fromDate;
                    toDate = tenantRelationAssignment.toDate;
                }
            }

            return this._productService.evaluatePriceRule(
                position.product as Product,
                {
                    boat,
                    berth,
                    tenantRelation,
                },
                position.quantity,
                null,
                fromDate,
                toDate
            )
                .pipe(
                    take(1),
                    map(dynamicPrice => {
                        dynamicPrice = {
                            ...dynamicPrice,
                            boat,
                            berth,
                            tenantRelation
                        };

                        position.dynamicPrice = dynamicPrice;
                        position.price = dynamicPrice.rulePrice;
                        position.title = (position.unit.uniqueName === UnitUniqueName.KWH && !!position.berthAssignmentUuid) ? position.title : dynamicPrice.ruleName;

                        return position;
                    })
                );

        } else {
            return of(position);
        }
    }

    // private _getQuantity(_from: Date, _to: Date, unit: IUnit): number | null {
    //
    //     if (unit?.uniqueName === UnitUniqueName.OVERNIGHT_STAY) {
    //
    //         let fromHours: number;
    //         let fromMinutes: number;
    //         let toHours: number;
    //         let toMinutes: number;
    //
    //         let isAfterPeriodStarts: boolean;
    //         let isAfterPeriodEnds: boolean;
    //
    //         let durationOfStay: number;
    //
    //         if (isSameDay(_from, _to)) {
    //             fromHours = this._defaultBerthReservationTimeUnit.dailyGuestFromHours || 0;
    //             fromMinutes = this._defaultBerthReservationTimeUnit.dailyGuestFromMinutes || 0;
    //             toHours = this._defaultBerthReservationTimeUnit.dailyGuestToHours || 0;
    //             toMinutes = this._defaultBerthReservationTimeUnit.dailyGuestToMinutes || 0;
    //         } else {
    //             fromHours = this._defaultBerthReservationTimeUnit.overnightGuestFromHours || 0;
    //             fromMinutes = this._defaultBerthReservationTimeUnit.overnightGuestFromMinutes || 0;
    //             toHours = this._defaultBerthReservationTimeUnit.overnightGuestToHours || 0;
    //             toMinutes = this._defaultBerthReservationTimeUnit.overnightGuestToMinutes || 0;
    //         }
    //
    //         isAfterPeriodStarts = fromHours < _from.getHours() ||
    //             (fromHours === _from.getHours() && fromMinutes <= _from.getMinutes());
    //
    //         isAfterPeriodEnds = toHours < _to.getHours() ||
    //             (toHours === _to.getHours() && toMinutes < _to.getMinutes());
    //
    //         durationOfStay = Math.max(differenceInCalendarDays(_to, _from), 1);
    //
    //         if (!isAfterPeriodStarts) {
    //             durationOfStay++;
    //         }
    //
    //         if (isAfterPeriodEnds) {
    //             durationOfStay++;
    //         }
    //
    //         return durationOfStay;
    //
    //     } else if (unit?.uniqueName === UnitUniqueName.DAY) {
    //
    //         return differenceInCalendarDays(_to, _from) + 1;
    //
    //     }
    //
    //     return null;
    //
    // }

}
