import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { Company } from '@shared/models/company';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

export class BookingDialogCompany extends BookingDialogBaseEntity<Company, Company> {

    constructor(bookingDialogService: BookingDialogService) {
        super(bookingDialogService, BookingAttribute.COMPANY);
    }

    protected afterValueSet(): void {
        if (this.value?.id) {
            this.bookingDialogService.general.value.isBoatOwner = this.value?.id === this.bookingDialogService.boat.value?.ownerCompany?.id;
            this.bookingDialogService.person.value = null;
        }
    }

}
